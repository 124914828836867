import React from 'react';
import Chip, { ChipTypes } from '@lib/components/Chip/Chip';
import { Task } from 'graphql-common';
import { useTranslation } from 'react-i18next';

type Props = {
  task: Task;
};

export default function TaskScopeChip(props: Props) {
  const { task } = props;
  const { canCompletionCreate, completedAt } = task;
  const { t } = useTranslation();

  let label: string;
  let type: ChipTypes;
  if (completedAt) {
    label = t('completed');
    type = ChipTypes.chipDisabled;
  } else if (canCompletionCreate?.value) {
    label = t('active');
    type = ChipTypes.chipGreen;
  } else {
    label = t('upcoming');
    type = ChipTypes.chipOrange;
  }
  return <Chip type={type} label={label} leftIcon="circle" />;
}
