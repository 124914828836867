import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import _get from 'lodash/get';
import QrScanner from 'qr-scanner';
import { useNavigate } from 'react-router-dom';
import { USER_SELECTED_CAMERA_ID } from '@lib/enums/localStorageKeys';
import useCameraPermission from '@lib/hooks/useCameraPermission';
import usePrevious from '@lib/hooks/usePrevious';
import { captureException } from 'utils/captureException';

type Args = {
  qrCodeSuccessCallback: (res: QrScanner.ScanResult) => void;
};

export type UseCameraSetupResult = {
  videoRef: MutableRefObject<HTMLVideoElement | null>;
  cameraPermission: string; // "granted", "denied", "prompt".
  cameras: QrScanner.Camera[];
  handleCameraChange: (cameraId: string) => Promise<void>;
  isReady: boolean;
  isTorchAvailable: boolean;
  selectedCameraId: string;
  toggleTorch: () => Promise<void>;
  torchEnabled: boolean;
};

const getStoredCameraId = () => {
  return localStorage.getItem(USER_SELECTED_CAMERA_ID);
};

const useCameraSetup = ({
  qrCodeSuccessCallback,
}: Args): UseCameraSetupResult => {
  const initializedRef = useRef(false);
  const navigate = useNavigate();
  const [cameras, setCameras] = useState<QrScanner.Camera[]>([]);
  const [selectedCameraId, setSelectedCameraId] = useState(
    getStoredCameraId() || '',
  );

  const [isReady, setReady] = useState(false);
  const [isTorchAvailable, setIsTorchAvailable] = useState(false);
  const [torchEnabled, setTorchEnabled] = useState(false);

  const qrScannerRef = useRef<QrScanner | null>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  const cameraPermission = useCameraPermission({
    initialized: initializedRef.current,
    selectedCameraId,
  });
  const prevCameraPermission = usePrevious(cameraPermission);

  const selectCameraOption = useCallback(() => {
    try {
      const stream = videoRef.current?.srcObject as MediaStream;
      const activeCamera = stream?.getVideoTracks()[0];
      if (activeCamera) {
        const activeCameraSettings = activeCamera.getSettings();
        const activeCameraId = activeCameraSettings?.deviceId;
        if (activeCameraId) setSelectedCameraId(activeCameraId);
      }
    } catch (error) {
      captureException(
        error instanceof Error ? error : new Error(String(error)),
      );
    }
  }, []);

  const stopCamera = () => {
    if (videoRef.current?.srcObject) {
      const tracks = (videoRef.current.srcObject as MediaStream).getTracks();
      tracks.forEach((track) => {
        track.stop();
        // eslint-disable-next-line no-param-reassign
        track.enabled = false;
      });
      videoRef.current.srcObject = null;
    }
  };

  const handleCameraChange = useCallback(
    async (cameraId: string) => {
      setReady(false);
      setTorchEnabled(false);
      setIsTorchAvailable(false);
      if (qrScannerRef.current) {
        qrScannerRef.current.stop();
        await qrScannerRef.current.turnFlashOff();
        try {
          stopCamera();
          await qrScannerRef.current.setCamera(cameraId);
          setIsTorchAvailable(false);
          setSelectedCameraId(cameraId);
          setTorchEnabled(false);
          localStorage.setItem(USER_SELECTED_CAMERA_ID, cameraId);

          qrScannerRef.current.start().then(async () => {
            const hasFlash = (await qrScannerRef.current?.hasFlash()) || false;
            setIsTorchAvailable(hasFlash);
            setSelectedCameraId(cameraId);
            selectCameraOption();
          });
        } catch (error) {
          captureException(error);
        }
      }
      setReady(true);
    },
    [selectCameraOption],
  );

  const toggleTorch = useCallback(async () => {
    if (!qrScannerRef.current) return;

    await qrScannerRef.current.toggleFlash();
    setTorchEnabled(qrScannerRef.current?.isFlashOn());
  }, []);

  useEffect(() => {
    if (initializedRef.current) return () => {};
    initializedRef.current = true;
    const initializeCamera = async () => {
      if (!videoRef.current) return;

      try {
        const prevCameraId = getStoredCameraId();
        qrScannerRef.current = new QrScanner(
          videoRef.current,
          qrCodeSuccessCallback,
          {
            highlightCodeOutline: true,
            highlightScanRegion: true,
            returnDetailedScanResult: true,
            preferredCamera: prevCameraId || 'environment',
            maxScansPerSecond: 5,
            onDecodeError: (error) => {
              if (
                typeof error === 'string' &&
                error.includes(QrScanner.NO_QR_CODE_FOUND)
              )
                return;
              captureException(
                typeof error === 'string' ? new Error(error) : error,
              );
            },
            calculateScanRegion: (videoProp) => {
              const smallestDimension = Math.min(
                videoProp.videoWidth,
                videoProp.videoHeight,
              );
              const scanRegionSize = Math.round(smallestDimension / 3);
              return {
                x: (videoProp.videoWidth - scanRegionSize) / 2,
                y: (videoProp.videoHeight - scanRegionSize) / 2,
                width: scanRegionSize,
                height: scanRegionSize,
              };
            },
          },
        );

        await QrScanner.listCameras(true).then(async (cams) => {
          setCameras(cams);
        });

        if (qrScannerRef.current) {
          qrScannerRef.current.start().then(async () => {
            if (qrScannerRef.current) {
              const hasFlash = await qrScannerRef.current.hasFlash();
              setReady(true);
              setIsTorchAvailable(hasFlash);
              setTorchEnabled(qrScannerRef.current?.isFlashOn());

              selectCameraOption();
            }
          });
        }
      } catch (error) {
        if (typeof error === 'string') {
          captureException(new Error(error));
        } else if (error instanceof Error) {
          captureException(error);
        }
        setReady(true);
      }
    };

    initializeCamera();

    return () => {};
  }, [qrCodeSuccessCallback, selectCameraOption]);

  useEffect(() => {
    return () => {
      if (_get(qrScannerRef.current, '_active')) {
        qrScannerRef.current?.stop();
        qrScannerRef.current?.destroy();
        qrScannerRef.current = null;
      }
      stopCamera();
    };
  }, []);

  useEffect(() => {
    if (
      (prevCameraPermission === 'granted' ||
        prevCameraPermission === 'denied') &&
      cameraPermission === 'prompt'
    ) {
      navigate(0);
    }
  }, [cameraPermission, navigate, prevCameraPermission]);

  return {
    videoRef,
    cameraPermission,
    cameras,
    handleCameraChange,
    isReady,
    isTorchAvailable,
    selectedCameraId,
    toggleTorch,
    torchEnabled,
  };
};

export default useCameraSetup;
