import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _without from 'lodash/without';
import { FilterGroupingEnum } from 'graphql-common';
import Table from '@lib/components/Table/Table';
import Modal from '@lib/components/Modal/Modal';
import useSearchParam from '@lib/hooks/useSearchParam';
import {
  ORDERING_PARAMS,
  OrderingParams,
  PAGINATION_PARAMS,
  PaginationParams,
} from '@lib/enums/urls';
import getTableColumns from 'components/InterventionAssigneesModal/utils/getTableColumns';
import useInterventionAssigneesQueryHook from 'utils/fetch/useInterventionAssigneesQueryHook';
import styles from './InterventionAssigneesModal.module.scss';

function InterventionAssignees({
  interventionId,
  interventionActionId,
}: {
  interventionId?: string;
  interventionActionId?: string;
}) {
  const { t } = useTranslation();
  const [searchQuery] = useSearchParam();
  const paginationState = useState<PaginationParams>(PAGINATION_PARAMS);
  const orderingState = useState<OrderingParams>(ORDERING_PARAMS);
  const [paginationParams] = paginationState;
  const [orderingParams] = orderingState;
  // Users
  const usersQueryHookResult = useInterventionAssigneesQueryHook({
    searchQuery,
    paginationParams: {
      ...paginationParams,
      ...orderingParams,
    },
    additionalFilters: _without(
      [
        interventionId
          ? {
              interventionAssigneesInterventionId: {
                grouping: FilterGroupingEnum.Or,
                predicate: { eq: interventionId },
              },
            }
          : undefined,
        interventionActionId
          ? {
              interventionActionAssigneesActionId: {
                grouping: FilterGroupingEnum.Or,
                predicate: { eq: interventionActionId },
              },
            }
          : undefined,
      ],
      undefined,
    ) as object[],
  });
  const tableColumns = getTableColumns({ t });
  return (
    <Table
      counterText={t('assignees')}
      columns={tableColumns}
      data={usersQueryHookResult.collection}
      totalEntries={usersQueryHookResult.totalCount}
      paginationState={paginationState}
      orderingState={orderingState}
      loading={usersQueryHookResult.loading}
    />
  );
}

type Props = {
  caption?: string;
  isOpen: boolean;
  onClose: () => void;
  interventionId?: string;
  interventionActionId?: string;
};

function InterventionAssigneesModal(props: Props) {
  const { isOpen, onClose, caption, interventionId, interventionActionId } =
    props;
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      caption={caption}
      modalContentClassName={styles.modalContentClassName}
    >
      {isOpen ? (
        <InterventionAssignees
          interventionId={interventionId}
          interventionActionId={interventionActionId}
        />
      ) : (
        <div />
      )}
    </Modal>
  );
}

export default memo(InterventionAssigneesModal);
