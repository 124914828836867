import React, { useEffect } from 'react';
import _get from 'lodash/get';
import TaskCompletionForm from 'components/TaskCompletionForm/TaskCompletionForm';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import { useTranslation } from 'react-i18next';
import { APP_URLS } from 'constants/urls';
import { useNavigate } from 'react-router-dom';
import FormTemplateFieldNames from '@lib/enums/fieldNames/formTemplateFieldNames';
import { ActionsProps } from '@lib/components/ReactHookForm/types';
import useFields from './utils/useFields';
import useActions from './utils/useActions';

function TaskCompletionCreateContainer() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    formRef,
    id,
    isFormAvailable,
    isFormDisabled,
    onTaskCompletionCreateMutation,
    taskCompletionError,
    taskCompletionCreateMutationLoading,
    taskCompletionLoading,
    taskDataForFormTemplate,
    taskFormTemplateError,
    taskFormTemplateLoading = false,
    taskFormTemplateVersion,
  } = useActions();

  const questions = _get(
    taskFormTemplateVersion,
    FormTemplateFieldNames.Questions,
    [],
  );
  const lastAllowedAnswers = _get(
    taskDataForFormTemplate,
    FormTemplateFieldNames.LastAllowedAnswers,
    [],
  );
  const { formFields, defaultValues } = useFields(
    [...questions],
    lastAllowedAnswers,
    isFormDisabled,
  );

  useEffect(() => {
    if (!isFormAvailable && !taskFormTemplateLoading) {
      navigate(APP_URLS.app.tasks.view.getDynamicPath({ pathParts: { id } }));
    }
  }, [id, isFormAvailable, navigate, taskFormTemplateLoading]);

  const actionsComponent = ({ loading }: ActionsProps) =>
    isFormDisabled ? null : (
      <Button
        type="submit"
        buttonType={ButtonTypes.primaryFilled}
        buttonText={t('finish')}
        leftIcon="check"
        fullWidth
        disabled={loading}
      />
    );

  return (
    <TaskCompletionForm
      actionsComponent={actionsComponent}
      defaultValues={defaultValues}
      error={taskFormTemplateError}
      fields={formFields}
      formRef={formRef}
      id={id}
      loading={taskFormTemplateLoading}
      onSubmit={onTaskCompletionCreateMutation}
      taskCompletionError={taskCompletionError}
      taskCompletionLoading={
        taskCompletionLoading || taskCompletionCreateMutationLoading
      }
    />
  );
}

export default TaskCompletionCreateContainer;
