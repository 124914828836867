import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from '@lib/components/ConfirmationModal/ConfirmationModal';
import Trans from '@lib/components/Trans/Trans';
import ErrorPage from '@lib/components/ErrorPage/ErrorPage';
import getStatusFromError from '@lib/utils/getStatusFromError';
import FileViewerModal from '@lib/components/FileViewerModal/FileViewerModal';
import InterventionAssigneesModal from 'components/InterventionAssigneesModal/InterventionAssigneesModal';
import InterventionFormPage from 'routes/Intervention/InterventionFormPage/InterventionFormPage';
import InterventionViewPage from 'routes/Intervention/InterventionViewPage/InterventionViewPage';
import useOutletContext, { ContextProps } from 'hooks/useOutletContext';
import { ComponentEnum, LevelEnum } from 'graphql-common';
import { IconName } from '@lib/components/Modal/enums';
import isErrorTimeoutError from '@lib/utils/isErrorTimeoutError';
import { ErrorPageStatusEnum } from '@lib/components/ErrorPage/enums';
import useInterventionActions from './utils/useInterventionActions';
import useModalManagement from './utils/useModalManagement';
import { InterventionViewMode } from './enums';

type Props = {
  viewMode: InterventionViewMode;
};

export default function InterventionContainer({ viewMode }: Props) {
  const { t } = useTranslation();

  const { getUserAccessByComponent }: ContextProps = useOutletContext();
  const isViewOnly =
    getUserAccessByComponent(ComponentEnum.InterventionsManagement) ===
    LevelEnum.Read;

  const useModalManagementResult = useModalManagement();
  const useInterventionActionsResult = useInterventionActions({
    viewMode,
    useModalManagementResult,
  });
  const {
    interventionQueryHookResult,
    onConfirmedInterventionDestroy,
    onConfirmedInterventionClose,
    onConfirmedInterventionActionDestroy,
    onConfirmedInterventionActionClose,
    onViewChange,
  } = useInterventionActionsResult;
  const {
    closeAllModals,
    isAttachmentPreviewModalOpened,
    isInterventionActionAssigneesModalOpened,
    isInterventionAssigneesModalOpened,
    isInterventionCloseModalOpened,
    isInterventionDestroyModalOpened,
    isInterventionActionDestroyModalOpened,
    isInterventionActionCloseModalOpened,
  } = useModalManagementResult;

  const isForm =
    viewMode === InterventionViewMode.Add ||
    viewMode === InterventionViewMode.Edit;
  const isView =
    viewMode === InterventionViewMode.View ||
    viewMode === InterventionViewMode.ViewActions;
  const canUpdate = interventionQueryHookResult?.data?.canUpdate;

  useEffect(() => {
    if (
      viewMode === InterventionViewMode.Edit &&
      !!canUpdate &&
      !canUpdate.value
    ) {
      onViewChange(InterventionViewMode.View);
    }
  }, [canUpdate, onViewChange, viewMode]);

  if (isErrorTimeoutError(interventionQueryHookResult.error)) {
    return <ErrorPage status={ErrorPageStatusEnum.Status503} />;
  }

  if (interventionQueryHookResult.error) {
    return (
      <ErrorPage
        status={getStatusFromError(interventionQueryHookResult.error)}
        description={interventionQueryHookResult.error?.message}
      />
    );
  }

  return (
    <>
      {isForm && (
        <InterventionFormPage
          viewMode={viewMode}
          useInterventionActionsResult={useInterventionActionsResult}
          useModalManagementResult={useModalManagementResult}
        />
      )}
      {isView && (
        <InterventionViewPage
          viewMode={viewMode}
          useInterventionActionsResult={useInterventionActionsResult}
          useModalManagementResult={useModalManagementResult}
          isViewOnly={isViewOnly}
        />
      )}
      <ConfirmationModal
        confirmButtonLabel={t('yes-delete')}
        description={<Trans i18nKey="intervention-delete-text" />}
        icon={IconName.Delete}
        isOpen={isInterventionDestroyModalOpened}
        onClose={closeAllModals}
        onConfirm={onConfirmedInterventionDestroy}
        title={t('are-you-sure')}
      />
      <ConfirmationModal
        confirmButtonLabel={t('yes-delete')}
        description={<Trans i18nKey="intervention-action-delete-text" />}
        icon={IconName.Delete}
        isOpen={!!isInterventionActionDestroyModalOpened?.id}
        onClose={closeAllModals}
        onConfirm={onConfirmedInterventionActionDestroy}
        title={t('are-you-sure')}
      />
      <ConfirmationModal
        confirmButtonLabel={t('yes-close')}
        description={<Trans i18nKey="intervention-close-text" />}
        icon={IconName.Warning}
        isOpen={isInterventionCloseModalOpened}
        onClose={closeAllModals}
        onConfirm={onConfirmedInterventionClose}
        title={t('are-you-sure')}
      />
      <ConfirmationModal
        confirmButtonLabel={t('yes-close')}
        description={<Trans i18nKey="intervention-action-close-text" />}
        icon={IconName.Warning}
        isOpen={!!isInterventionActionCloseModalOpened?.id}
        onClose={closeAllModals}
        onConfirm={onConfirmedInterventionActionClose}
        title={t('are-you-sure')}
      />
      <FileViewerModal
        attachment={isAttachmentPreviewModalOpened}
        attachmentName={
          isAttachmentPreviewModalOpened?.fileData?.data?.metadata?.filename
        }
        onClose={closeAllModals}
        isOpen={!!isAttachmentPreviewModalOpened?.id}
      />
      <InterventionAssigneesModal
        caption={t('all-assignees')}
        interventionId={
          isInterventionActionAssigneesModalOpened?.id
            ? undefined
            : useInterventionActionsResult.id
        }
        interventionActionId={isInterventionActionAssigneesModalOpened?.id}
        isOpen={
          isInterventionAssigneesModalOpened ||
          !!isInterventionActionAssigneesModalOpened?.id
        }
        onClose={closeAllModals}
      />
    </>
  );
}
