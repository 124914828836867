import _get from 'lodash/get';
import { LanguageEnum } from 'graphql-common';
import { Values } from '@lib/interfaces/form';
import { getLangLabel } from 'utils/lang';

export default function getDefaultLangFormValuesFromQueryData(
  data?: Values,
): Values {
  const value = _get(data, 'lang') as LanguageEnum;
  return {
    lang: { value, label: getLangLabel(value) },
  };
}
