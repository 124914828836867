import { TFunction } from 'i18next';
import { FetchResult } from '@apollo/client';
import { AuthenticationPasswordsForgotMutation } from 'graphql-common';
import { FieldItem } from '@lib/interfaces/form';
import { InputTypes } from '@lib/components/Input/enums';
import Input from '@lib/components/Input/Input';
import * as Yup from 'yup';
import {
  MAX_PASSWORD_FIELD_LENGTH,
  MIN_PASSWORD_FIELD_LENGTH,
} from '@lib/enums/form';
import ForgotPasswordLink from 'components/Profile/ForgotPasswordLink';

type PasswordFormFieldsArgs = {
  t: TFunction<'translation', undefined>;
  onSendPasswordResetHandler: () => Promise<
    FetchResult<AuthenticationPasswordsForgotMutation>
  >;
  closeChangePasswordModal: () => void;
};

export default function getPasswordFormFields({
  t,
  onSendPasswordResetHandler,
  closeChangePasswordModal,
}: PasswordFormFieldsArgs): FieldItem[] {
  return [
    {
      name: 'passwordChallenge',
      type: InputTypes.password,
      label: t('current-password'),
      element: Input,
      validation: Yup.string()
        .trim()
        .min(MIN_PASSWORD_FIELD_LENGTH, t('password-field-min-error'))
        .max(MAX_PASSWORD_FIELD_LENGTH, t('password-field-max-error'))
        .required(t('required-field-error')),
    },
    {
      name: 'password',
      type: InputTypes.password,
      label: t('new-password'),
      element: Input,
      validation: Yup.string()
        .trim()
        .min(MIN_PASSWORD_FIELD_LENGTH, t('password-field-min-error'))
        .max(MAX_PASSWORD_FIELD_LENGTH, t('password-field-max-error'))
        .required(t('required-field-error')),
      description: t('password-field-description'),
    },
    {
      name: 'passwordConfirmation',
      type: InputTypes.password,
      label: t('confirm-new-password'),
      element: Input,
      validation: Yup.string()
        .trim()
        .test(
          'passwords-match',
          t('password-field-match-error'),
          // @ts-ignore
          function matchTest(this, value: string): boolean {
            // eslint-disable-next-line react/no-this-in-sfc
            return this.parent.password === value;
          },
        )
        .required(t('required-field-error')),
    },
    {
      name: 'forgot-password-link',
      customComponent: ForgotPasswordLink,
      customComponentProps: {
        onSendPasswordResetHandler,
        closeChangePasswordModal,
      },
    },
  ];
}
