import { useState, useEffect, useCallback } from 'react';
import { indexedDB } from 'utils/indexedDBUtils';
import { CacheNames } from 'constants/enums';
import { StoredCompletion, SyncStatus } from 'components/TaskSyncTracker/types';
import { captureException } from 'utils/captureException';

export const fetchDataFromDB = async () => {
  const store = indexedDB.table(CacheNames.taskCompletions);
  const total = await store.count();
  const query = store.where('id').above(0);
  const data = await query.toArray();
  return { data, total };
};

type Args = {
  disabled?: boolean;
  isOnline: boolean;
  syncStatus: SyncStatus;
};

const useTaskCompletionsIndexedDB = (args: Args) => {
  const { disabled, syncStatus, isOnline } = args;
  const [data, setData] = useState<StoredCompletion[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const { data: allData, total } = await fetchDataFromDB();
      setTotalCount(total);
      setData(allData);
    } catch (error) {
      captureException(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!disabled) {
      fetchData();
    }
  }, [disabled, fetchData, syncStatus, isOnline]);

  return { data, totalCount, loading, refetch: fetchData };
};

export default useTaskCompletionsIndexedDB;
