import React from 'react';
import { TaskScopeNameEnum } from 'graphql-common';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import EmptyState from '@lib/components/EmptyState/EmptyState';
import Trans from '@lib/components/Trans/Trans';
import { useTranslation } from 'react-i18next';
import {
  CustomTaskScopeNameEnum,
  TaskScopeNameEnumExtended,
} from 'constants/enums';
import { IconName } from '@lib/components/Modal/enums';
import { useNavigate } from 'react-router-dom';

type Props = {
  isEmptyState: boolean;
  isEmptyStateByFilter: boolean;
  isEmptyStateBySearch: boolean;
  isOnline: boolean;
  isTimeoutError?: boolean;
  onResetFilter: () => void;
  onTabParamChange: (v: string) => void;
  setSearchQuery: (v: string) => void;
  tabParam?: TaskScopeNameEnumExtended;
};

function getEmptyStateTextKeys(tabParam?: TaskScopeNameEnumExtended) {
  switch (tabParam) {
    case TaskScopeNameEnum.MyTodoAvailableToComplete:
      return ['no-tasks-my-available-yet', 'no-tasks-my-available-text'];
    case TaskScopeNameEnum.MyTodoUpcoming:
      return ['no-tasks-my-available-yet', 'no-tasks-my-available-text'];
    case TaskScopeNameEnum.MyCompleted:
      return ['no-tasks-my-completed-yet', 'no-tasks-my-completed-text'];
    case CustomTaskScopeNameEnum.Downloads:
      return ['no-tasks-my-downloads-yet', 'no-tasks-my-downloads-text'];
    default:
      return ['no-tasks-yet', 'no-tasks-text'];
  }
}

function TasksEmptyState(props: Props) {
  const {
    isEmptyState,
    isEmptyStateByFilter,
    isEmptyStateBySearch,
    isOnline,
    isTimeoutError,
    onResetFilter,
    onTabParamChange,
    setSearchQuery,
    tabParam,
  } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [emptyStateTitleKey, emptyStateTextKey] =
    getEmptyStateTextKeys(tabParam);
  if (
    (!isOnline || isTimeoutError) &&
    tabParam !== CustomTaskScopeNameEnum.Downloads
  ) {
    return (
      <EmptyState
        title={t('503-error-title')}
        text={t('503-error-desc')}
        icon={IconName.Offline}
        isFullWidthActions
        actions={[
          <Button
            key="navigate"
            buttonText={t('go-to-downloads')}
            buttonType={ButtonTypes.primaryFilled}
            onClick={() => onTabParamChange(CustomTaskScopeNameEnum.Downloads)}
          />,
          <Button
            key="refresh"
            buttonText={t('refresh-page')}
            buttonType={ButtonTypes.primaryOutlined}
            onClick={() => navigate(0)}
          />,
        ]}
      />
    );
  }

  if (isEmptyStateBySearch) {
    return (
      <EmptyState
        title={t('no-results-found')}
        text={t('no-results-found-text')}
        icon={IconName.SearchOff}
        actions={[
          <Button
            key="back"
            buttonText={t('go-back')}
            buttonType={ButtonTypes.primaryFilled}
            leftIcon="arrow_back"
            onClick={() => setSearchQuery('')}
          />,
        ]}
      />
    );
  }

  if (isEmptyStateByFilter) {
    return (
      <EmptyState
        title={t('no-results-found')}
        text={t('try-something-else')}
        icon={IconName.SearchOff}
        actions={[
          <Button
            key="back"
            buttonText={t('clear-filtering')}
            buttonType={ButtonTypes.primaryFilled}
            onClick={onResetFilter}
          />,
        ]}
      />
    );
  }

  if (isEmptyState) {
    return (
      <EmptyState
        title={t(emptyStateTitleKey)}
        text={<Trans i18nKey={emptyStateTextKey} />}
        icon={IconName.Activate}
        actions={
          tabParam === TaskScopeNameEnum.MyCompleted
            ? [
                <Button
                  key="back"
                  buttonText={t('check-todo-list')}
                  buttonType={ButtonTypes.primaryFilled}
                  onClick={() =>
                    onTabParamChange(
                      TaskScopeNameEnum.MyTodoAvailableToComplete,
                    )
                  }
                />,
              ]
            : undefined
        }
      />
    );
  }

  return null;
}

export default TasksEmptyState;
