import React, { useEffect, useState } from 'react';
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import _get from 'lodash/get';
import { User } from 'graphql-common';
import ErrorBoundary from '@lib/components/ErrorBoundary/ErrorBoundary';
import { dashboardModalId, downloadLinkId } from '@lib/enums/selectors';
import ScrollToTop from '@lib/components/ScrollToTop/ScrollToTop';
import Menu, { NavItem } from '@lib/components/Menu/Menu';
import ReleaseTracker from '@lib/components/ReleaseTreacker/ReleaseTracker';
import AppContentMaxWidth from '@lib/layouts/AppLayout/AppContentMaxWidth/AppContentMaxWidth';
import usePrevious from '@lib/hooks/usePrevious';
import IconButton, {
  IconButtonSizes,
  IconButtonTypes,
} from '@lib/components/IconButton/IconButton';
import Image from '@lib/components/Image/Image';
import AvatarIcon from '@lib/assets/icons/avatar.svg?react';
import Logo from '@lib/components/Logo/Logo';
import LogoSmall from '@lib/assets/images/logo-image.svg?react';
import UpgradePlanModal from '@lib/components/UpgradePlanModal/UpgradePlanModal';
import { PlanState } from '@lib/enums/plan';
import styles from './AppLayout.module.scss';

const {
  VITE_PLATFORM_URL: domain,
  VITE_RELEASE_VERSION: releaseVersion,
  VITE_RELEASE_TRACKER_SECONDS: releaseTrackerSeconds,
  MODE: mode,
  // @ts-ignore
} = import.meta.env;

type Components = {
  topContent: React.ReactElement;
};

type Props = {
  captureException: (error: Error, response?: unknown) => void;
  components: Components;
  currentUser: User;
  logoUrl: string;
  navItems: NavItem<undefined>[];
  outletContext: object;
  profileUrl: string;
  tools: React.ReactNode;
};

function AppLayout(props: Props): React.ReactElement {
  const {
    captureException,
    components,
    currentUser,
    logoUrl,
    navItems,
    outletContext,
    profileUrl,
    tools,
  } = props;
  const companyId = _get(currentUser, 'company.id', '') as string;
  const { topContent } = components;
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname, search } = location;
  const prevUrl = usePrevious(`${pathname}${search}`);
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [planModalType, setPlanModalType] = useState<PlanState | undefined>();

  const { avatarAttached } = currentUser || {};
  const url =
    _get(avatarAttached, 'urls.small') || _get(avatarAttached, 'urls.original');
  const userAvatar = url ? <Image src={url} /> : <AvatarIcon />;

  const onLogoClick = () => {
    setSidebarOpen(false);
    navigate(logoUrl);
  };

  const handleClosePlanModal = () => setPlanModalType(undefined);
  const handleOpenPlanModal = (state: PlanState) => setPlanModalType(state);

  const contextProps = { ...outletContext, handleOpenPlanModal, prevUrl };

  useEffect(() => {
    setSidebarOpen(false);
  }, [pathname, search]);

  return (
    <>
      <ScrollToTop />
      <ReleaseTracker
        captureException={captureException}
        domain={domain}
        releaseVersion={releaseVersion}
        releaseTrackerSeconds={releaseTrackerSeconds}
        mode={mode}
      />
      <UpgradePlanModal
        companyId={companyId}
        isOpen={!!planModalType}
        planState={planModalType}
        onClose={handleClosePlanModal}
      />
      <div
        className={classNames(styles.root, {
          [styles.rootWithTracker]: topContent,
          [styles.rootWithOpenedSidebar]: isSidebarOpen,
        })}
      >
        {topContent}
        <div className={styles.header}>
          <AppContentMaxWidth>
            <div className={styles.headerRow}>
              <div>
                {navItems?.length ? (
                  <IconButton
                    icon="menu"
                    onClick={() => setSidebarOpen(true)}
                  />
                ) : (
                  <button
                    type="button"
                    className={styles.headerLogo}
                    onClick={onLogoClick}
                  >
                    <LogoSmall />
                  </button>
                )}
              </div>
              {tools}
              <NavLink to={profileUrl} className={styles.userAvatar}>
                {userAvatar}
              </NavLink>
            </div>
          </AppContentMaxWidth>
        </div>
        <div className={styles.content}>
          <ErrorBoundary
            location={location}
            captureException={captureException}
          >
            <Outlet context={contextProps} />
          </ErrorBoundary>
        </div>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div
          className={classNames(styles.sideBarOverlay, {
            [styles.sideBarOverlayVisible]: isSidebarOpen,
          })}
          onClick={() => setSidebarOpen(false)}
        />
        <div
          className={classNames(styles.sideBar, {
            [styles.sideBarVisible]: isSidebarOpen,
          })}
        >
          <div className={styles.toggleBtn}>
            <IconButton
              icon="chevron_left"
              onClick={() => setSidebarOpen(false)}
              rounded
              type={IconButtonTypes.SecondaryOutlined}
              size={IconButtonSizes.Small}
            />
          </div>
          <div className={styles.sideBarTopBlock}>
            <button
              type="button"
              className={styles.sideBarLogo}
              onClick={onLogoClick}
            >
              <Logo />
            </button>
          </div>
          <Menu
            navItems={navItems}
            itemWrapClassName={styles.menuItemWrapClassName}
            onClickCallback={() => setSidebarOpen(false)}
          />
        </div>
        <div style={{ display: 'none' }}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a id={downloadLinkId} download>
            Download link
          </a>
        </div>
      </div>
      <div id={dashboardModalId} />
    </>
  );
}

export default AppLayout;
