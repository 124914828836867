import { useCallback } from 'react';
import { USER_QRCODE_DATA } from '@lib/enums/localStorageKeys';

interface CodeEntry {
  code: string;
  createdAt: string; // ISO date format
}

const {
  VITE_SCANNED_CODE_EXPIRATION_SECONDS: SCANNED_CODE_EXPIRATION_SECONDS,
} = import.meta.env;

const EXPIRATION_SECONDS = SCANNED_CODE_EXPIRATION_SECONDS
  ? parseInt(SCANNED_CODE_EXPIRATION_SECONDS, 10) * 60
  : 30 * 60; // 30 minutes in seconds

// Helper function to determine if a code is still valid
function isCodeValid(codeEntry: CodeEntry): boolean {
  const now = new Date();
  const createdAt = new Date(codeEntry.createdAt);
  const diffSeconds = (now.getTime() - createdAt.getTime()) / 1000;
  return EXPIRATION_SECONDS && diffSeconds
    ? diffSeconds < EXPIRATION_SECONDS
    : false;
}

function useManageCodes() {
  const getCodesFromStorage = (): CodeEntry[] => {
    const storedCodes = localStorage.getItem(USER_QRCODE_DATA);
    if (!storedCodes) return [];
    return JSON.parse(storedCodes).filter(isCodeValid);
  };

  // Set or update a code
  const setCode = useCallback((newCode: string) => {
    const now = new Date().toISOString();
    const updatedCodes = getCodesFromStorage();
    const existingIndex = updatedCodes.findIndex((c) => c.code === newCode);

    if (existingIndex > -1) {
      updatedCodes[existingIndex].createdAt = now; // Update existing code's timestamp
    } else {
      updatedCodes.push({ code: newCode, createdAt: now }); // Add new code
    }

    localStorage.setItem(USER_QRCODE_DATA, JSON.stringify(updatedCodes));
  }, []);

  // Check if a code exists and is valid
  const checkCode = useCallback(
    (codeToCheck: string) =>
      getCodesFromStorage().some((c) => {
        if (!c.code?.length) return false;
        return c.code.indexOf(codeToCheck) > -1;
      }),
    [],
  );

  // Public API of the hook
  return { setCode, checkCode };
}

export default useManageCodes;
