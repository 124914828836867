import _find from 'lodash/find';
import _some from 'lodash/some';
import _get from 'lodash/get';
import _has from 'lodash/has';
import { Value, Values } from '@lib/interfaces/form';
import {
  AttachmentCuInputObject,
  AttachmentFileInputObject,
  FormAnswerCreateInputObject,
  FormTemplateQuestionFieldOption,
  FormTemplateQuestionFieldsKindEnum,
  FormTemplateVersion,
  RespGroupEnum,
} from 'graphql-common';
import { getFormattedDate } from '@lib/utils/dateTimeHelpers';
import {
  dateFormatForApi,
  dateTimePickerDateFormatForApi,
} from '@lib/enums/dateTime';

function extractQuestionAndFieldId(key: string) {
  const match = key.match(/question-([a-z0-9\\-]+)-field-([a-z0-9\\-]+)/);
  if (match) {
    return { questionId: match[1], fieldId: match[2] };
  }
  return null;
}

// Define separate functions for each kind of question field processing
const processInputTextArea = (fieldValue: Value) =>
  fieldValue ? [{ value: `${fieldValue}` }] : undefined;

const processCheckboxRadioButtonSingleSelectDecisionBox = (
  fieldValue: Value,
  options: FormTemplateQuestionFieldOption[],
) =>
  options.map(({ id: optionId, values: optionValues }) => {
    let selected = false;
    if (Array.isArray(fieldValue) && fieldValue.length && optionValues[0]) {
      selected = _some(fieldValue, { value: optionValues[0] });
    } else if (
      typeof fieldValue === 'object' &&
      Object.prototype.hasOwnProperty.call(fieldValue, 'value')
    ) {
      selected = optionValues.some(
        (item) => item === _get(fieldValue, 'value'),
      );
    }
    return { optionId, selected };
  });

const processImportantNotice = (options: FormTemplateQuestionFieldOption[]) =>
  options.map(({ id: optionId }) => ({ optionId }));

const processRating = (fieldValue: Value) =>
  _has(fieldValue, 'value')
    ? [{ value: `${_get(fieldValue, 'value')}` }]
    : undefined;

const processNumberInput = (fieldValue: Value) =>
  fieldValue
    ? [{ value: `${parseFloat(`${fieldValue}`).toFixed(1)}` }]
    : undefined;

const processMinMax = (fieldValue: Value) => {
  const v1 = _get(fieldValue, 0);
  const v2 = _get(fieldValue, 1);
  return [
    {
      value:
        v1 === undefined ? undefined : `${parseFloat(v1 as string).toFixed(1)}`,
      group: RespGroupEnum.Min,
    },
    {
      value:
        v1 === undefined ? undefined : `${parseFloat(v2 as string).toFixed(1)}`,
      group: RespGroupEnum.Max,
    },
  ];
};

const processDate = (fieldValue: Value) =>
  fieldValue
    ? [{ value: getFormattedDate(fieldValue as string, dateFormatForApi) }]
    : undefined;

const processDateRange = (fieldValue: Value) => {
  const v1 = _get(fieldValue, 0);
  const v2 = _get(fieldValue, 1);
  return [
    {
      value:
        v1 === undefined
          ? undefined
          : getFormattedDate(v1 as string, dateFormatForApi),
      group: RespGroupEnum.StartDate,
    },
    {
      value:
        v2 === undefined
          ? undefined
          : getFormattedDate(v2 as string, dateFormatForApi),
      group: RespGroupEnum.EndDate,
    },
  ];
};

const processTime = (fieldValue: Value) =>
  fieldValue ? [{ value: `${fieldValue}` }] : undefined;

const processTimeRange = (fieldValue: Value) => {
  const v1 = _get(fieldValue, 0);
  const v2 = _get(fieldValue, 1);
  return [
    {
      value: v1 === undefined ? undefined : `${v1}`,
      group: RespGroupEnum.StartTime,
    },
    {
      value: v2 === undefined ? undefined : `${v2}`,
      group: RespGroupEnum.EndTime,
    },
  ];
};

const processDatetime = (fieldValue: Value) =>
  fieldValue
    ? [
        {
          value: getFormattedDate(
            fieldValue as string,
            dateTimePickerDateFormatForApi,
          ),
        },
      ]
    : undefined;

const processYesNo = (fieldValue: Value, options: any[]) =>
  options.map(({ id: optionId, group }) => ({
    optionId,
    selected: _get(fieldValue, 'value') === group,
    group,
  }));

const processAttachment = (
  fieldValue: Value,
  attachmentsAttached: AttachmentCuInputObject[],
) => {
  if (!fieldValue) return [];
  if (Array.isArray(fieldValue) && fieldValue.length) {
    fieldValue.forEach((fieldValueItem, index) => {
      const filename = _get(fieldValueItem, 'filename');
      const file = _get(
        fieldValueItem,
        'file',
      ) as unknown as AttachmentFileInputObject;
      if (file) attachmentsAttached.push({ file, filename, position: index });
    });
  }
  return [];
};

export default function getAnswers(
  values: Values,
  formTemplateVersion: FormTemplateVersion,
) {
  const { questions } = formTemplateVersion;
  const answers: FormAnswerCreateInputObject[] = [];

  Object.keys(values).forEach((key) => {
    const ids = extractQuestionAndFieldId(key);
    const fieldValue = values[key] as Value;
    if (!ids) return;
    const attachmentsAttached: AttachmentCuInputObject[] = [];
    const { questionId, fieldId } = ids;
    const question = _find(questions, { id: questionId });
    const field = _find(question?.fields, { id: fieldId });
    if (!field) return;

    let resp;
    switch (field.kind) {
      case FormTemplateQuestionFieldsKindEnum.Input:
      case FormTemplateQuestionFieldsKindEnum.TextArea:
        resp = processInputTextArea(fieldValue);
        break;
      case FormTemplateQuestionFieldsKindEnum.Checkbox:
      case FormTemplateQuestionFieldsKindEnum.RadioButton:
      case FormTemplateQuestionFieldsKindEnum.SingleSelect:
      case FormTemplateQuestionFieldsKindEnum.DecisionBox:
        resp = processCheckboxRadioButtonSingleSelectDecisionBox(
          fieldValue,
          field.options || [],
        );
        break;
      case FormTemplateQuestionFieldsKindEnum.CheckboxButtonGrid:
        resp = fieldValue;
        break;
      case FormTemplateQuestionFieldsKindEnum.Rating:
        resp = processRating(fieldValue);
        break;
      case FormTemplateQuestionFieldsKindEnum.MinMax:
        resp = processMinMax(fieldValue);
        break;
      case FormTemplateQuestionFieldsKindEnum.NumberInput:
        resp = processNumberInput(fieldValue);
        break;
      case FormTemplateQuestionFieldsKindEnum.Date:
        resp = processDate(fieldValue);
        break;
      case FormTemplateQuestionFieldsKindEnum.DateRange:
        resp = processDateRange(fieldValue);
        break;
      case FormTemplateQuestionFieldsKindEnum.Time:
        resp = processTime(fieldValue);
        break;
      case FormTemplateQuestionFieldsKindEnum.TimeRange:
        resp = processTimeRange(fieldValue);
        break;
      case FormTemplateQuestionFieldsKindEnum.Datetime:
        resp = processDatetime(fieldValue);
        break;
      case FormTemplateQuestionFieldsKindEnum.YesNo:
        resp = processYesNo(fieldValue, field.options || []);
        break;
      case FormTemplateQuestionFieldsKindEnum.Attachment:
        resp = processAttachment(fieldValue, attachmentsAttached);
        break;
      case FormTemplateQuestionFieldsKindEnum.ImportantNotice:
        resp = processImportantNotice(field.options || []);
        break;
      default:
        resp = undefined;
        break;
    }
    answers.push({
      questionId,
      fieldId,
      resp: resp || [],
      attachmentsAttached: attachmentsAttached.length
        ? attachmentsAttached
        : undefined,
    });
  });

  return answers;
}
