import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _isEmpty from 'lodash/isEmpty';
import {
  TaskScopeNameEnum,
  useFormCategoriesLazyQuery,
  useSitesSearchListLazyQuery,
} from 'graphql-common';
import { Values } from '@lib/interfaces/form';
import Modal from '@lib/components/Modal/Modal';
import Form from '@lib/components/ReactHookForm/FormContainer';
import {
  GetUrlParams,
  ORDERING_DIRECTION_ASC,
  ORDERING_DIRECTION_DESC,
} from '@lib/enums/urls';
import formStyles from '@lib/assets/styles/Form.module.scss';
import { GetLoadOptions } from '@lib/components/Select/types';
import Chip, { ChipSizes, ChipTypes } from '@lib/components/Chip/Chip';
import IconButtonWithDropDown from '@lib/components/IconButtonWithDropDown/IconButtonWithDropDown';
import { NavItemTypeEnum } from '@lib/components/Menu/Menu';
import usePaginationParams from '@lib/hooks/usePaginationParams';
import useTabParam from '@lib/hooks/useTabParam';
import AscIcon from '@lib/assets/icons/asc_icon.svg?react';
import DescIcon from '@lib/assets/icons/desc_icon.svg?react';
import { getSitesLoadOptions as getSitesLoadOptionsFn } from 'utils/fetch/sitesSearchList';
import { getFormCategoriesLoadOptions as getFormCategoriesLoadOptionsFn } from 'utils/fetch/formCategoriesSearchList';
import IconButton, {
  IconButtonTypes,
} from '@lib/components/IconButton/IconButton';
import useOutletContext, { ContextProps } from 'hooks/useOutletContext';
import { ScannerActionType } from 'routes/Scanner/enums';
import { useLocalStorage } from 'usehooks-ts';
import { TASK_FILTER_FORM } from '@lib/enums/localStorageKeys';
import _without from 'lodash/without';
import TasksFilterActions from './TasksFilterActions';
import useFilterFields from './utils/useFilterFields';
import styles from './TasksFilter.module.scss';

type Props = {
  onResetFilter: () => void;
};

export default function TasksFilter(props: Props) {
  const { onResetFilter } = props;
  const { t } = useTranslation();
  const [tab] = useTabParam();
  const [paginationParams, setPaginationParams] = usePaginationParams();
  const [filterParams, setFilterParams] = useLocalStorage(TASK_FILTER_FORM, {});
  const [isFilterOpened, setFilterOpened] = useState(false);
  const openFilter = () => setFilterOpened(true);
  const closeFilter = () => setFilterOpened(false);
  const clearFilter = () => {
    setFilterOpened(false);
    setFilterParams({});
  };
  const { openScanner }: ContextProps = useOutletContext();

  // Sites query
  const [fetchSitesSearchListLazyQuery] = useSitesSearchListLazyQuery({
    fetchPolicy: 'cache-and-network',
  });

  const getSitesLoadOptions: GetLoadOptions = () =>
    getSitesLoadOptionsFn(fetchSitesSearchListLazyQuery);

  // Form categories query
  const [fetchFormCategoriesLazyQuery] = useFormCategoriesLazyQuery({
    fetchPolicy: 'cache-and-network',
  });

  const getFormCategoriesLoadOptions: GetLoadOptions = () =>
    getFormCategoriesLoadOptionsFn(fetchFormCategoriesLazyQuery);

  const { [GetUrlParams.OrderingDirection]: orderingDirection } =
    paginationParams;

  const defaultValues =
    filterParams && Object.keys(filterParams).length > 0 ? filterParams : {};

  const filterCounter = Object.values(defaultValues).filter((value) => {
    if (Array.isArray(value)) {
      return !!_without(value, '').length;
    }
    return !_isEmpty(value);
  }).length;

  const fields = useFilterFields({
    getFormCategoriesLoadOptions,
    getSitesLoadOptions,
    t,
  });

  const onSubmit = (values: Values) => {
    setFilterParams(values);
    closeFilter();
  };

  const onOpenScanner = () => openScanner(ScannerActionType.FindTasks);

  const sortingIcon = useMemo(
    () =>
      orderingDirection === ORDERING_DIRECTION_ASC ? (
        <AscIcon key="asc" />
      ) : (
        <DescIcon key="desc" />
      ),
    [orderingDirection],
  );

  const navItems =
    tab === TaskScopeNameEnum.MyCompleted
      ? [
          {
            id: 'newest-first',
            title: t('newest-first'),
            onClick: () =>
              setPaginationParams({
                [GetUrlParams.OrderingDirection]: ORDERING_DIRECTION_DESC,
              }),
            type: NavItemTypeEnum.PrimaryText,
            itemClassName:
              orderingDirection === ORDERING_DIRECTION_DESC
                ? 'active'
                : undefined,
          },
          {
            id: 'oldest-first',
            title: t('oldest-first'),
            onClick: () =>
              setPaginationParams({
                [GetUrlParams.OrderingDirection]: ORDERING_DIRECTION_ASC,
              }),
            type: NavItemTypeEnum.PrimaryText,
            itemClassName:
              orderingDirection === ORDERING_DIRECTION_ASC
                ? 'active'
                : undefined,
          },
        ]
      : [
          {
            id: 'most-urgent-first',
            title: t('most-urgent-first'),
            onClick: () =>
              setPaginationParams({
                [GetUrlParams.OrderingDirection]: ORDERING_DIRECTION_ASC,
              }),
            type: NavItemTypeEnum.PrimaryText,
            itemClassName:
              orderingDirection === ORDERING_DIRECTION_ASC
                ? 'active'
                : undefined,
          },
          {
            id: 'least-urgent-first',
            title: t('least-urgent-first'),
            onClick: () =>
              setPaginationParams({
                [GetUrlParams.OrderingDirection]: ORDERING_DIRECTION_DESC,
              }),
            type: NavItemTypeEnum.PrimaryText,
            itemClassName:
              orderingDirection === ORDERING_DIRECTION_DESC
                ? 'active'
                : undefined,
          },
        ];

  return (
    <>
      <div className={styles.filterWrap}>
        <div className={styles.filter}>
          <div className={styles.filterRow}>
            <div>
              <IconButton
                icon="qr_code"
                type={IconButtonTypes.PrimaryFilled}
                onClick={onOpenScanner}
              />
            </div>
            <div>
              <Chip
                counter={filterCounter}
                label={t('filter')}
                leftIcon="filter_alt"
                leftIconSymbolsOutlined
                onClick={openFilter}
                size={ChipSizes.large}
                type={ChipTypes.chipFilter}
                tooltipForCroppedTextDisabled
              />
            </div>
            {!!filterCounter && (
              <div className={styles.clearBtnCol}>
                <IconButton
                  type={IconButtonTypes.TertiaryFilled}
                  icon="cancel"
                  onClick={onResetFilter}
                />
              </div>
            )}
          </div>
          <div className={styles.sortingRow}>
            <IconButtonWithDropDown
              buttonProps={{
                svgIcon: sortingIcon,
                className: styles.sortingBtn,
              }}
              popoverProps={{
                width: 168,
                placement: 'bottomRight',
              }}
              navItems={navItems}
            />
          </div>
        </div>
      </div>
      <Modal
        isOpen={isFilterOpened}
        caption={t('filter')}
        onClose={closeFilter}
      >
        <Form
          fields={fields}
          onSubmit={onSubmit}
          defaultValues={defaultValues}
          actionsComponent={TasksFilterActions}
          actionsComponentProps={{
            onCancel: clearFilter,
          }}
          formClassName={formStyles.form}
        />
      </Modal>
    </>
  );
}
