import { TFunction } from 'i18next';
import { FieldItem } from '@lib/interfaces/form';
import Select from '@lib/components/Select/Select';
import * as Yup from 'yup';
import { getOptionShape } from '@lib/utils/yup';
import formStyles from '@lib/assets/styles/Form.module.scss';
import { langOptions } from 'utils/lang';

type LangFormFieldsArgs = {
  t: TFunction<'translation', undefined>;
  loading: boolean;
  isOnline?: boolean;
};

export default function getLangFormFields({
  t,
  loading,
  isOnline,
}: LangFormFieldsArgs): FieldItem[] {
  return [
    {
      name: 'lang',
      label: t('choose-language'),
      element: Select,
      validation: Yup.object()
        .shape(getOptionShape(t, true))
        .required(t('required-field-error')),
      formItemClassName: formStyles.formColumn,
      componentProps: {
        disabled: loading || !isOnline,
        options: langOptions,
        isClearable: false,
      },
    },
  ];
}
