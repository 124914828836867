import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import { Task, TaskScopeNameEnum } from 'graphql-common';
import { ApolloError } from '@apollo/client/errors';
import { TaskCounter } from '@lib/interfaces/task';
import PageBody from '@lib/layouts/DashboardLayout/PageBody/PageBody';
import TabBar from '@lib/components/TabBar/TabBar';
import {
  GetUrlParams,
  ORDERING_DIRECTION_ASC,
  ORDERING_DIRECTION_DESC,
} from '@lib/enums/urls';
import ErrorPage from '@lib/components/ErrorPage/ErrorPage';
import AppContentMaxWidth from '@lib/layouts/AppLayout/AppContentMaxWidth/AppContentMaxWidth';
import getStatusFromError from '@lib/utils/getStatusFromError';
import useIsOnline from '@lib/hooks/useIsOnline';
import TabsWrap from '@lib/layouts/AppLayout/TabsWrap/TabsWrap';
import {
  CustomTaskScopeNameEnum,
  TaskScopeNameEnumExtended,
} from 'constants/enums';
import { TabBarItem as TabBarItemType } from '@lib/components/TabBar/constants';
import { useLocalStorage } from 'usehooks-ts';
import { TASK_FILTER_FORM } from '@lib/enums/localStorageKeys';
import TasksFilterFieldNames from '@lib/enums/fieldNames/tasksFilterFieldNames';
import isErrorTimeoutError from '@lib/utils/isErrorTimeoutError';
import TasksList from './TaskList';
import TasksDownloads from './TasksDownloads';
import getTableTabs from './utils/getTableTabs';

type Props = {
  downloadsData: Task[];
  downloadsLimitValue: number;
  downloadsLoading: boolean;
  downloadsTotalCount: number;
  onTabParamChange: (v: string, p?: object) => void;
  tabParam?: TaskScopeNameEnumExtended;
  prevTabParam?: TaskScopeNameEnumExtended;
  tasks: Task[];
  taskCounterData?: TaskCounter;
  tasksError?: ApolloError;
  tasksFirstLoading: boolean;
  tasksLoading: boolean;
  totalEntries: number;
};

function Tasks(props: Props) {
  const {
    downloadsData,
    downloadsLimitValue,
    downloadsLoading,
    downloadsTotalCount,
    onTabParamChange,
    tabParam,
    prevTabParam,
    taskCounterData,
    tasks,
    tasksError,
    tasksFirstLoading,
    tasksLoading,
    totalEntries,
  } = props;

  const isOnline = useIsOnline();
  const { t } = useTranslation();
  const [isFilterByQRCodeMessageVisible, setFilterByQRCodeMessageVisible] =
    useState(false);
  const [filterParams, setFilterParams] = useLocalStorage(TASK_FILTER_FORM, {});

  const tableTabs = getTableTabs({ t, downloadsTotalCount, taskCounterData });

  const onResetFilter = () => setFilterParams({});

  const onActiveTabChange = useCallback(
    (item: TabBarItemType) => {
      const orderingField =
        item.value === TaskScopeNameEnum.MyCompleted
          ? 'completedAt'
          : 'deadlineAt';
      const orderingDirection =
        item.value === TaskScopeNameEnum.MyCompleted
          ? ORDERING_DIRECTION_DESC
          : ORDERING_DIRECTION_ASC;
      onTabParamChange(item.value, {
        [GetUrlParams.OrderingField]: orderingField,
        [GetUrlParams.OrderingDirection]: orderingDirection,
      });
    },
    [onTabParamChange],
  );

  useEffect(() => {
    const siteAreaIds = _get(filterParams, TasksFilterFieldNames.SiteArea, []);
    const assetIds = _get(filterParams, TasksFilterFieldNames.Asset, []);
    setFilterByQRCodeMessageVisible(
      !!(siteAreaIds?.length || assetIds?.length),
    );
  }, [filterParams]);

  const errorStatus = tasksError ? getStatusFromError(tasksError) : undefined;
  const isTimeoutError = isErrorTimeoutError(tasksError);
  if (
    errorStatus &&
    tasksError &&
    isOnline &&
    !tasksLoading &&
    !isTimeoutError
  ) {
    return <ErrorPage status={errorStatus} description={tasksError.message} />;
  }

  return (
    <PageBody>
      <AppContentMaxWidth>
        <TabsWrap>
          <TabBar
            items={tableTabs}
            active={tabParam as string}
            onActiveChange={onActiveTabChange}
            withoutCrop
          />
        </TabsWrap>
        {tabParam === CustomTaskScopeNameEnum.Downloads ? (
          <TasksDownloads
            isFilterByQRCodeMessageVisible={isFilterByQRCodeMessageVisible}
            limitValue={downloadsLimitValue}
            onResetFilter={onResetFilter}
            onTabParamChange={onTabParamChange}
            setFilterByQRCodeMessageVisible={setFilterByQRCodeMessageVisible}
            tabParam={tabParam}
            tasks={downloadsData}
            tasksLoading={downloadsLoading}
            totalEntries={downloadsTotalCount}
          />
        ) : (
          <TasksList
            isFilterByQRCodeMessageVisible={isFilterByQRCodeMessageVisible}
            isOnline={isOnline}
            isTimeoutError={isTimeoutError}
            onResetFilter={onResetFilter}
            onTabParamChange={onTabParamChange}
            prevTabParam={prevTabParam}
            setFilterByQRCodeMessageVisible={setFilterByQRCodeMessageVisible}
            tabParam={tabParam}
            tasks={tasks}
            tasksFirstLoading={tasksFirstLoading}
            tasksLoading={tasksLoading}
            totalEntries={totalEntries}
          />
        )}
      </AppContentMaxWidth>
    </PageBody>
  );
}

export default Tasks;
