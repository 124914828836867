import { useLocation, useNavigate, useParams } from 'react-router-dom';
import _get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import toast from '@lib/components/Toast/Toast';
import { showGraphQLErrors } from '@lib/utils/graphQLErrors';
import {
  FormTemplate,
  FormTemplateVersion,
  Task,
  TaskScopeNameEnum,
  TaskStatusEnum,
  useAppTaskFormTemplateQuery,
  useAppTaskQuery,
  useTaskCompletionCreateMutation,
  useTaskFormCompletionQuery,
} from 'graphql-common';
import { APP_URLS } from 'constants/urls';
import { Values } from '@lib/interfaces/form';
import useIsOnline from '@lib/hooks/useIsOnline';
import getAnswers from 'routes/Task/utils/getAnswers';
import useManageCodes from 'hooks/useManageCodes';
import useTaskIndexedDB from 'hooks/useTaskIndexedDB';
import {
  removeOutdatedTasksFromIndexedDB,
  saveToIndexedDB,
} from 'utils/indexedDBUtils';
import { CacheNames, CustomTaskScopeNameEnum } from 'constants/enums';
import { useMemo, useRef } from 'react';
import { FormRef } from '@lib/components/ReactHookForm/types';
import isErrorTimeoutError from '@lib/utils/isErrorTimeoutError';

const checkCodesHelper = (
  data: Task | null,
  checkCode: (v: string) => boolean,
) => {
  let available = false;
  if (data?.asset?.code) {
    available = checkCode(data.asset.code);
  }
  if (data?.siteArea?.code && !available) {
    available = checkCode(data.siteArea.code);
  }
  return available;
};

export default function useActions() {
  const { id = '' } = useParams();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isOnline = useIsOnline();
  const formRef = useRef<FormRef>();
  const { checkCode } = useManageCodes();

  // Task
  const dynamicPath = { pathParts: { id: id || '' } };
  const variables = id ? { id } : undefined;

  const isTaskQueryEnabled =
    id &&
    isOnline &&
    APP_URLS.app.tasks.view.getDynamicPath(dynamicPath) === pathname;
  const taskQuery = useAppTaskQuery({
    skip: !isTaskQueryEnabled,
    variables,
    fetchPolicy: 'cache-and-network',
  });

  const isTaskFormTemplateQueryEnabled =
    id &&
    isOnline &&
    APP_URLS.app.tasks.completionCreate.getDynamicPath(dynamicPath) ===
      pathname;
  const taskFormTemplateQuery = useAppTaskFormTemplateQuery({
    skip: !isTaskFormTemplateQueryEnabled,
    variables,
    fetchPolicy: 'network-only',
  });

  const isTaskCompletionQueryEnabled =
    id &&
    isOnline &&
    APP_URLS.app.tasks.completionView.getDynamicPath(dynamicPath) === pathname;
  const taskCompletionQuery = useTaskFormCompletionQuery({
    skip: !isTaskCompletionQueryEnabled,
    variables,
    fetchPolicy: 'network-only',
  });

  const {
    data: downloadData,
    loading: downloadDataLoading,
    refetch: downloadDataRefetch,
  } = useTaskIndexedDB(id);

  const { error: taskError, loading: taskLoading } = taskQuery;
  const { error: taskFormTemplateError, loading: taskFormTemplateLoading } =
    taskFormTemplateQuery;
  const { error: taskCompletionError, loading: taskCompletionLoading } =
    taskCompletionQuery;

  const isTaskTimeoutError = isErrorTimeoutError(taskError);
  const isTaskFormTemplateTimeoutError = isErrorTimeoutError(
    taskFormTemplateError,
  );

  const taskData =
    isOnline && !isTaskTimeoutError
      ? (_get(taskQuery, 'data.data') as Task)
      : downloadData;
  const taskDataForFormTemplate = _get(
    taskFormTemplateQuery,
    'data.data',
  ) as Task;
  const taskDataForCompletion = _get(taskCompletionQuery, 'data.data') as Task;

  const taskFormTemplate =
    isOnline && !isTaskFormTemplateTimeoutError
      ? (_get(
          taskDataForFormTemplate || taskDataForCompletion,
          'formTemplate',
        ) as unknown as FormTemplate)
      : _get(downloadData, 'formTemplate');
  const taskFormTemplateVersion =
    isOnline && !isTaskFormTemplateTimeoutError
      ? (_get(
          taskDataForFormTemplate || taskDataForCompletion,
          'formTemplateVersion',
        ) as unknown as FormTemplateVersion)
      : _get(downloadData, 'formTemplateVersion');

  const afterCompletionCallback = (
    taskCompletionId?: string,
    isTimeout?: boolean,
  ) => {
    const callbackFn = () => {
      navigate(
        APP_URLS.app.tasks.index.getPathWithQuery({
          tab: isOnline
            ? TaskScopeNameEnum.MyTodoAvailableToComplete
            : CustomTaskScopeNameEnum.Downloads,
        }),
      );
      toast({
        content:
          isOnline && !isTimeout
            ? t('task-finished-successfully')
            : t('task-finished-successfully-offline'),
      });
    };
    if (taskCompletionId) {
      removeOutdatedTasksFromIndexedDB([taskCompletionId]).then(callbackFn);
    } else {
      callbackFn();
    }
  };

  // Completion create
  const [
    taskCompletionCreateMutation,
    { loading: taskCompletionCreateMutationLoading },
  ] = useTaskCompletionCreateMutation({
    onCompleted: ({ taskCompletionCreate }) => {
      afterCompletionCallback(taskCompletionCreate?.id);
    },
    onError: (error, options) => {
      if (isErrorTimeoutError(error)) {
        const taskId = _get(options, ['variables', 'input', 'taskId']);
        afterCompletionCallback(taskId, true);
      } else {
        showGraphQLErrors(error, t);
      }
    },
  });
  const onTaskCompletionCreateMutation = (values: Values) => {
    if (taskFormTemplateVersion && id) {
      const answers = getAnswers(values, taskFormTemplateVersion);
      saveToIndexedDB(CacheNames.taskCompletions, {
        id,
        taskId: id,
        taskName: downloadData?.name,
        answers,
      }).then(() => {
        if (isOnline) {
          taskCompletionCreateMutation({
            variables: { input: { taskId: id, answers } },
          });
        } else {
          downloadDataRefetch().then(() => {
            afterCompletionCallback(id);
          });
        }
      });
    }
  };

  const isFormAvailable = useMemo(() => {
    if (!isOnline || isTaskFormTemplateTimeoutError) {
      return checkCodesHelper(downloadData, checkCode);
    }
    if (isTaskQueryEnabled && !(taskLoading || downloadDataLoading)) {
      return checkCodesHelper(taskData, checkCode);
    }
    if (
      isTaskFormTemplateQueryEnabled &&
      !(taskFormTemplateLoading || downloadDataLoading)
    ) {
      return checkCodesHelper(taskDataForFormTemplate, checkCode);
    }
    return !!isTaskCompletionQueryEnabled;
  }, [
    checkCode,
    downloadData,
    downloadDataLoading,
    isOnline,
    isTaskCompletionQueryEnabled,
    isTaskFormTemplateQueryEnabled,
    isTaskFormTemplateTimeoutError,
    isTaskQueryEnabled,
    taskData,
    taskDataForFormTemplate,
    taskFormTemplateLoading,
    taskLoading,
  ]);

  const isFormDisabled = useMemo(() => {
    let isDisabled = false;
    if (isTaskQueryEnabled && !taskLoading) {
      if (
        taskData?.canCompletionCreate?.value === false &&
        taskData?.status === TaskStatusEnum.Validated
      ) {
        isDisabled = true; // it is Upcoming task
      }
    } else if (isTaskFormTemplateQueryEnabled && !taskFormTemplateLoading) {
      if (
        taskDataForFormTemplate?.canCompletionCreate?.value === false &&
        taskDataForFormTemplate?.status === TaskStatusEnum.Validated
      ) {
        isDisabled = true; // it is Upcoming task
      }
    } else if (!isOnline && !downloadDataLoading) {
      if (
        downloadData?.canCompletionCreate?.value === false &&
        downloadData?.status === TaskStatusEnum.Validated
      ) {
        isDisabled = true; // it is Upcoming task
      }
    }
    return isDisabled;
  }, [
    downloadData?.canCompletionCreate?.value,
    downloadData?.status,
    downloadDataLoading,
    isOnline,
    isTaskFormTemplateQueryEnabled,
    isTaskQueryEnabled,
    taskData?.canCompletionCreate?.value,
    taskData?.status,
    taskDataForFormTemplate?.canCompletionCreate?.value,
    taskDataForFormTemplate?.status,
    taskFormTemplateLoading,
    taskLoading,
  ]);

  return {
    id,
    isFormAvailable,
    isFormDisabled,
    onTaskCompletionCreateMutation,
    taskData,
    taskDataForCompletion,
    taskDataForFormTemplate,
    taskError,
    taskFormTemplate,
    isTaskFormTemplateTimeoutError,
    isTaskTimeoutError,
    taskFormTemplateError:
      isTaskFormTemplateTimeoutError && taskFormTemplate?.id
        ? undefined
        : taskFormTemplateError,
    taskFormTemplateLoading: isOnline
      ? taskFormTemplateLoading
      : downloadDataLoading,
    taskLoading: isOnline ? taskLoading : downloadDataLoading,
    taskCompletionError,
    taskCompletionLoading,
    isSynced: !!downloadData?.id,
    formRef,
    taskFormTemplateVersion,
    taskCompletionCreateMutationLoading,
  };
}
