import React from 'react';
import { TFunction } from 'i18next';
import * as Yup from 'yup';
import _get from 'lodash/get';
import { Counter } from 'graphql-common';
import Input from '@lib/components/Input/Input';
import { FieldItem } from '@lib/interfaces/form';
import AssetCounterIndexFieldNames from '@lib/enums/fieldNames/assetCounterIndexFieldNames';
import AssetCounterInfoView from 'components/AssetCounterInfoView/AssetCounterInfoView';
import RadioButtonGroup, {
  RadioButtonWrapViews,
} from '@lib/components/RadioButtonGroup/RadioButtonGroup';
import { getDefaultNumberValidation, getOptionShape } from '@lib/utils/yup';
import RadioButtonAsButton from '@lib/components/RadioButtonAsButton/RadioButtonAsButton';
import { getMeasurementAbbreviation } from '@lib/utils/measure';
import { CounterIndexAction } from 'components/AssetCounterIndexModalForm/enums';
import { DECIMAL_NUMBER_INPUT_PROPS } from '@lib/enums/form';

type Args = {
  assetCounter?: Counter;
  t: TFunction<'translation', undefined>;
};

export default function getFormFields({ assetCounter, t }: Args): FieldItem[] {
  const indexAbbreviation = getMeasurementAbbreviation(
    assetCounter?.measureType,
    assetCounter?.measureUnit,
    t,
  );
  return [
    {
      name: 'counter-view',
      customRender: () => <AssetCounterInfoView counter={assetCounter} />,
    },
    {
      name: AssetCounterIndexFieldNames.Action,
      element: RadioButtonGroup,
      validation: Yup.object()
        .shape(getOptionShape(t, true))
        .required(t('required-field-error')),
      componentProps: {
        options: [
          { label: t('enter-index'), value: CounterIndexAction.Index },
          { label: t('reset-to-zero'), value: CounterIndexAction.Zero },
        ],
        RadioButtonComponent: RadioButtonAsButton,
        wrapView: RadioButtonWrapViews.row,
      },
      fieldChangeCallBack: ({ values, setValue, name }) => {
        if (name === AssetCounterIndexFieldNames.Action) {
          if (
            _get(values, [AssetCounterIndexFieldNames.Action, 'value']) ===
            CounterIndexAction.Zero
          ) {
            setValue(AssetCounterIndexFieldNames.Index, '0');
          } else {
            setValue(AssetCounterIndexFieldNames.Index, '');
          }
        }
      },
    },
    {
      name: AssetCounterIndexFieldNames.Index,
      label: indexAbbreviation
        ? `${t('index')} ${indexAbbreviation}`
        : t('index'),
      element: Input,
      componentProps: {
        ...DECIMAL_NUMBER_INPUT_PROPS,
        hasErrorAsTooltip: true,
      },
      hideErrors: true,
      validation: getDefaultNumberValidation(t, true),
      description: assetCounter?.prohibitLowerIndexThanPrevious
        ? t('index-validation-desc')
        : undefined,
      isDisabledFn: (values) =>
        _get(values, [AssetCounterIndexFieldNames.Action, 'value']) === 'zero',
    },
    {
      name: 'empty',
      customRender: () => <div />,
    },
  ];
}
