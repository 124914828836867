import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'usehooks-ts';
import _valuesIn from 'lodash/valuesIn';
import _without from 'lodash/without';
import { Task, TaskScopeNameEnum } from 'graphql-common';
import CircleLoader from '@lib/components/CircleLoader/CircleLoader';
import Pagination from '@lib/components/Pagination/Pagination';
import useSearchParam from '@lib/hooks/useSearchParam';
import TaskPreview from 'components/TaskPreview/TaskPreview';
import useIsOnline from '@lib/hooks/useIsOnline';
import SupportiveMessage from '@lib/components/SupportiveMessage/SupportiveMessage';
import IconButton, {
  IconButtonTypes,
} from '@lib/components/IconButton/IconButton';
import {
  CustomTaskScopeNameEnum,
  TaskScopeNameEnumExtended,
} from 'constants/enums';
import useOutletContext, { ContextProps } from 'hooks/useOutletContext';
import { TASK_FILTER_FORM } from '@lib/enums/localStorageKeys';
import TasksEmptyState from './TasksEmptyState';
import TasksFilter from './TasksFilter';
import styles from './Tasks.module.scss';

type Props = {
  limitValue: number;
  isFilterByQRCodeMessageVisible: boolean;
  onResetFilter: () => void;
  onTabParamChange: (v: string) => void;
  setFilterByQRCodeMessageVisible: (v: boolean) => void;
  tabParam?: TaskScopeNameEnumExtended;
  tasks: Task[];
  tasksLoading: boolean;
  totalEntries: number;
};

function TasksDownloads(props: Props) {
  const {
    limitValue,
    isFilterByQRCodeMessageVisible,
    onResetFilter,
    onTabParamChange,
    setFilterByQRCodeMessageVisible,
    tabParam,
    tasks,
    tasksLoading,
    totalEntries,
  } = props;

  const { tasksSyncLoading }: ContextProps = useOutletContext();

  const isOnline = useIsOnline();
  const { t } = useTranslation();
  const [filterParams] = useLocalStorage(TASK_FILTER_FORM, {});
  const [searchQuery, setSearchQuery] = useSearchParam();
  const isContentVisible = !!limitValue && !!tasks.length;
  const isEmptyStateVisible = !isContentVisible && !tasksLoading;
  const isPaginationVisible =
    !!totalEntries && !!tasks.length && totalEntries > tasks.length;
  const isSearchApplied = !!searchQuery;
  const isFilterApplied = !!_without(_valuesIn(filterParams), undefined).length;
  const isEmptyState =
    isEmptyStateVisible && !isSearchApplied && !isFilterApplied;
  const isEmptyStateBySearch = isEmptyStateVisible && isSearchApplied;
  const isEmptyStateByFilter = isEmptyStateVisible && isFilterApplied;

  const emptyState = (
    <TasksEmptyState
      {...{
        isEmptyState,
        isEmptyStateByFilter,
        isEmptyStateBySearch,
        isOnline,
        onResetFilter,
        onTabParamChange,
        setSearchQuery,
        tabParam,
      }}
    />
  );

  const content = (
    <>
      <div className={styles.tasksGrid}>
        {(tabParam === CustomTaskScopeNameEnum.Downloads ? tasks : tasks).map(
          (item) => (
            <div key={item.id}>
              <TaskPreview data={item} scope={tabParam as TaskScopeNameEnum} />
            </div>
          ),
        )}
      </div>
      {isPaginationVisible && (
        <div className={styles.pagination}>
          <Pagination totalEntries={totalEntries} />
        </div>
      )}
    </>
  );

  const isLoading = tasksSyncLoading || tasksLoading;

  return (
    <div>
      {isLoading ? (
        <CircleLoader />
      ) : (
        <div>
          {!isEmptyState && <TasksFilter onResetFilter={onResetFilter} />}
          {isFilterByQRCodeMessageVisible && (
            <SupportiveMessage
              text={t('tasks-qr-code-filter-text')}
              className={styles.message}
              actions={
                <IconButton
                  icon="close"
                  type={IconButtonTypes.PrimaryOutlined}
                  onClick={() => setFilterByQRCodeMessageVisible(false)}
                />
              }
            />
          )}
          {limitValue > 0 ? content : emptyState}
        </div>
      )}
    </div>
  );
}

export default TasksDownloads;
