import {
  ApolloClient,
  ApolloLink,
  from,
  HttpLink,
  InMemoryCache,
  Observable,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/react';
import _some from 'lodash/some';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import { getSessionTokenCookie } from '@lib/utils/cookieUtils';
import { FETCH_TIMEOUT, TIMEOUT_ERROR } from '@lib/constants/fetch';
import { captureException } from 'utils/captureException';

const { VITE_API_URL: API_DOMAIN, MODE } = import.meta.env;

if (MODE && ['development', 'test'].includes(MODE)) {
  loadDevMessages();
  loadErrorMessages();
}

const customHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
  'X-App-Component': 'system',
  'Cache-Control': 'no-cache',
};

const cors = {
  xhrFields: { withCredentials: true },
  crossDomain: true,
  mode: 'cors',
  credentials: 'include',
};

const customFetch = async (uri, options) => {
  const controller = new AbortController();
  const timeoutId = setTimeout(
    () => controller.abort(new Error(TIMEOUT_ERROR)),
    FETCH_TIMEOUT,
  );

  const isServiceWorkerAvailable =
    'serviceWorker' in navigator &&
    (navigator.serviceWorker as ServiceWorkerContainer).controller;

  if (options.method !== 'OPTIONS' && isServiceWorkerAvailable) {
    return new Promise<Response>((resolve, reject) => {
      const messageChannel = new MessageChannel();

      messageChannel.port1.onmessage = (event) => {
        clearTimeout(timeoutId);
        if (event.data.error) {
          const errorMessage = event.data.error?.message;
          resolve(
            new Response(
              JSON.stringify({
                errors: [{ message: errorMessage }],
              }),
              {
                status: event.data.status || 503,
                headers: { 'Content-Type': 'application/json' },
              },
            ),
          );
        } else {
          const response = new Response(new Blob([event.data.body]), {
            status: event.data.status,
            statusText: event.data.statusText,
            headers: event.data.headers,
          });

          const requestId = response.headers.get('x-request-id');
          if (requestId) {
            Sentry.setTag('request_id', requestId);
          }

          resolve(response);
        }
      };
      if ('serviceWorker' in navigator) {
        const sanitizedOptions = { ...options };
        delete sanitizedOptions.signal;
        const serviceWorker = navigator.serviceWorker as ServiceWorkerContainer;
        serviceWorker.controller?.postMessage(
          { type: 'graphql-request', uri, options: sanitizedOptions },
          [messageChannel.port2],
        );
      }
    });
  }

  try {
    const response = await fetch(uri, {
      ...options,
      signal: controller.signal,
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const requestId = response.headers.get('x-request-id');
    if (requestId) {
      Sentry.setTag('request_id', requestId);
    }
    return response;
  } catch (error) {
    clearTimeout(timeoutId);
    throw error;
  } finally {
    clearTimeout(timeoutId);
  }
};

const singleHttpLink = new HttpLink({
  uri: `${API_DOMAIN}/graphql`,
  headers: customHeaders,
  fetch: customFetch,
});

const httpLink = new BatchHttpLink({
  uri: `${API_DOMAIN}/graphql`,
  batchMax: 5,
  batchInterval: 20,
  headers: customHeaders,
  fetch: customFetch,
  ...cors,
});

const authLink = setContext((_, { headers }) => {
  const token = getSessionTokenCookie();
  if (!token) {
    return {
      headers: {
        ...headers,
      },
    };
  }
  // return the headers to be used in the HTTP request
  return {
    headers: {
      ...headers,
      AUTHORIZATION: `Bearer ${token}`,
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  const isProblemExist = _some(
    graphQLErrors,
    ({ extensions }) => !_isEmpty(extensions?.problems),
  );
  const isUndefinedField = _some(graphQLErrors, ({ extensions }) =>
    _isEqual(extensions?.code, 'undefinedField'),
  );
  if (graphQLErrors && (isProblemExist || isUndefinedField)) {
    graphQLErrors.forEach((error) => {
      captureException(
        new Error(`[GraphQL error]: Message: ${error.message}`),
        error,
      );
    });
  }

  const statusCode = _get(networkError, 'statusCode');
  if (networkError && statusCode !== 429) {
    captureException(networkError);
  }
});

let hasFetchedToken = false;
const optionsRequestLink = new ApolloLink((operation, forward) => {
  if (hasFetchedToken) {
    // If token has been fetched, proceed with the operation
    return forward(operation);
  }
  return new Observable((observer) => {
    // Send an OPTIONS request
    fetch('/graphql', {
      method: 'OPTIONS',
      credentials: 'include',
    })
      .then(() => {
        hasFetchedToken = true;
      })
      .finally(() => {
        // Proceed with the original request
        forward(operation).subscribe({
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        });
      })
      .catch((error) => {
        observer.error(error);
      });
  });
});

const sentryTracingLink = new ApolloLink((operation, forward) => {
  return Sentry.startSpan(
    { name: operation.operationName || 'GraphQL Request', op: 'graphql' },
    (span) => {
      return forward(operation).map((response) => {
        span?.end();
        return response;
      });
    },
  );
});

const customLink = new ApolloLink((operation, forward) => {
  const { operationName } = operation;
  return ['appFullTask', 'taskCompletionCreate', 'Whoami'].includes(
    operationName,
  )
    ? singleHttpLink.request(operation, forward)
    : httpLink.request(operation);
});

const link = from([
  sentryTracingLink,
  optionsRequestLink,
  authLink,
  errorLink,
  customLink,
]);

const Client = new ApolloClient({
  cache: new InMemoryCache(),
  link,
  defaultOptions: {
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
  },
});

export default Client;
