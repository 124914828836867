import React from 'react';
import { useTranslation } from 'react-i18next';
import Chip, { ChipTypes } from '@lib/components/Chip/Chip';
import styles from './SyncLabel.module.scss';

interface Props {
  isSynced: boolean;
}

export default function SyncLabel({ isSynced }: Props) {
  const { t } = useTranslation();
  return (
    <Chip
      type={ChipTypes.chipGray}
      label={t(isSynced ? 'synced' : 'unsynced')}
      leftIcon={isSynced ? 'offline_pin' : 'sync_disabled'}
      leftIconClassName={isSynced ? styles.syncedLeftIconClassName : undefined}
      fill={false}
    />
  );
}
