import { Access, ComponentEnum, LevelEnum } from 'graphql-common';
import { checkUserAccessByComponent } from '@lib/utils/accesses';
import { APP_URLS } from 'constants/urls';

export default function getHomePageUrl(accesses: Access[]) {
  if (
    checkUserAccessByComponent(accesses, ComponentEnum.TasksPerforming) !==
    LevelEnum.LimitedRead
  ) {
    return APP_URLS.app.tasks.index.getPathWithQuery();
  }
  if (
    checkUserAccessByComponent(
      accesses,
      ComponentEnum.InterventionsManagement,
    ) !== LevelEnum.LimitedRead
  ) {
    return APP_URLS.app.interventions.index.getPathWithQuery();
  }
  return APP_URLS.app.profile.path;
}
