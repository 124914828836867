import _get from 'lodash/get';
import { TFunction } from 'i18next';
import { InterventionScopeNameEnum } from 'graphql-common';

type Args = {
  t: TFunction<'translation', undefined>;
  interventionsCounter: object;
};

export default function getTabs({ t, interventionsCounter }: Args) {
  return [
    {
      label: t('my-interventions'),
      value: InterventionScopeNameEnum.OpenedMyParticipation,
      counter: _get(interventionsCounter, 'openedMyParticipation', 0),
    },
    {
      label: t('active'),
      value: InterventionScopeNameEnum.Opened,
      counter: _get(interventionsCounter, 'opened', 0),
    },
    {
      label: t('closed'),
      value: InterventionScopeNameEnum.Closed,
      counter: _get(interventionsCounter, 'closed', 0),
    },
  ];
}
