import React from 'react';
import ErrorPageCommon from '@lib/components/ErrorPage/ErrorPage';
import { ErrorPageStatusEnum } from '@lib/components/ErrorPage/enums';

type Props = {
  status?: ErrorPageStatusEnum;
};

export default function ErrorPage({
  status = ErrorPageStatusEnum.Status404,
}: Props) {
  return <ErrorPageCommon status={status} />;
}
