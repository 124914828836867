import React from 'react';
import { CheckboxProps } from '@lib/components/CheckBox/CheckBox';
import IconButton, {
  IconButtonProps,
  IconButtonTypes,
} from '@lib/components/IconButton/IconButton';

interface Props {
  onChange?: () => void;
  getIcon: (checked: boolean) => string;
}

export default function CheckBoxAsIconButton(
  props: Props & CheckboxProps & IconButtonProps,
) {
  const { checked, onChange, disabled, symbolsOutlined, size, getIcon } = props;
  return (
    <IconButton
      size={size}
      type={
        checked
          ? IconButtonTypes.SecondaryInvert
          : IconButtonTypes.TertiaryInvert
      }
      disabled={disabled}
      icon={getIcon(checked)}
      symbolsOutlined={symbolsOutlined}
      onClick={() => onChange()}
    />
  );
}
