import React from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import DashboardTitleHeader from '@lib/layouts/DashboardLayout/DashboardTitleHeader/DashboardTitleHeader';
import { ButtonTypes } from '@lib/components/Button/Button';
import ActionsMenu from '@lib/components/ActionsMenu/ActionsMenu';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import Typography from '@lib/components/Typography/Typography';
import EntityViewStyles from '@lib/assets/styles/EntityView.module.scss';
import CountMark, { CountMarkTypes } from '@lib/components/CountMark/CountMark';
import DocumentationPreview from '@lib/components/DocumentationPreview/DocumentationPreview';
import Carousel from '@lib/components/Carousel/Carousel';
import { DocumentationPreviewEnum } from '@lib/components/DocumentationPreview/enums';
import { UppyDocumentationAllowedFileTypes } from '@lib/enums/mimeTypes';
import FilesUploader from '@lib/components/FilesUploader/FilesUploader';
import CircleLoaderWrapper, {
  CircleLoaderWrapperType,
} from '@lib/components/CircleLoaderWrapper/CircleLoaderWrapper';
import InterventionInfo from 'routes/Intervention/InterventionInfo/InterventionInfo';
import TaskPreview from 'components/TaskPreview/TaskPreview';
import TextEditor from '@lib/components/TextEditor/TextEditor';
import getInterventionViewPageActionsMenu from '../utils/getInterventionViewPageActionsMenu';
import { UseInterventionActionsResult } from '../utils/useInterventionActions';
import { UseModalManagementResult } from '../utils/useModalManagement';
import { InterventionViewMode } from '../enums';

type Props = {
  useModalManagementResult: UseModalManagementResult;
  useInterventionActionsResult: UseInterventionActionsResult;
  isViewOnly: boolean;
};

export default function InterventionViewDetails({
  useModalManagementResult,
  useInterventionActionsResult,
  isViewOnly,
}: Props) {
  const { t } = useTranslation();
  const {
    interventionQueryHookResult,
    loading,
    onAttachFiles,
    onViewChange,
    taskQueryHookResult,
    openCreateActionPage,
  } = useInterventionActionsResult;
  const { data } = interventionQueryHookResult;
  const {
    name,
    description,
    attachmentsAttached,
    canDestroy,
    canClose,
    canUpdate,
  } = data || {};
  const isDestroyAvailable = !isViewOnly && canDestroy?.value;
  const isCloseAvailable = !isViewOnly && canClose?.value;
  const isUpdateAvailable = !isViewOnly && canUpdate?.value;
  const isCreateActionAvailable = !isViewOnly && canUpdate?.value;

  const { data: task } = taskQueryHookResult;

  const actionsMenu = getInterventionViewPageActionsMenu({
    t,
    onCreateAction: isCreateActionAvailable ? openCreateActionPage : undefined,
    onEdit: isUpdateAvailable
      ? () => onViewChange(InterventionViewMode.Edit)
      : undefined,
    onDelete: isDestroyAvailable
      ? useModalManagementResult.openInterventionDestroyModal
      : undefined,
    onClose: isCloseAvailable
      ? useModalManagementResult.openInterventionCloseModal
      : undefined,
  });

  const isMenuVisible = actionsMenu.length > 0;

  return (
    <CircleLoaderWrapper
      isLoading={loading}
      type={CircleLoaderWrapperType.blur}
    >
      <DashboardTitleHeader
        title={name}
        actions={[
          isMenuVisible ? (
            <ActionsMenu
              key="actions-menu"
              actions={actionsMenu}
              disabled={loading}
            />
          ) : undefined,
        ]}
      />
      {!!description && (
        <div className={EntityViewStyles.infoItemCol}>
          <p className={EntityViewStyles.infoItemTitle}>
            <MaterialIcon icon="description" symbolsOutlined />
            <Typography variant="caption">{t('description')}</Typography>
          </p>
          <div className={EntityViewStyles.infoItemContent}>
            <TextEditor
              readOnly
              value={description}
              className={EntityViewStyles.textEditor}
            />
          </div>
        </div>
      )}
      <div className={EntityViewStyles.infoItemCol}>
        <div className={EntityViewStyles.infoItemHeader}>
          <div className={EntityViewStyles.infoItemTitle}>
            <MaterialIcon icon="attach_file" symbolsOutlined />
            <Typography
              variant="caption"
              className={EntityViewStyles.infoItemLabelText}
              component="div"
            >
              {t('attachments')}
              <CountMark
                value={attachmentsAttached?.length || 0}
                type={CountMarkTypes.blue}
              />
            </Typography>
          </div>
          {!isViewOnly && (
            <FilesUploader
              // @ts-ignore
              onChange={onAttachFiles}
              value={[]}
              uploadButtonProps={{
                buttonText: t('attach'),
                buttonType: ButtonTypes.primaryOutlined,
                leftIcon: 'attach_file',
                fullWidth: true,
              }}
              allowedFileTypes={UppyDocumentationAllowedFileTypes}
              note={t('files-upload-validation-msg', {
                sizeImg: 5,
                sizePdf: 25,
              })}
            />
          )}
        </div>
        {!!attachmentsAttached?.length && (
          <div
            className={EntityViewStyles.infoItemContent}
            key={attachmentsAttached?.length}
          >
            <div className={EntityViewStyles.infoItemContentFullWidth}>
              <Carousel withWrap spaceBetween={16} slidesPerView="auto">
                {attachmentsAttached.map((attachment) => (
                  <DocumentationPreview
                    key={uuidv4()}
                    attachment={attachment}
                    view={DocumentationPreviewEnum.BlockCard}
                    onClick={() =>
                      useModalManagementResult.openAttachmentPreviewModal(
                        attachment,
                      )
                    }
                  />
                ))}
              </Carousel>
            </div>
          </div>
        )}
      </div>
      {!!task?.id && (
        <div className={EntityViewStyles.infoItemCol}>
          <p className={EntityViewStyles.infoItemTitle}>
            <MaterialIcon icon="task_alt" symbolsOutlined />
            <Typography variant="caption">{t('linked-task')}</Typography>
          </p>
          <div className={EntityViewStyles.infoItemContent}>
            <TaskPreview data={task} />
          </div>
        </div>
      )}
      <div className={EntityViewStyles.infoItemCol}>
        <InterventionInfo
          useModalManagementResult={useModalManagementResult}
          useInterventionActionsResult={useInterventionActionsResult}
          isViewOnly={isViewOnly}
        />
      </div>
    </CircleLoaderWrapper>
  );
}
