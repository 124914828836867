import React from 'react';
import _get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { Site } from 'graphql-common';
import getFullName from '@lib/utils/getFullName';
import Typography from '@lib/components/Typography/Typography';
import Chip, { ChipTypes } from '@lib/components/Chip/Chip';
import { Values } from '@lib/interfaces/form';
import styles from './ProfileForm.module.scss';

interface Props {
  data?: Values;
}

function ProfileView(props: Props) {
  const { data } = props;
  const { t } = useTranslation();

  if (!data) return null;

  const { personalId, username, email } = data;

  const internationalPhoneNumber = _get(
    data,
    'phoneNumber.international',
  ) as string;
  const sites = _get(data, 'sites.collection') as Site[];

  const fullName = getFullName(data);

  return (
    <div className={styles.profileView}>
      {fullName && (
        <div>
          <Typography variant="caption" className={styles.profileViewLabel}>
            {t('name')}
          </Typography>
          <Typography variant="body" strong>
            {fullName}
          </Typography>
        </div>
      )}
      {personalId && typeof personalId === 'string' ? (
        <div>
          <Typography variant="caption" className={styles.profileViewLabel}>
            ID
          </Typography>
          <Typography variant="body" strong>
            {personalId}
          </Typography>
        </div>
      ) : null}
      {username && typeof username === 'string' ? (
        <div>
          <Typography variant="caption" className={styles.profileViewLabel}>
            {t('username')}
          </Typography>
          <Typography variant="body" strong>
            {username}
          </Typography>
        </div>
      ) : null}
      {email && typeof email === 'string' ? (
        <div>
          <Typography variant="caption" className={styles.profileViewLabel}>
            {t('work-email')}
          </Typography>
          <Typography variant="body" strong>
            {email}
          </Typography>
        </div>
      ) : null}
      {internationalPhoneNumber ? (
        <div>
          <Typography variant="caption" className={styles.profileViewLabel}>
            {t('phone-number')}
          </Typography>
          <Typography variant="body" strong>
            {internationalPhoneNumber}
          </Typography>
        </div>
      ) : null}
      {!!sites?.length && (
        <div>
          <Typography variant="caption">{t('site')}</Typography>
          <div className={styles.chipsRow}>
            {sites.map((site) => (
              <Chip type={ChipTypes.chipGray} label={site.name} key={site.id} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default ProfileView;
