import React from 'react';
import { useTranslation } from 'react-i18next';
import DashboardTitleHeader from '@lib/layouts/DashboardLayout/DashboardTitleHeader/DashboardTitleHeader';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import ListEmptyState from '@lib/components/ListEmptyState/ListEmptyState';
import CircleLoader from '@lib/components/CircleLoader/CircleLoader';
import ErrorPage from '@lib/components/ErrorPage/ErrorPage';
import getStatusFromError from '@lib/utils/getStatusFromError';
import CircleLoaderWrapper from '@lib/components/CircleLoaderWrapper/CircleLoaderWrapper';
import getInterventionViewPageActionsMenu from 'routes/Intervention/utils/getInterventionViewPageActionsMenu';
import ActionsMenu from '@lib/components/ActionsMenu/ActionsMenu';
import InterventionActions from '../InterventionActions/InterventionActions';
import { UseInterventionActionsResult } from '../utils/useInterventionActions';
import { UseModalManagementResult } from '../utils/useModalManagement';

type Props = {
  useModalManagementResult: UseModalManagementResult;
  useInterventionActionsResult: UseInterventionActionsResult;
  isViewOnly: boolean;
};

export default function InterventionViewActions({
  useModalManagementResult,
  useInterventionActionsResult,
  isViewOnly,
}: Props) {
  const { t } = useTranslation();
  const {
    interventionQueryHookResult,
    interventionActionsQueryHookResult,
    openCreateActionPage,
  } = useInterventionActionsResult;
  const { data } = interventionQueryHookResult;
  const { name, canCreateAction } = data || {};

  const { collection, loading, firstLoading, error, totalCount } =
    interventionActionsQueryHookResult;

  const isEmptyStateVisible = collection.length === 0 && !error && !loading;
  const emptyState = isEmptyStateVisible ? (
    <ListEmptyState
      title={t('no-actions-yet')}
      text={t('no-actions-text')}
      actions={
        isViewOnly
          ? []
          : [
              <Button
                key="add"
                buttonText={t('create-action')}
                buttonType={ButtonTypes.primaryFilled}
                leftIcon="add"
                onClick={() => openCreateActionPage()}
              />,
            ]
      }
    />
  ) : null;

  const isErrorStateVisible = !!error && !loading;
  const errorState = isErrorStateVisible ? (
    <ErrorPage
      status={getStatusFromError(error)}
      description={error?.message}
    />
  ) : null;

  const actionsMenu = getInterventionViewPageActionsMenu({
    t,
    onCreateAction: openCreateActionPage,
  });

  const headerActions =
    !isViewOnly && canCreateAction?.value
      ? [
          <ActionsMenu
            key="actions-menu"
            actions={actionsMenu}
            disabled={loading}
          />,
        ]
      : [];

  const isContentVisible = !error && !firstLoading && collection?.length;
  const content = isContentVisible ? (
    <CircleLoaderWrapper isLoading={loading}>
      <InterventionActions
        collection={collection}
        totalCount={totalCount}
        useInterventionActionsResult={useInterventionActionsResult}
        useModalManagementResult={useModalManagementResult}
        isViewOnly={isViewOnly}
      />
    </CircleLoaderWrapper>
  ) : null;

  return (
    <>
      <DashboardTitleHeader title={name} actions={headerActions} />
      {firstLoading && <CircleLoader />}
      {errorState}
      {emptyState}
      {content}
    </>
  );
}
