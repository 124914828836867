import { useCallback, useEffect, useState } from 'react';
import _get from 'lodash/get';
import _toNumber from 'lodash/toNumber';
import { Task } from 'graphql-common';
import { GetUrlParams, ORDERING_DIRECTION_DESC } from '@lib/enums/urls';
import { indexedDB } from 'utils/indexedDBUtils';
import { ListQueryHookProps } from 'utils/fetch/types';
import { CacheNames } from 'constants/enums';
import { captureException } from 'utils/captureException';
import { SelectOption } from '@lib/components/Select/types';
import TasksFilterFieldNames from '@lib/enums/fieldNames/tasksFilterFieldNames';

export const fetchDataFromDB = async (
  filters: ListQueryHookProps['filterParams'] = {},
  paginationParams: unknown = {},
) => {
  let filteredData: Task[] = [];
  let paginatedData: Task[] = [];
  try {
    const page = _toNumber(_get(paginationParams, GetUrlParams.Page));
    const perPage = _toNumber(_get(paginationParams, GetUrlParams.PerPage));
    const orderingField = _get(paginationParams, GetUrlParams.OrderingField);
    const orderingDirection = _get(
      paginationParams,
      GetUrlParams.OrderingDirection,
    );
    let db = indexedDB[CacheNames.tasks];

    // Apply sorting (completedAt param is used for Completed tab only, downloads data doesn't have it).
    if (orderingField && orderingField !== 'completedAt') {
      db = db.orderBy(orderingField);
      if (orderingDirection === ORDERING_DIRECTION_DESC) {
        db = db.reverse();
      }
    }

    // Apply filters using Dexie
    Object.keys(filters).forEach((key) => {
      const filterValue = _get(filters, key);

      if (filters[key]) {
        db = db.filter((task: Task) => {
          const parsedFilterValue = _get(filters, key) as SelectOption[];

          if (!parsedFilterValue?.length) return true;

          if (key === GetUrlParams.Priority) {
            return parsedFilterValue.some((fv) => fv.value === task[key]);
          }

          if (key === GetUrlParams.SiteCode) {
            return parsedFilterValue.some((fv) => {
              return fv.value === _get(task, ['site', 'code']);
            });
          }

          if (key === GetUrlParams.SiteAreaCode) {
            return parsedFilterValue.some((fv) => {
              return fv.value === _get(task, ['siteArea', 'code']);
            });
          }

          if (key === GetUrlParams.AssetCode) {
            return parsedFilterValue.some((fv) => {
              return fv.value === _get(task, ['asset', 'code']);
            });
          }

          if (
            [
              TasksFilterFieldNames.Site,
              TasksFilterFieldNames.SiteArea,
              TasksFilterFieldNames.Asset,
              TasksFilterFieldNames.FormCategory,
            ].includes(key as TasksFilterFieldNames)
          ) {
            return parsedFilterValue.some((fv) => {
              return fv.value === _get(task, [key, 'id']);
            });
          }

          return true;
        });
      }

      if (key === TasksFilterFieldNames.SiteArea) {
        const assetFilterValue = _get(filters, TasksFilterFieldNames.Asset);
        if (!assetFilterValue) {
          db = db.filter((task: Task) => !task.asset);
        }
      }

      if (key === GetUrlParams.PeriodStart || key === GetUrlParams.PeriodEnd) {
        const filterDate =
          typeof filterValue === 'string' ? new Date(filterValue) : undefined;

        if (key === 'periodStart' && filterDate) {
          db = db.filter(
            (task: Task) => new Date(task.deadlineAt) >= filterDate,
          );
        }

        if (key === 'periodEnd' && filterDate) {
          db = db.filter(
            (task: Task) => new Date(task.deadlineAt) <= filterDate,
          );
        }
      }
    });

    filteredData = await db.toArray();
    paginatedData = await db
      .offset((page - 1) * perPage)
      .limit(perPage)
      .toArray();
  } catch (e) {
    captureException(e);
  }
  return {
    filteredData,
    paginatedData,
    limit: paginatedData.length,
    total: filteredData.length,
  };
};

const useTasksIndexedDB = (
  options: ListQueryHookProps,
  disabled?: boolean,
  reset?: boolean,
) => {
  const { filterParams, paginationParams } = options;
  const [data, setData] = useState<Task[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [limitValue, setLimitValue] = useState(0);
  const [loading, setLoading] = useState(true);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const { paginatedData, total, limit } = await fetchDataFromDB(
        filterParams,
        paginationParams,
      );
      setTotalCount(total);
      setLimitValue(limit);
      setData(paginatedData);
    } catch (error) {
      captureException(error);
    } finally {
      setLoading(false);
    }
  }, [filterParams, paginationParams]);

  useEffect(() => {
    if (!disabled) {
      fetchData();
    }
  }, [disabled, fetchData, reset]);

  return { data, totalCount, loading, limitValue, refetch: fetchData };
};

export default useTasksIndexedDB;
