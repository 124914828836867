import { ScannerActionType } from 'routes/Scanner/enums';
import { SelectOption } from '@lib/components/Select/types';

type Args = {
  isCountersAvailable: boolean;
  isCreateInterventionsAvailable: boolean;
  isInterventionsAvailable: boolean;
  isTasksAvailable: boolean;
};

export default function getScannerActionOptions({
  isCountersAvailable,
  isCreateInterventionsAvailable,
  isInterventionsAvailable,
  isTasksAvailable,
}: Args) {
  const result: SelectOption[] = [];
  if (isTasksAvailable) {
    result.push({
      labelKey: 'find-tasks',
      value: ScannerActionType.FindTasks,
      data: {
        icon: 'task_alt',
      },
    });
  }
  if (isInterventionsAvailable) {
    result.push({
      labelKey: 'find-interventions',
      value: ScannerActionType.FindInterventions,
      data: {
        icon: 'build',
      },
    });
  }
  if (isCreateInterventionsAvailable) {
    result.push({
      labelKey: 'create-intervention',
      value: ScannerActionType.CreateIntervention,
      data: {
        icon: 'add',
      },
    });
  }
  if (isCountersAvailable) {
    result.push({
      labelKey: 'add-counter-index',
      value: ScannerActionType.AddCounterIndex,
      data: {
        icon: 'speed',
      },
    });
  }
  return result;
}
