import {
  AppTasksQueryVariables,
  FilterGroupingEnum,
  Task,
  TaskFilterInputObject,
  TaskScopeNameEnum,
  useAppTasksCountersQuery,
  useAppTasksQuery,
} from 'graphql-common';
import TasksFilterFieldNames from '@lib/enums/fieldNames/tasksFilterFieldNames';
import _get from 'lodash/get';
import createSearchFilters from '@lib/utils/createSearchFilters';
import { CustomTaskScopeNameEnum } from 'constants/enums';
import getTaskCounterData from '@lib/utils/getTaskCounterData';
import { ListQueryHookProps } from './types';
import {
  addFilter,
  getPaginationAndSorting,
  parseFilterValues,
} from './helpers';

const createFilters = (
  filterParams: any,
  searchQuery?: string,
): TaskFilterInputObject[] => {
  const filters: TaskFilterInputObject[] = [];

  if (searchQuery) {
    filters.push(
      ...createSearchFilters(searchQuery, [
        { fieldName: 'name' },
        { fieldName: 'formCategoryName' },
        { fieldName: 'siteName' },
        { fieldName: 'siteAreaName' },
        { fieldName: 'priority', predicateType: 'eq' },
      ]),
    );
  }

  const periodValue = _get(
    filterParams,
    TasksFilterFieldNames.Period,
    [],
  ) as string[];
  const [periodStart, periodEnd] = periodValue;
  if (periodStart) {
    filters.push({
      deadlineAt: {
        grouping: FilterGroupingEnum.And,
        predicate: { gteq: periodStart },
      },
    });
  }
  if (periodEnd) {
    filters.push({
      deadlineAt: {
        grouping: FilterGroupingEnum.And,
        predicate: { lteq: periodEnd },
      },
    });
  }

  addFilter(
    filters,
    'priority',
    parseFilterValues(_get(filterParams, TasksFilterFieldNames.Priority)),
  );

  addFilter(
    filters,
    'siteId',
    parseFilterValues(_get(filterParams, TasksFilterFieldNames.Site)),
  );

  const siteAreaIdValue = _get(filterParams, TasksFilterFieldNames.SiteArea);
  const assetIdValue = _get(filterParams, TasksFilterFieldNames.Asset);

  addFilter(filters, 'siteAreaId', parseFilterValues(siteAreaIdValue));

  if (assetIdValue && assetIdValue.length > 0) {
    addFilter(filters, 'assetId', parseFilterValues(assetIdValue));
  } else if (siteAreaIdValue) {
    filters.push({
      assetId: {
        grouping: FilterGroupingEnum.And,
        predicate: { null: true },
      },
    });
  }

  addFilter(
    filters,
    'formCategoryId',
    parseFilterValues(_get(filterParams, TasksFilterFieldNames.FormCategory)),
  );

  return filters;
};

export default function useAppTasksQueryHook({
  searchQuery,
  tabParam,
  filterParams,
  paginationParams,
  orderingParams,
}: ListQueryHookProps) {
  const { page, perPage, orderingField, orderingDirection } =
    getPaginationAndSorting(paginationParams, orderingParams);

  const tasksQueryVariables: AppTasksQueryVariables = {
    page,
    limit: perPage,
    scope: { name: (tabParam || '') as TaskScopeNameEnum },
    sorts:
      orderingField && orderingDirection
        ? { [orderingField]: orderingDirection }
        : undefined,
    filters: createFilters(filterParams, searchQuery),
  };

  // Adjust parameters based on scope
  switch (tabParam) {
    case TaskScopeNameEnum.MyTodoAvailableToComplete:
    case TaskScopeNameEnum.MyTodoUpcoming:
      tasksQueryVariables.includeDeadlineAt = true;
      tasksQueryVariables.includeLateFor = true;
      break;
    case TaskScopeNameEnum.MyCompleted:
      tasksQueryVariables.includeCompletedAt = true;
      break;
    default:
      break;
  }

  const tasksQuery = useAppTasksQuery({
    fetchPolicy: 'no-cache',
    variables: tasksQueryVariables,
    skip: tabParam === CustomTaskScopeNameEnum.Downloads,
  });
  const tasksCountersQuery = useAppTasksCountersQuery({
    fetchPolicy: 'no-cache',
    variables: tasksQueryVariables,
  });
  const {
    loading: tasksLoading,
    data,
    previousData,
    refetch: refetchTasks,
    error: tasksError,
  } = tasksQuery;
  const {
    data: countersData,
    previousData: previousCountersData,
    refetch: refetchTasksCounters,
  } = tasksCountersQuery;
  const queryData = data || previousData;
  const taskCounterData = countersData || previousCountersData;
  const tasksFirstLoading = tasksLoading && previousData === undefined;
  const metadata = _get(queryData, 'data.metadata');
  const totalTasksCount = _get(metadata, 'totalCount', 0);
  const totalPages = _get(metadata, 'totalPages', 1);
  return {
    refetchTasks: () => {
      refetchTasks();
      refetchTasksCounters();
    },
    taskCounterData: getTaskCounterData(taskCounterData),
    tasks: _get(queryData, 'data.collection', []) as Task[],
    tasksError,
    tasksFirstLoading,
    tasksLoading,
    totalPages,
    totalTasksCount,
  };
}
