import { TaskScopeNameEnum } from 'graphql-common';

export const CacheNames = {
  apiCalls: 'apiCalls',
  apiDB: 'apiDB',
  assets: 'assets',
  images: 'images',
  pages: 'pages',
  tasks: 'tasks',
  taskCompletions: 'taskCompletions',
};

export const Destinations = {
  image: 'image',
  script: 'script',
  style: 'style',
  worker: 'worker',
};

export enum CustomTaskScopeNameEnum {
  Downloads = 'DOWNLOADS',
}

export type TaskScopeNameEnumExtended =
  | TaskScopeNameEnum
  | CustomTaskScopeNameEnum;
