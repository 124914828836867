import React from 'react';
import { useTranslation } from 'react-i18next';
import ErrorPage from '@lib/components/ErrorPage/ErrorPage';
import getStatusFromError from '@lib/utils/getStatusFromError';
import FileViewerModal from '@lib/components/FileViewerModal/FileViewerModal';
import InterventionAssigneesModal from 'components/InterventionAssigneesModal/InterventionAssigneesModal';
import ConfirmationModal from '@lib/components/ConfirmationModal/ConfirmationModal';
import Trans from '@lib/components/Trans/Trans';
import useOutletContext, { ContextProps } from 'hooks/useOutletContext';
import { ComponentEnum, LevelEnum } from 'graphql-common';
import { IconName } from '@lib/components/Modal/enums';
import InterventionActionFormPage from './InterventionActionFormPage/InterventionActionFormPage';
import InterventionActionViewPage from './InterventionActionViewPage/InterventionActionViewPage';
import useModalManagement from './utils/useModalManagement';
import useInterventionActions from './utils/useInterventionActions';
import useInterventionActionActions from './utils/useInterventionActionActions';
import { InterventionViewMode } from './enums';

type Props = {
  viewMode: InterventionViewMode;
};

export default function InterventionActionContainer(props: Props) {
  const { viewMode } = props;
  const { t } = useTranslation();

  const { getUserAccessByComponent }: ContextProps = useOutletContext();
  const isViewOnly =
    getUserAccessByComponent(ComponentEnum.InterventionsManagement) ===
    LevelEnum.Read;

  const useModalManagementResult = useModalManagement();
  const useInterventionActionsResult = useInterventionActions({
    viewMode,
    useModalManagementResult,
  });
  const useInterventionActionActionsResult = useInterventionActionActions({
    viewMode,
  });

  const { onConfirmedInterventionActionDestroy } = useInterventionActionsResult;
  const {
    closeAllModals,
    isInterventionActionAssigneesModalOpened,
    isInterventionAssigneesModalOpened,
    isInterventionActionDestroyModalOpened,
  } = useModalManagementResult;
  const { interventionQueryHookResult, interventionActionQueryHookResult } =
    useInterventionActionActionsResult;

  if (interventionQueryHookResult.error) {
    return (
      <ErrorPage
        status={getStatusFromError(interventionQueryHookResult.error)}
        description={interventionQueryHookResult.error?.message}
      />
    );
  }

  if (interventionActionQueryHookResult.error) {
    return (
      <ErrorPage
        status={getStatusFromError(interventionActionQueryHookResult.error)}
        description={interventionActionQueryHookResult.error?.message}
      />
    );
  }

  return (
    <>
      {(viewMode === InterventionViewMode.AddAction ||
        viewMode === InterventionViewMode.EditAction) && (
        <InterventionActionFormPage
          viewMode={viewMode}
          useInterventionActionActionsResult={
            useInterventionActionActionsResult
          }
        />
      )}
      {viewMode === InterventionViewMode.ViewAction && (
        <InterventionActionViewPage
          useInterventionActionActionsResult={
            useInterventionActionActionsResult
          }
          useInterventionActionsResult={useInterventionActionsResult}
          useModalManagementResult={useModalManagementResult}
          isViewOnly={isViewOnly}
        />
      )}
      <FileViewerModal
        attachment={useModalManagementResult.isAttachmentPreviewModalOpened}
        attachmentName={
          useModalManagementResult.isAttachmentPreviewModalOpened?.fileData
            ?.data?.metadata?.filename
        }
        onClose={useModalManagementResult.closeAllModals}
        isOpen={!!useModalManagementResult.isAttachmentPreviewModalOpened?.id}
      />
      <ConfirmationModal
        confirmButtonLabel={t('yes-delete')}
        description={<Trans i18nKey="intervention-action-delete-text" />}
        icon={IconName.Delete}
        isOpen={!!isInterventionActionDestroyModalOpened?.id}
        onClose={closeAllModals}
        onConfirm={onConfirmedInterventionActionDestroy}
        title={t('are-you-sure')}
      />
      <InterventionAssigneesModal
        caption={t('all-assignees')}
        interventionId={
          isInterventionActionAssigneesModalOpened?.id
            ? undefined
            : useInterventionActionsResult.id
        }
        interventionActionId={isInterventionActionAssigneesModalOpened?.id}
        isOpen={
          isInterventionAssigneesModalOpened ||
          !!isInterventionActionAssigneesModalOpened?.id
        }
        onClose={closeAllModals}
      />
    </>
  );
}
