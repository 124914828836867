import { useEffect, useState } from 'react';
import isSamsungS9 from '@lib/utils/isSamsungS9';

enum PermissionStateEnum {
  granted = 'granted',
  prompt = 'prompt',
  denied = 'denied',
  unknown = 'unknown',
}

function useCameraPermission({
  selectedCameraId,
  initialized,
}: {
  selectedCameraId: string;
  initialized: boolean;
}): PermissionStateEnum {
  const [cameraPermission, setCameraPermission] = useState<PermissionStateEnum>(
    PermissionStateEnum.unknown,
  );

  useEffect(() => {
    const checkCameraPermission = async () => {
      const isS9 = await isSamsungS9();
      if (isS9) {
        setCameraPermission(PermissionStateEnum.granted);
      } else {
        try {
          const nav = navigator as Navigator & {
            mediaDevices: {
              getUserMedia: (
                constraints: MediaStreamConstraints,
              ) => Promise<MediaStream>;
            };
          };
          const stream = await nav.mediaDevices.getUserMedia(
            selectedCameraId
              ? { video: { deviceId: selectedCameraId } }
              : { video: true },
          );
          setCameraPermission(PermissionStateEnum.granted);
          stream.getTracks().forEach((track) => track.stop());
        } catch (error: unknown) {
          if (error instanceof DOMException) {
            switch (error.name) {
              case 'NotAllowedError':
                setCameraPermission(PermissionStateEnum.denied);
                break;
              case 'NotFoundError':
                setCameraPermission(PermissionStateEnum.prompt);
                break;
              default:
                setCameraPermission(PermissionStateEnum.unknown);
                break;
            }
          } else {
            setCameraPermission(PermissionStateEnum.unknown);
          }
        }
      }
    };

    if (!initialized) checkCameraPermission();
  }, [initialized, selectedCameraId]);

  return cameraPermission;
}

export default useCameraPermission;
