import { useState, useEffect, useCallback } from 'react';
import { indexedDB } from 'utils/indexedDBUtils';
import { Task } from 'graphql-common';
import { CacheNames } from 'constants/enums';
import { captureException } from 'utils/captureException';

const useTaskIndexedDB = (id?: string) => {
  const [data, setData] = useState<Task | null>(null);
  const [loading, setLoading] = useState(true);

  const fetchData = useCallback(async () => {
    if (id) {
      setLoading(true);
      try {
        const store = indexedDB.table(CacheNames.tasks);
        const item = await store.get(id);
        setData(item || null);
      } catch (error) {
        captureException(error);
      } finally {
        setLoading(false);
      }
    }
  }, [id]);

  useEffect(() => {
    if (id) fetchData();
  }, [fetchData, id]);

  return { data, loading, refetch: fetchData };
};

export default useTaskIndexedDB;
