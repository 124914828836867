import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ApolloError } from '@apollo/client/errors';
import { LazyQueryExecFunction, QueryResult } from '@apollo/client';
import {
  ComponentEnum,
  FilterGroupingEnum,
  InterventionActionCreateInputObject,
  InterventionActionStatusEnum,
  InterventionActionUpdateInputObject,
  InterventionSiteIdQuery,
  InterventionSiteIdQueryVariables,
  LevelEnum,
  useInterventionSiteIdQuery,
  UsersSearchListQuery,
  useUsersSearchListLazyQuery,
} from 'graphql-common';
import { Values } from '@lib/interfaces/form';
import toast from '@lib/components/Toast/Toast';
import getAppUrlWithDomain from '@lib/utils/getAppUrlWithDomain';
import useInterventionActionQueryHook, {
  UseAssetInterventionActionQueryHookResult,
} from 'utils/fetch/useInterventionActionQueryHook';
import { APP_URLS } from 'constants/urls';
import FieldNames from '@lib/enums/fieldNames/interventionFieldNames';
import useUsersQueryHook, {
  UseUsersQueryHookResult,
} from 'utils/fetch/useUsersQueryHook';
import { PATH_ACTION_ID, PATH_ID } from '@lib/enums/urls';
import useMutations from './useMutations';
import getInterventionActionPreparedFromValues from './getInterventionActionPreparedFromValues';
import { InterventionViewMode } from '../enums';

type Args = {
  viewMode: InterventionViewMode;
};

export type UseInterventionActionActionsResult = {
  actionId: string;
  interventionActionQueryHookResult: UseAssetInterventionActionQueryHookResult;
  interventionId: string;
  interventionQueryHookResult: QueryResult<
    InterventionSiteIdQuery,
    InterventionSiteIdQueryVariables
  >;
  loading: boolean;
  mutationError: ApolloError | undefined;
  onOpenInterventionActions: () => void;
  onStatusChangeHandler: (
    interventionActionId: string,
    newStatus: InterventionActionStatusEnum,
  ) => void;
  onSubmit: (values: Values) => void;
  usersQueryHookResult: UseUsersQueryHookResult;
  fetchUsersSearchListLazyQuery: LazyQueryExecFunction<
    UsersSearchListQuery,
    any
  >;
};

const assigneeRedirectUrl = getAppUrlWithDomain(
  APP_URLS.app.interventions.actionView.getDynamicPath({}),
)
  .replace(PATH_ID, ':interventionId')
  .replace(PATH_ACTION_ID, ':interventionActionId');
const mentionRedirectUrl = getAppUrlWithDomain(
  APP_URLS.app.interventions.actionView.getDynamicPath({}),
)
  .replace(PATH_ID, ':interventionId')
  .replace(PATH_ACTION_ID, ':interventionActionId');

export default function useInterventionActionActions({
  viewMode,
}: Args): UseInterventionActionActionsResult {
  const navigate = useNavigate();
  const { id: interventionId = '', actionId = '' } = useParams();

  const { t } = useTranslation();
  const useMutationsResult = useMutations();

  // Intervention
  const interventionQueryHookResult = useInterventionSiteIdQuery({
    variables: { id: interventionId },
    fetchPolicy: 'network-only',
  });

  // Intervention action
  const interventionActionQueryHookResult = useInterventionActionQueryHook({
    id: actionId,
    interventionId,
    skip: !(
      viewMode === InterventionViewMode.EditAction ||
      viewMode === InterventionViewMode.ViewAction
    ),
    fetchPolicy: 'cache-and-network',
  });

  // Users (action assignees) for form field
  const usersQueryHookResult = useUsersQueryHook({
    skip: true,
    skipGetAllIds: viewMode !== InterventionViewMode.EditAction,
    additionalFilters: [
      {
        interventionActionAssigneesActionId: {
          grouping: FilterGroupingEnum.Or,
          predicate: { eq: actionId },
        },
      },
    ],
    getWithAllIds: true,
  });
  const { allIds: assigneeIds } = usersQueryHookResult;

  // Users (action mentions) for editor field
  const siteId = interventionQueryHookResult?.data?.data?.siteId;
  const [fetchUsersSearchListLazyQuery] = useUsersSearchListLazyQuery({
    fetchPolicy: 'no-cache',
    variables: {
      page: 1,
      limit: 10,
      scope: {
        custom: {
          byCore: {
            siteId,
            onlyActive: true,
            access: {
              component: ComponentEnum.InterventionsManagement,
              level: LevelEnum.Read,
            },
          },
        },
      },
    },
  });

  const onInterventionActionCreate = (
    values: InterventionActionCreateInputObject,
  ) => {
    useMutationsResult.interventionActionCreateMutation({
      variables: {
        attributes: values,
        assigneeRedirectUrl,
        mentionRedirectUrl,
        interventionId,
      },
      onCompleted: () => {
        toast({ content: t('create-intervention-action-success') });
        navigate(
          APP_URLS.app.interventions.viewActions.getDynamicPath({
            pathParts: { id: interventionId },
          }),
        );
      },
    });
  };

  const onInterventionActionUpdate = (
    values: InterventionActionUpdateInputObject,
  ) => {
    useMutationsResult.interventionActionUpdateMutation({
      variables: {
        id: actionId,
        interventionId,
        attributes: values,
        assigneeRedirectUrl,
        mentionRedirectUrl,
      },
      onCompleted: () => {
        toast({ content: t('update-intervention-action-success') });
        navigate(
          APP_URLS.app.interventions.viewActions.getDynamicPath({
            pathParts: { id: interventionId },
          }),
        );
      },
    });
  };

  const onSubmit = (values: Values) => {
    if (viewMode === InterventionViewMode.AddAction) {
      onInterventionActionCreate(
        getInterventionActionPreparedFromValues(values),
      );
    } else if (viewMode === InterventionViewMode.EditAction) {
      onInterventionActionUpdate(
        getInterventionActionPreparedFromValues(values, {
          ...interventionActionQueryHookResult.data,
          [FieldNames.Assignee]: assigneeIds.map(({ id: assigneeId }) => ({
            value: assigneeId,
          })),
        } as Values),
      );
    }
  };

  const onStatusChangeHandler = (
    interventionActionId: string,
    newStatus: InterventionActionStatusEnum,
  ) => {
    if (interventionActionId && newStatus) {
      useMutationsResult.interventionActionUpdateMutation({
        variables: {
          id: interventionActionId,
          interventionId,
          attributes: { status: newStatus },
          assigneeRedirectUrl,
          mentionRedirectUrl,
        },
        onCompleted: () => {
          toast({ content: t('update-intervention-action-success') });
          navigate(
            APP_URLS.app.interventions.view.getDynamicPath({
              pathParts: { id: interventionId },
            }),
          );
        },
      });
    }
  };

  const onOpenInterventionActions = () => {
    navigate(
      APP_URLS.app.interventions.viewActions.getDynamicPath({
        pathParts: { id: interventionId },
      }),
    );
  };

  const loading =
    interventionQueryHookResult.loading ||
    interventionActionQueryHookResult.loading ||
    useMutationsResult.interventionActionCreateMutationResult.loading ||
    useMutationsResult.interventionActionUpdateMutationResult.loading;

  const mutationError =
    useMutationsResult.interventionActionCreateMutationResult.error ||
    useMutationsResult.interventionActionUpdateMutationResult.error;

  return {
    actionId,
    fetchUsersSearchListLazyQuery,
    interventionActionQueryHookResult,
    interventionId,
    interventionQueryHookResult,
    loading,
    mutationError,
    onOpenInterventionActions,
    onStatusChangeHandler,
    onSubmit,
    usersQueryHookResult,
  };
}
