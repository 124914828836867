import React, { memo } from 'react';
import QrScanner from 'qr-scanner';
import classNames from 'classnames';
import { ComponentEnum, LevelEnum } from 'graphql-common';
import PageBody from '@lib/layouts/DashboardLayout/PageBody/PageBody';
import AppContentMaxWidth from '@lib/layouts/AppLayout/AppContentMaxWidth/AppContentMaxWidth';
import CircleLoader from '@lib/components/CircleLoader/CircleLoader';
import { scannerDropDownsWrap } from '@lib/enums/selectors';
import AccessPromptState from './AccessPromptState';
import AccessDeniedState from './AccessDeniedState';
import QrcodeScannerToolbar from './QrcodeScannerToolbar';
import useCameraSetup from './utils/useCameraSetup';
import styles from './QrcodeScanner.module.scss';

type Props = {
  qrCodeSuccessCallback: (res: QrScanner.ScanResult) => void;
  loading?: boolean;
  getUserAccessByComponent: (component: ComponentEnum) => undefined | LevelEnum;
};

function QrcodeScanner(props: Props) {
  const { qrCodeSuccessCallback, loading, getUserAccessByComponent } = props;

  const useCameraSetupResult = useCameraSetup({ qrCodeSuccessCallback });
  const { cameraPermission, isReady, videoRef } = useCameraSetupResult;

  if (loading) {
    return (
      <PageBody className={styles.pageBody}>
        <AppContentMaxWidth className={styles.root}>
          <div className={classNames(styles.scannerWrap)}>
            <div className={styles.scannerLoader}>
              <CircleLoader size={48} width={4} />
            </div>
          </div>
        </AppContentMaxWidth>
      </PageBody>
    );
  }

  if (cameraPermission === 'denied') {
    return (
      <PageBody className={styles.pageBody}>
        <AppContentMaxWidth className={styles.root}>
          <AccessDeniedState />
        </AppContentMaxWidth>
      </PageBody>
    );
  }

  if (cameraPermission === 'prompt') {
    return (
      <PageBody className={styles.pageBody}>
        <AppContentMaxWidth className={styles.root}>
          <AccessPromptState />
        </AppContentMaxWidth>
      </PageBody>
    );
  }

  return (
    <div
      className={classNames(styles.scannerWrap, {
        [styles.scannerWrapVisible]: isReady,
      })}
    >
      {!isReady && (
        <div className={styles.scannerLoader}>
          <CircleLoader size={48} width={4} />
        </div>
      )}
      <div
        className={classNames(styles.scanner, {
          [styles.scannerVisible]: isReady,
        })}
      >
        <video ref={videoRef} muted autoPlay playsInline preload="none" />
        {isReady && (
          <QrcodeScannerToolbar
            useCameraSetupResult={useCameraSetupResult}
            getUserAccessByComponent={getUserAccessByComponent}
          />
        )}
      </div>
      <div id={scannerDropDownsWrap} className={styles.dropDownsWrap} />
    </div>
  );
}

export default memo(QrcodeScanner);
