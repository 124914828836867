import React from 'react';
import useIsOnline from '@lib/hooks/useIsOnline';

type Props = {
  children: React.ReactNode;
};

export function OfflineTracker(props: Props) {
  const { children } = props;
  const isOnline = useIsOnline();

  if (!isOnline) {
    return <div>{children}</div>;
  }

  return null;
}
