export type ParsedCodes = {
  customPrefix: string;
  sideCode: string;
  entityCode: string;
  counterCode: string;
};

export default function getParsedCodes(code: string) {
  const parts = code.split('-');
  return {
    customPrefix: parts[0],
    sideCode: parts[1],
    entityCode: parts[2],
    counterCode: parts[4],
  };
}
