enum InterventionsFilterFieldNames {
  Asset = 'asset',
  Assignees = 'assignees',
  CreatedBy = 'createdBy',
  Filter = 'filter',
  InterventionCategory = 'interventionCategory',
  Period = 'period',
  Priority = 'priority',
  Site = 'site',
  SiteArea = 'siteArea',
  SiteAreaIds = 'siteAreaIds',
}

export default InterventionsFilterFieldNames;
