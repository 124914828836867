import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import {
  TaskCompletionCreateInputObject,
  useAppFullTaskLazyQuery,
  useTaskCompletionCreateMutation,
  useTasksMyTodoCompletableByDateDataLazyQuery,
} from 'graphql-common';
import Trans from '@lib/components/Trans/Trans';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import Typography from '@lib/components/Typography/Typography';
import IconButton, {
  IconButtonTypes,
} from '@lib/components/IconButton/IconButton';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import useIsOnline from '@lib/hooks/useIsOnline';
import Modal from '@lib/components/Modal/Modal';
import useTaskCompletionsIndexedDB from 'hooks/useTaskCompletionsIndexedDB';
import usePrevious from '@lib/hooks/usePrevious';
import useWhoamiQueryHook from 'utils/fetch/useWhoamiQueryHook';
import { removeTableFromIndexedDB } from 'utils/indexedDBUtils';
import { CacheNames, CustomTaskScopeNameEnum } from 'constants/enums';
import { IconName } from '@lib/components/Modal/enums';
import { APP_URLS } from 'constants/urls';
import { syncTaskCompletions } from './utils/syncTaskCompletions';
import { shouldFetchSyncTasks, syncTasks } from './utils/syncTasks';
import { storeCompletableTodoTasks } from './utils/storeUtils';
import {
  CompletableTodoTask,
  StoredCompletion,
  SyncProcess,
  SyncStatus,
} from './types';
import styles from './TaskSyncTracker.module.scss';

const {
  VITE_SYNC_TRACKER_SECONDS: syncTrackerSeconds,
  VITE_SYNC_TRACKER_DAYS: syncTrackerDays,
} = import.meta.env;

interface Props {
  setTaskSyncLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function TaskSyncTracker({ setTaskSyncLoading }: Props) {
  const { t } = useTranslation();
  const isOnline = useIsOnline();
  const { pathname, search } = useLocation();
  const prevPathname = usePrevious(pathname);
  const prevSearch = usePrevious(search);
  const lastTasksFetchTimeRef = useRef<Date | null>(null);
  const lastTaskCompletionsFetchTimeRef = useRef<Date | null>(null);
  const processRef = useRef<SyncProcess | null>(null);

  const [isServiceWorkerReady, setIsServiceWorkerReady] = useState(true);
  const [isSyncErrorModalOpened, setIsSyncErrorModalOpened] = useState(false);
  const [syncStatus, setSyncStatus] = useState<SyncStatus>(SyncStatus.closed);

  const [expectedSyncItems, setExpectedSyncItems] = useState<
    CompletableTodoTask[] | StoredCompletion[]
  >([]);
  const [fetchedSyncItems, setFetchedSyncItems] = useState<
    CompletableTodoTask[] | StoredCompletion[]
  >([]);
  const [failedSyncItems, setFailedSyncItems] = useState<
    CompletableTodoTask[] | StoredCompletion[]
  >([]);

  // Completions
  const {
    data: completions,
    totalCount: completionTotalCount,
    loading: completionsLoading,
    refetch: completionsRefetch,
  } = useTaskCompletionsIndexedDB({ syncStatus, isOnline });

  // Queries
  const { refetch: refetchWhoami } = useWhoamiQueryHook({
    fetchPolicy: 'cache-first',
  });
  const [fetchTasksMyTodoCompletableByDateData] =
    useTasksMyTodoCompletableByDateDataLazyQuery({
      fetchPolicy: 'network-only',
    });
  const [fetchTask] = useAppFullTaskLazyQuery({ fetchPolicy: 'network-only' });

  // Actions
  const [taskCompletionCreateMutation] = useTaskCompletionCreateMutation();

  const onTaskCompletionCreateMutation = useCallback(
    (input: TaskCompletionCreateInputObject) =>
      taskCompletionCreateMutation({ variables: { input } }),
    [taskCompletionCreateMutation],
  );

  const onOpenErrorModal = () => setIsSyncErrorModalOpened(true);
  const onCloseErrorModal = () => setIsSyncErrorModalOpened(false);

  const resetComponentState = () => {
    processRef.current = null;
    setExpectedSyncItems([]);
    setFetchedSyncItems([]);
    setFailedSyncItems([]);
    setSyncStatus(SyncStatus.closed);
  };

  const onRestart = () => {
    if (processRef.current === SyncProcess.tasks) {
      lastTasksFetchTimeRef.current = null;
    } else if (processRef.current === SyncProcess.completions) {
      lastTaskCompletionsFetchTimeRef.current = null;
    }
    storeCompletableTodoTasks([]);
    resetComponentState();
    onCloseErrorModal();
  };

  const onCancel = async () => {
    if (processRef.current === SyncProcess.tasks) {
      lastTasksFetchTimeRef.current = null;
      await removeTableFromIndexedDB(CacheNames.tasks);
    } else if (processRef.current === SyncProcess.completions) {
      lastTaskCompletionsFetchTimeRef.current = null;
      await removeTableFromIndexedDB(CacheNames.taskCompletions);
      await completionsRefetch();
    }
    storeCompletableTodoTasks([]);
    resetComponentState();
    onCloseErrorModal();
  };

  const onProcessStart = useCallback((v: SyncProcess) => {
    processRef.current = v;
    if (v === SyncProcess.tasks) {
      lastTasksFetchTimeRef.current = new Date();
    } else if (v === SyncProcess.completions) {
      lastTaskCompletionsFetchTimeRef.current = new Date();
    }
  }, []);

  const onProcessEnd = useCallback(() => {
    processRef.current = null;
  }, []);

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      const serviceWorker = navigator.serviceWorker as ServiceWorkerContainer;
      serviceWorker.ready.then(() => {
        serviceWorker.addEventListener('message', (event) => {
          if (event.data && event.data.type === 'SERVICE_WORKER_READY') {
            setIsServiceWorkerReady(true);
            setTimeout(() => {
              refetchWhoami();
            });
          }
        });
      });
    }
  }, [refetchWhoami]);

  // syncTaskCompletions
  useEffect(() => {
    if (
      completionTotalCount &&
      !completionsLoading &&
      isOnline &&
      !processRef.current
    ) {
      processRef.current = SyncProcess.completions;
      syncTaskCompletions({
        onTaskCompletionCreateMutation,
        completions,
        setExpectedSyncItems,
        setFailedSyncItems,
        setFetchedSyncItems,
        setSyncStatus,
        onProcessStart,
        onProcessEnd,
      });
    }
  }, [
    completionTotalCount,
    completions,
    completionsLoading,
    isOnline,
    onProcessEnd,
    onProcessStart,
    onTaskCompletionCreateMutation,
    pathname,
    search,
    syncStatus,
  ]);

  // syncTasks
  useEffect(() => {
    const shouldFetch =
      shouldFetchSyncTasks({
        completionTotalCount,
        isOnline,
        isServiceWorkerReady,
        lastTasksFetchTimeRef,
        syncStatus,
        syncTrackerSeconds,
      }) ||
      APP_URLS.app.tasks.index.isTheSameUrlAs(pathname, {
        params: {
          tab: CustomTaskScopeNameEnum.Downloads,
        },
      });

    if (shouldFetch && !processRef.current) {
      syncTasks({
        fetchTask,
        fetchTasksMyTodoCompletableByDateData,
        setExpectedSyncItems,
        setFailedSyncItems,
        setFetchedSyncItems,
        setSyncStatus,
        syncTrackerDays,
        onProcessStart,
        onProcessEnd,
      });
    }
  }, [
    completionTotalCount,
    fetchTask,
    fetchTasksMyTodoCompletableByDateData,
    isOnline,
    isServiceWorkerReady,
    onProcessEnd,
    onProcessStart,
    pathname,
    search,
    syncStatus,
  ]);

  // Toggle loading for App layout
  useEffect(() => {
    setTaskSyncLoading(syncStatus === SyncStatus.idle);
  }, [syncStatus, setTaskSyncLoading]);

  useEffect(() => {
    const isUrlChanged = prevSearch !== search || pathname !== prevPathname;
    if (syncStatus === SyncStatus.success && isUrlChanged) {
      resetComponentState();
    } else if (isOnline && syncStatus === SyncStatus.closed && isUrlChanged) {
      completionsRefetch();
    }
  }, [
    isOnline,
    completionsRefetch,
    pathname,
    prevPathname,
    prevSearch,
    search,
    syncStatus,
  ]);

  if (!isServiceWorkerReady || expectedSyncItems.length === 0) {
    return null;
  }

  const syncErrorModalDescription = () => {
    if (processRef.current === SyncProcess.tasks) {
      return (
        <Trans
          i18nKey="tasks-sync-error"
          values={{
            count: failedSyncItems.length,
          }}
        />
      );
    }
    if (processRef.current === SyncProcess.completions) {
      return (
        <Trans
          i18nKey="task-completions-sync-error"
          values={{
            count: failedSyncItems.length,
            tasks: failedSyncItems.map(({ taskName }) => taskName).join(', '),
          }}
        />
      );
    }
    return undefined;
  };

  return (
    <>
      <div
        className={classNames(styles.taskSyncTracker, {
          [styles.taskSyncTrackerSuccess]: syncStatus === 'success',
          [styles.taskSyncTrackerError]: syncStatus === 'error',
        })}
      >
        <span
          className={styles.taskSyncTrackerLine}
          style={{
            width: `${Math.round((fetchedSyncItems.length * 100) / expectedSyncItems.length)}%`,
          }}
        />
        <div className={styles.taskSyncTrackerIcon}>
          {syncStatus === 'idle' && <MaterialIcon icon="sync" />}
          {syncStatus === 'success' && <MaterialIcon icon="offline_pin" />}
          {syncStatus === 'error' && <MaterialIcon icon="error" />}
        </div>
        <div className={styles.taskSyncTrackerContent}>
          <Typography variant="caption" className={styles.taskSyncTrackerTitle}>
            {fetchedSyncItems.length}/{expectedSyncItems.length}{' '}
            {processRef.current === SyncProcess.completions
              ? t('task-completions-are-synced')
              : t('tasks-are-synced')}
          </Typography>
          <Typography variant="label" className={styles.taskSyncTrackerText}>
            {syncStatus === 'idle' && t('syncing')}
            {syncStatus === 'success' && t('sync-complete')}
            {syncStatus === 'error' && t('sync-error')}
          </Typography>
        </div>
        <div className={styles.taskSyncTrackerActions}>
          {syncStatus === 'success' && (
            <IconButton icon="close" onClick={resetComponentState} />
          )}
          {syncStatus === 'error' && failedSyncItems.length > 0 && (
            <IconButton
              icon="help"
              symbolsOutlined
              rounded
              type={IconButtonTypes.SecondaryFilled}
              onClick={onOpenErrorModal}
            />
          )}
          {syncStatus === 'error' && (
            <Button buttonText={t('restart')} onClick={onRestart} />
          )}
        </div>
      </div>
      <Modal
        description={syncErrorModalDescription()}
        icon={IconName.Warning}
        isOpen={isSyncErrorModalOpened}
        onClose={onCloseErrorModal}
        title={t('sync-error-title')}
        actions={
          <div className={styles.taskSyncTrackerModalActions}>
            <Button buttonText={t('try-again')} fullWidth onClick={onRestart} />
            <Button
              buttonText={t('cancel')}
              buttonType={ButtonTypes.secondaryOutlined}
              fullWidth
              onClick={onCancel}
            />
          </div>
        }
      />
    </>
  );
}
