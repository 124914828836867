import React from 'react';
import TaskView from 'components/Task/TaskView';
import useActions from './utils/useActions';

function TaskViewContainer() {
  const {
    isFormAvailable,
    isFormDisabled,
    taskData,
    taskError,
    taskLoading = false,
    isSynced,
    isTaskTimeoutError,
  } = useActions();

  return (
    <TaskView
      isFormAvailable={isFormAvailable}
      isFormDisabled={isFormDisabled}
      isSynced={isSynced}
      taskData={taskData}
      taskError={taskError}
      taskLoading={taskLoading}
      isTaskTimeoutError={isTaskTimeoutError}
    />
  );
}

export default TaskViewContainer;
