import React from 'react';
import { useTranslation } from 'react-i18next';
import { FetchResult } from '@apollo/client';
import { GraphQLErrors } from '@apollo/client/errors';
import { AuthenticationPasswordsForgotMutation, User } from 'graphql-common';
import PageBody from '@lib/layouts/DashboardLayout/PageBody/PageBody';
import CircleLoader from '@lib/components/CircleLoader/CircleLoader';
import { Values } from '@lib/interfaces/form';
import Button, {
  ButtonSizes,
  ButtonTypes,
} from '@lib/components/Button/Button';
import AppContentMaxWidth from '@lib/layouts/AppLayout/AppContentMaxWidth/AppContentMaxWidth';
import useIsOnline from '@lib/hooks/useIsOnline';
import TopNavigationHeader from '@lib/layouts/AppLayout/TopNavigationHeader/TopNavigationHeader';
import getDefaultValuesFromQueryData from './utils/getDefaultValuesFromQueryData';
import ProfileForm from './ProfileForm';
import LangForm from './LangForm';
import PasswordForm from './PasswordForm';
import { OnUpdatePassword, OnUpdateProfile } from './types';
import styles from './Profile.module.scss';

export interface Props {
  currentUser?: User;
  graphQLErrors?: GraphQLErrors;
  loading: boolean;
  onLogout: () => void;
  onSendPasswordResetHandler: () => Promise<
    FetchResult<AuthenticationPasswordsForgotMutation>
  >;
  onUpdateHandler: OnUpdateProfile;
  onUpdatePasswordHandler: OnUpdatePassword;
  passwordResetGraphQLErrors?: GraphQLErrors;
  passwordResetMutationLoading: boolean;
  passwordResetMutationReset: () => void;
}

function Profile(props: Props) {
  const { t } = useTranslation();
  const isOnline = useIsOnline();
  const {
    currentUser,
    graphQLErrors,
    loading,
    onLogout,
    onSendPasswordResetHandler,
    onUpdateHandler,
    onUpdatePasswordHandler,
    passwordResetGraphQLErrors,
    passwordResetMutationLoading,
    passwordResetMutationReset,
  } = props;

  if (loading && !currentUser) {
    return <CircleLoader />;
  }

  return (
    <>
      <TopNavigationHeader title={t('profile')} />
      <PageBody>
        <AppContentMaxWidth>
          <div className={styles.body}>
            <ProfileForm
              defaultValues={getDefaultValuesFromQueryData(
                currentUser as unknown as Values,
              )}
              graphQLErrors={graphQLErrors}
              loading={loading}
              onSubmit={onUpdateHandler}
            />
            <LangForm
              defaultValues={currentUser as unknown as Values}
              loading={loading}
              onSubmit={onUpdateHandler}
            />
            <PasswordForm
              graphQLErrors={passwordResetGraphQLErrors}
              loading={passwordResetMutationLoading}
              onSendPasswordResetHandler={onSendPasswordResetHandler}
              onSubmit={onUpdatePasswordHandler}
              updateMutationReset={passwordResetMutationReset}
            />
            <div>
              <Button
                buttonText={t('logout')}
                buttonSize={ButtonSizes.large}
                buttonType={ButtonTypes.negativeOutlined}
                leftIcon="logout"
                onClick={onLogout}
                disabled={!isOnline}
              />
            </div>
          </div>
        </AppContentMaxWidth>
      </PageBody>
    </>
  );
}

export default Profile;
