import React, { Suspense } from 'react';
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { ApolloProvider } from '@apollo/client';
import ApolloClient from 'graphql-common/ApolloClient';
import AppLayout from 'layouts/AppLayout/AppLayout';
import { APP_URLS } from 'constants/urls';
import { BreadCrumbsProvider } from '@lib/components/BreadCrumbs/BreadCrumbsContext';
import CircleLoader from '@lib/components/CircleLoader/CircleLoader';
import AntdConfigProvider from '@lib/components/Antd/ConfigProvider';
import { ErrorPageStatusEnum } from '@lib/components/ErrorPage/enums';
import ErrorPage from './ErrorPage/ErrorPage';
import AppProfile from './Profile/ProfileContainer';
import Interventions from './Interventions/InterventionsContainer';
import Intervention from './Intervention/InterventionContainer';
import { InterventionViewMode } from './Intervention/enums';
import InterventionAction from './Intervention/InterventionActionContainer';
import Tasks from './Tasks/TasksContainer';
import TaskView from './Task/TaskViewContainer';
import TaskCompletionCreate from './Task/TaskCompletionCreateContainer';
import TaskCompletionView from './Task/TaskCompletionViewContainer';
import ProtectedRoute from './ProtectedRoute';

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    path: '/',
    element: <ProtectedRoute />,
    children: [
      {
        path: '/',
        element: <AppLayout />,
        children: [
          {
            path: APP_URLS.app.profile.path,
            element: <AppProfile />,
          },
          {
            path: APP_URLS.app.tasks.index.path,
            element: <Tasks />,
          },
          {
            path: APP_URLS.app.tasks.view.getPathWithQuery(),
            element: <TaskView />,
          },
          {
            path: APP_URLS.app.tasks.completionCreate.getPathWithQuery(),
            element: <TaskCompletionCreate />,
          },
          {
            path: APP_URLS.app.tasks.completionView.getPathWithQuery(),
            element: <TaskCompletionView />,
          },
          {
            path: APP_URLS.app.interventions.index.path,
            element: <Interventions />,
          },
          {
            path: APP_URLS.app.interventions.view.path,
            element: <Intervention viewMode={InterventionViewMode.View} />,
          },
          {
            path: APP_URLS.app.interventions.add.path,
            element: <Intervention viewMode={InterventionViewMode.Add} />,
          },
          {
            path: APP_URLS.app.interventions.edit.path,
            element: <Intervention viewMode={InterventionViewMode.Edit} />,
          },
          {
            path: APP_URLS.app.interventions.viewActions.path,
            element: (
              <Intervention viewMode={InterventionViewMode.ViewActions} />
            ),
          },
          {
            path: APP_URLS.app.interventions.actionAdd.path,
            element: (
              <InterventionAction viewMode={InterventionViewMode.AddAction} />
            ),
          },
          {
            path: APP_URLS.app.interventions.actionEdit.path,
            element: (
              <InterventionAction viewMode={InterventionViewMode.EditAction} />
            ),
          },
          {
            path: APP_URLS.app.interventions.actionView.path,
            element: (
              <InterventionAction viewMode={InterventionViewMode.ViewAction} />
            ),
          },
          {
            path: APP_URLS.app.unauthorized.path,
            element: <ErrorPage status={ErrorPageStatusEnum.Status401} />,
          },
          {
            path: APP_URLS.app.accessDenied.path,
            element: <ErrorPage status={ErrorPageStatusEnum.AccessDenied} />,
          },
          {
            path: APP_URLS.app.index.path,
            index: true,
            element: <Navigate to={APP_URLS.app.profile.path} replace />,
          },
          {
            path: '*',
            element: <ErrorPage />,
          },
        ],
      },
    ],
  },
]);

function App(): React.ReactElement {
  return (
    <ApolloProvider client={ApolloClient}>
      <AntdConfigProvider>
        <BreadCrumbsProvider>
          <Suspense fallback={<CircleLoader />}>
            <RouterProvider router={router} />
          </Suspense>
        </BreadCrumbsProvider>
      </AntdConfigProvider>
    </ApolloProvider>
  );
}

export default App;
