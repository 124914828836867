import React from 'react';
import { useTranslation } from 'react-i18next';
import ActionsRow from '@lib/components/ActionsRow/ActionsRow';
import Button, { ButtonTypes } from '@lib/components/Button/Button';

interface Props {
  className?: string;
  loading?: boolean;
  onCancel: () => void;
}

export default function TasksFilterActions(props: Props) {
  const { className, onCancel, loading } = props;
  const { t } = useTranslation();
  return (
    <ActionsRow
      className={className}
      actions={[
        <Button
          buttonType={ButtonTypes.secondaryOutlined}
          buttonText={t('clear-filter')}
          leftIcon="close"
          onClick={onCancel}
          fullWidth
          disabled={loading}
        />,
        <Button
          type="submit"
          buttonType={ButtonTypes.primaryFilled}
          buttonText={t('apply')}
          leftIcon="check"
          fullWidth
          isLoading={loading}
        />,
      ]}
    />
  );
}
