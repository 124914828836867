import React from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { Intervention, InterventionScopeNameEnum, User } from 'graphql-common';
import _upperFirst from 'lodash/upperFirst';
import _toLower from 'lodash/toLower';
import Chip, { ChipTypes } from '@lib/components/Chip/Chip';
import Typography from '@lib/components/Typography/Typography';
import { getPriorityChipType } from '@lib/utils/priority';
import { getFormattedDate } from '@lib/utils/dateTimeHelpers';
import { dayDateFormat } from '@lib/enums/dateTime';
import { useTranslation } from 'react-i18next';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import { APP_URLS } from 'constants/urls';
import UserChip from '@lib/components/UserChip/UserChip';
import UsersAvatarsPreview from '@lib/components/UsersAvatarsPreview/UsersAvatarsPreview';
import styles from './InterventionPreview.module.scss';

interface Props {
  data: Intervention;
  usersData?: User[];
  usersTotalCount?: number;
  scope?: InterventionScopeNameEnum;
  openInterventionAssigneesModal: (item: Intervention) => void;
}

export default function InterventionPreview(props: Props) {
  const {
    data,
    usersData = [],
    usersTotalCount,
    scope,
    openInterventionAssigneesModal,
  } = props;
  const {
    id,
    priority,
    name,
    createdAt,
    closedAt,
    site,
    siteArea,
    asset,
    createdBy,
  } = data;
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  const currentLocale = i18n.language;

  const openInterventionPage = () =>
    navigate(
      APP_URLS.app.interventions.view.getDynamicPath({
        pathParts: { id },
      }),
    );

  const isClosed = scope === InterventionScopeNameEnum.Closed;

  const onOpenInterventionAssigneesModal = (
    e: React.MouseEvent<HTMLElement>,
  ) => {
    e.preventDefault();
    e.stopPropagation();
    openInterventionAssigneesModal(data);
  };

  return (
    <div
      role="button"
      tabIndex={0}
      onClick={openInterventionPage}
      onKeyDown={openInterventionPage}
      className={classNames(styles.root)}
    >
      <div className={styles.header}>
        <div>
          <Chip
            type={getPriorityChipType(priority)}
            label={_upperFirst(_toLower(priority))}
            leftIcon="circle"
          />
        </div>
        <div className={styles.date}>
          <div>
            <MaterialIcon
              icon={isClosed ? 'check_circle' : 'calendar_today'}
              size="md-16"
            />
          </div>
          <Typography variant="caption">
            {getFormattedDate(
              (isClosed ? closedAt : createdAt) as string,
              dayDateFormat,
              currentLocale,
            )}
          </Typography>
        </div>
      </div>
      <Typography variant="h3">{name}</Typography>
      <div className={styles.info}>
        <div className={styles.chipsRow}>
          {site?.id ? (
            <div>
              <Chip type={ChipTypes.chipGray} label={site.name} key={site.id} />
            </div>
          ) : null}
          {siteArea?.id ? (
            <div>
              <Chip
                type={ChipTypes.chipGray}
                label={siteArea.name}
                key={siteArea.id}
              />
            </div>
          ) : null}
          {asset?.id ? (
            <div>
              <Chip
                type={ChipTypes.chipGray}
                label={asset.name}
                key={asset.id}
              />
            </div>
          ) : null}
        </div>
      </div>
      <div className={styles.footer}>
        {createdBy ? <UserChip user={createdBy} /> : <div />}
        {!!usersTotalCount && (
          <UsersAvatarsPreview
            users={usersData}
            usersTotalCount={usersTotalCount}
            usersVisibleCount={4}
            onClick={onOpenInterventionAssigneesModal}
          />
        )}
      </div>
    </div>
  );
}
