import React from 'react';
import {
  TaskCompletionCreateInputObject,
  TaskCompletionCreateMutation,
} from 'graphql-common';
import { FetchResult } from '@apollo/client';
import {
  CompletableTodoTask,
  StoredCompletion,
  SyncProcess,
  SyncStatus,
} from 'components/TaskSyncTracker/types';
import { removeByIdFromIndexedDB } from 'utils/indexedDBUtils';
import { CacheNames } from 'constants/enums';
import _get from 'lodash/get';
import { captureException } from 'utils/captureException';

type SyncTaskCompletionsArgs = {
  onTaskCompletionCreateMutation: (
    v: TaskCompletionCreateInputObject,
  ) => Promise<FetchResult<TaskCompletionCreateMutation>>;
  completions: StoredCompletion[];
  setExpectedSyncItems: React.Dispatch<
    React.SetStateAction<CompletableTodoTask[] | StoredCompletion[]>
  >;
  setFailedSyncItems: React.Dispatch<
    React.SetStateAction<CompletableTodoTask[] | StoredCompletion[]>
  >;
  setFetchedSyncItems: React.Dispatch<
    React.SetStateAction<CompletableTodoTask[] | StoredCompletion[]>
  >;
  setSyncStatus: React.Dispatch<React.SetStateAction<SyncStatus>>;
  onProcessStart: (v: SyncProcess) => void;
  onProcessEnd: () => void;
};

export const syncTaskCompletions = async ({
  completions,
  onTaskCompletionCreateMutation,
  setExpectedSyncItems,
  setFailedSyncItems,
  setFetchedSyncItems,
  setSyncStatus,
  onProcessStart,
  onProcessEnd,
}: SyncTaskCompletionsArgs) => {
  try {
    const newFetchedTasks: StoredCompletion[] = [];
    const newFailedTasks: StoredCompletion[] = [];
    if (completions.length) {
      setSyncStatus(SyncStatus.idle);
      onProcessStart(SyncProcess.completions);
    } else {
      onProcessEnd();
    }
    setExpectedSyncItems(completions);

    // eslint-disable-next-line no-restricted-syntax
    for (const completion of completions) {
      const { id, taskId, answers } = completion;
      try {
        // eslint-disable-next-line no-await-in-loop
        const res = await onTaskCompletionCreateMutation({ taskId, answers });
        const taskCompletionCreate = _get(res, [
          'data',
          'taskCompletionCreate',
        ]);

        if (taskCompletionCreate) {
          // eslint-disable-next-line no-await-in-loop
          await removeByIdFromIndexedDB(CacheNames.taskCompletions, id);
          newFetchedTasks.push(completion);
          setFetchedSyncItems([...newFetchedTasks]);
        }
      } catch {
        newFailedTasks.push(completion);
        setFailedSyncItems([...newFailedTasks]);
      }
    }

    // All tasks processed, set status
    const hasErrors = newFailedTasks.length > 0;
    setSyncStatus(hasErrors ? SyncStatus.error : SyncStatus.success);
  } catch (error) {
    if (typeof error === 'string') {
      captureException(new Error(error));
    } else if (error instanceof Error) {
      captureException(error);
    }
    setSyncStatus(SyncStatus.error);
    throw error;
  }
};
