import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Asset, Site, SiteArea } from 'graphql-common';
import AppContentMaxWidth from '@lib/layouts/AppLayout/AppContentMaxWidth/AppContentMaxWidth';
import Form, { FormRef } from '@lib/components/ReactHookForm/FormContainer';
import PageBody from '@lib/layouts/DashboardLayout/PageBody/PageBody';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import ActionsRow from '@lib/components/ActionsRow/ActionsRow';
import formStyles from '@lib/assets/styles/Form.module.scss';
import TopNavigationHeader from '@lib/layouts/AppLayout/TopNavigationHeader/TopNavigationHeader';
import { APP_URLS } from 'constants/urls';
import IconButton, {
  IconButtonTypes,
} from '@lib/components/IconButton/IconButton';
import useOutletContext, { ContextProps } from 'hooks/useOutletContext';
import { ScannerActionType } from 'routes/Scanner/enums';
import useSearchListQueries from '../utils/useSearchListQueries';
import { UseModalManagementResult } from '../utils/useModalManagement';
import getInterventionFormFields from '../utils/getInterventionFormFields';
import getInterventionDefaultValuesFromQueryData from '../utils/getInterventionDefaultValuesFromQueryData';
import { UseInterventionActionsResult } from '../utils/useInterventionActions';
import getInterventionDefaultValues from '../utils/getInterventionDefaultValues';
import { InterventionViewMode } from '../enums';

type Props = {
  useModalManagementResult: UseModalManagementResult;
  useInterventionActionsResult: UseInterventionActionsResult;
  viewMode: InterventionViewMode;
};

export default function InterventionFormPage({
  useModalManagementResult,
  useInterventionActionsResult,
  viewMode,
}: Props) {
  const { t } = useTranslation();
  const formRef = useRef<FormRef>();
  const navigate = useNavigate();
  const { openScanner }: ContextProps = useOutletContext();
  const useSearchListQueriesResult = useSearchListQueries();
  const {
    id,
    mutationError,
    interventionQueryHookResult,
    loading,
    onSubmit,
    usersQueryHookResult,
    interventionSitePrefillDataQueryHookResult,
    interventionSiteAreaPrefillDataQueryHookResult,
    interventionAssetPrefillDataQueryHookResult,
  } = useInterventionActionsResult;
  const { allIds: assigneeIds } = usersQueryHookResult;

  const isCreatePage = viewMode === InterventionViewMode.Add;

  const defaultValues = isCreatePage
    ? getInterventionDefaultValues({
        site: interventionSitePrefillDataQueryHookResult?.data?.site as Site,
        siteArea: interventionSiteAreaPrefillDataQueryHookResult?.data
          ?.siteArea as SiteArea,
        asset: interventionAssetPrefillDataQueryHookResult?.data
          ?.asset as Asset,
      })
    : getInterventionDefaultValuesFromQueryData(
        interventionQueryHookResult.data,
        assigneeIds,
      );

  const fields = getInterventionFormFields({
    interventionId: id,
    t,
    isEditForm: viewMode === InterventionViewMode.Edit,
    getAssetsLoadOptions: useSearchListQueriesResult.getAssetsLoadOptions,
    getInterventionCategoriesLoadOptions:
      useSearchListQueriesResult.getInterventionCategoriesLoadOptions,
    getSiteAreasLoadOptions: useSearchListQueriesResult.getSiteAreasLoadOptions,
    getSitesLoadOptions: useSearchListQueriesResult.getSitesLoadOptions,
    getSupervisorLoadOptions:
      useSearchListQueriesResult.getSupervisorLoadOptions,
    getAssigneeLoadOptions: useSearchListQueriesResult.getAssigneeLoadOptions,
    getTasksLoadOptions: useSearchListQueriesResult.getTasksLoadOptions,
    openCategoryFormModal: useModalManagementResult.openCategoryFormModal,
  });

  const onOpenScanner = () => openScanner(ScannerActionType.CreateIntervention);

  const actionsComponent = () => (
    <ActionsRow
      className={formStyles.formActions}
      actions={[
        <Button
          onClick={() => navigate(-1)}
          buttonType={ButtonTypes.secondaryOutlined}
          buttonText={t('cancel')}
          leftIcon="close"
          fullWidth
        />,
        <Button
          buttonType={ButtonTypes.primaryFilled}
          buttonText={t(isCreatePage ? 'create' : 'save-changes')}
          leftIcon="check"
          type="submit"
          fullWidth
        />,
      ]}
    />
  );

  return (
    <>
      <TopNavigationHeader
        title={t(isCreatePage ? 'create-intervention' : 'edit-intervention')}
        backUrl={
          isCreatePage
            ? APP_URLS.app.interventions.index.getPathWithQuery()
            : APP_URLS.app.interventions.view.getDynamicPath({
                pathParts: { id },
              })
        }
        rightAction={
          isCreatePage ? (
            <IconButton
              icon="qr_code"
              type={IconButtonTypes.PrimaryFilled}
              onClick={onOpenScanner}
            />
          ) : undefined
        }
      />
      <PageBody withoutHeader>
        <AppContentMaxWidth>
          <Form
            actionsComponent={actionsComponent}
            actionsComponentProps={{ isCreatePage }}
            defaultValues={defaultValues}
            fields={fields}
            formId="intervention"
            formRef={formRef}
            graphQLErrors={mutationError?.graphQLErrors}
            loading={loading}
            onSubmit={onSubmit}
          />
        </AppContentMaxWidth>
      </PageBody>
    </>
  );
}
