import { USER_COMPLETABLE_TODO_TASKS } from '@lib/enums/localStorageKeys';
import { CompletableTodoTask } from 'components/TaskSyncTracker/types';

export const storeUtils = (): CompletableTodoTask[] => {
  const data = localStorage.getItem(USER_COMPLETABLE_TODO_TASKS);
  return data ? JSON.parse(data) : [];
};

export const storeCompletableTodoTasks = (
  tasks: CompletableTodoTask[],
): void => {
  localStorage.setItem(USER_COMPLETABLE_TODO_TASKS, JSON.stringify(tasks));
};
