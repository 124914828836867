import QrCodePrefix from '@lib/enums/qrCodePrefix';

const qrCodePrefixes = Object.values(QrCodePrefix).join('');
const assetCounterPrefix = QrCodePrefix.AssetCounter;

const regex = new RegExp(
  `^(?:[A-Z0-9]{1,4}|[${qrCodePrefixes}])-[^-]{1,10}-[^-]{1,10}(?:-${assetCounterPrefix}-[A-Za-z0-9]{1,3})?$`,
);

export default function isCodeValid(code?: string | null): boolean {
  if (!code) return false;
  return regex.test(code);
}
