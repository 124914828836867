import React from 'react';
import { useTranslation } from 'react-i18next';
import { GraphQLErrors } from '@apollo/client/errors';
import _omit from 'lodash/omit';
import { Values } from '@lib/interfaces/form';
import Typography from '@lib/components/Typography/Typography';
import Form from '@lib/components/ReactHookForm/FormContainer';
import replaceNullsWithUndefined from '@lib/utils/replaceNullsWithUndefined';
import useIsOnline from '@lib/hooks/useIsOnline';
import getProfileFormFields from './utils/getProfileFormFields';
import { OnUpdateProfile } from './types';
import styles from './ProfileForm.module.scss';

interface Props {
  defaultValues: Values;
  graphQLErrors?: GraphQLErrors;
  loading: boolean;
  onSubmit: OnUpdateProfile;
}

const emptyDefaultValues = {
  avatarAttached: null,
  email: null,
  firstName: null,
  id: null,
  lastName: null,
  personalId: null,
  phoneNumber: null,
};

function ProfileForm(props: Props) {
  const {
    defaultValues = emptyDefaultValues,
    graphQLErrors,
    loading,
    onSubmit,
  } = props;
  const { t } = useTranslation();
  const isOnline = useIsOnline();

  const fields = getProfileFormFields(defaultValues, isOnline);

  const preparedDefaultValues = {
    ...replaceNullsWithUndefined(_omit(defaultValues, 'lang'), [
      'avatarAttached',
    ]),
    avatarAttached: defaultValues.avatarAttached,
  };

  return (
    <div>
      <Typography variant="h3" className={styles.title}>
        {t('personal-info')}
      </Typography>
      <Form
        defaultValues={preparedDefaultValues}
        fields={fields}
        graphQLErrors={graphQLErrors}
        loading={loading}
        onSubmit={onSubmit}
        submitOnChange
      />
    </div>
  );
}

export default ProfileForm;
