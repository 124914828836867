import React from 'react';
import _get from 'lodash/get';
import TaskCompletionForm from 'components/TaskCompletionForm/TaskCompletionForm';
import getValues from 'routes/Task/utils/getValues';
import useFields from './utils/useFields';
import useActions from './utils/useActions';

function TaskCompletionViewContainer() {
  const {
    id = '',
    taskDataForCompletion,
    taskFormTemplateVersion,
    taskCompletionError,
    taskCompletionLoading = false,
    formRef,
  } = useActions();
  const questions = _get(taskFormTemplateVersion, 'questions', []);
  const answers = _get(taskDataForCompletion, 'completion.answers', []);
  const { formFields } = useFields([...questions], [], true);

  return (
    <TaskCompletionForm
      id={id}
      formRef={formRef}
      defaultValues={getValues(answers)}
      error={taskCompletionError}
      taskCompletionError={taskCompletionError}
      fields={formFields}
      loading={taskCompletionLoading}
    />
  );
}

export default TaskCompletionViewContainer;
