import { TFunction } from 'i18next';
import _get from 'lodash/get';
import Select, { SelectComponents } from '@lib/components/Select/Select';
import DateRangePicker from '@lib/components/DateTimePicker/DateRangePicker';
import { GetLoadOptions } from '@lib/components/Select/types';
import { InputTypes } from '@lib/components/Input/enums';
import { FieldChangeCallBackArgs, Values } from '@lib/interfaces/form';
import { getInterventionPriorityOptions } from '@lib/utils/priority';
import AssetOption from '@lib/components/Select/components/AssetOption';
import AssetSingleValue from '@lib/components/Select/components/AssetSingleValue';
import InterventionsFilterFieldNames from '@lib/enums/fieldNames/interventionsFilterFieldNames';
import UserOption from '@lib/components/Select/components/UserOption';
import ChipCircleOption from '@lib/components/Select/components/ChipCircleOption';

type Args = {
  getAssetsLoadOptions: GetLoadOptions;
  getAssigneeLoadOptions: GetLoadOptions;
  getInterventionCategoriesLoadOptions: GetLoadOptions;
  getSitesLoadOptions: GetLoadOptions;
  getSupervisorLoadOptions: GetLoadOptions;
  t: TFunction<'translation', undefined>;
};

export default function useFilterFields(args: Args) {
  const {
    getAssetsLoadOptions,
    getAssigneeLoadOptions,
    getInterventionCategoriesLoadOptions,
    getSitesLoadOptions,
    getSupervisorLoadOptions,
    t,
  } = args;
  return [
    {
      name: InterventionsFilterFieldNames.Site,
      label: t('sites-label'),
      element: Select,
      componentProps: {
        isAsync: true,
        isMulti: true,
        getLoadOptions: getSitesLoadOptions,
        maxMenuHeight: 200,
        usePortal: true,
      },
      fieldChangeCallBack: ({
        type,
        setValue,
        name,
      }: FieldChangeCallBackArgs) => {
        if (type === 'change' && name === InterventionsFilterFieldNames.Site) {
          setValue(InterventionsFilterFieldNames.SiteArea, null, {
            shouldValidate: true,
          });
          setValue(InterventionsFilterFieldNames.Asset, null, {
            shouldValidate: true,
          });
        }
      },
    },
    {
      name: InterventionsFilterFieldNames.SiteArea,
      label: t('qr-code-scan-result-area'),
      element: Select,
      componentProps: {
        isMulti: true,
        isClearable: false,
        maxMenuHeight: 200,
        usePortal: true,
      },
      isVisibleFn: (values: Values, name?: string) => {
        if (name) {
          return (
            _get(values, [InterventionsFilterFieldNames.Site, 0, 'value']) &&
            _get(values, [InterventionsFilterFieldNames.SiteArea, 0, 'value'])
          );
        }
        return false;
      },
    },
    {
      name: InterventionsFilterFieldNames.Asset,
      label: t('asset'),
      element: Select,
      componentProps: {
        isAsync: true,
        isMulti: true,
        maxMenuHeight: 200,
        components: {
          Option: AssetOption,
          SingleValue: AssetSingleValue,
        } as unknown as SelectComponents,
        usePortal: true,
        getLoadOptions: getAssetsLoadOptions,
        disabledTooltipProps: {
          body: t('choose-site-first'),
        },
      },
    },
    {
      name: InterventionsFilterFieldNames.CreatedBy,
      label: t('created-by'),
      element: Select,
      isUseWatch: true,
      componentProps: {
        isAsync: true,
        isMulti: true,
        maxMenuHeight: 200,
        components: {
          Option: UserOption,
        } as unknown as SelectComponents,
        usePortal: true,
        getLoadOptions: getSupervisorLoadOptions,
        disabledTooltipProps: {
          body: t('choose-site-first'),
        },
      },
    },
    {
      name: InterventionsFilterFieldNames.Assignees,
      label: t('assignees-label'),
      element: Select,
      isUseWatch: true,
      componentProps: {
        isAsync: true,
        isMulti: true,
        maxMenuHeight: 200,
        components: {
          Option: UserOption,
        } as unknown as SelectComponents,
        usePortal: true,
        getLoadOptions: getAssigneeLoadOptions,
        disabledTooltipProps: {
          body: t('choose-site-first'),
        },
      },
    },
    {
      name: InterventionsFilterFieldNames.Period,
      label: t('period'),
      element: DateRangePicker,
      componentProps: {
        type: InputTypes.dateRange,
      },
    },
    {
      name: InterventionsFilterFieldNames.Priority,
      label: t('priority'),
      element: Select,
      componentProps: {
        isSearchable: false,
        isMulti: true,
        options: getInterventionPriorityOptions(),
        menuPlacement: 'bottom',
        components: {
          Option: ChipCircleOption,
        },
      },
    },
    {
      name: InterventionsFilterFieldNames.InterventionCategory,
      label: t('category'),
      element: Select,
      componentProps: {
        isAsync: true,
        isMulti: true,
        getLoadOptions: getInterventionCategoriesLoadOptions,
        maxMenuHeight: 200,
      },
    },
  ];
}
