import _get from 'lodash/get';
import { FilterGroupingEnum } from 'graphql-common';
import { GetUrlParams } from '@lib/enums/urls';
import { qsParse } from '@lib/utils/qsHelpers';

export const addFilter = (
  filters: any[],
  field: string,
  values: string[],
  grouping: FilterGroupingEnum = FilterGroupingEnum.And,
) => {
  if (values.length) {
    filters.push({
      [field]: {
        grouping,
        predicate: { in: values },
      },
    });
  }
};

export const parseFilterValues = (
  filterString: string,
  isSingleSelect = false,
): string[] => {
  const parsedFilterValues: string[] = [];
  const filterValue = qsParse(filterString);
  if (isSingleSelect) {
    const singleValue = _get(filterValue, 'value');
    if (singleValue) parsedFilterValues.push(singleValue as string);
  } else {
    Object.values(filterValue).forEach((item) => {
      const value = _get(item, 'value');
      if (value) parsedFilterValues.push(value);
    });
  }
  return parsedFilterValues;
};

export const getPaginationAndSorting = (
  paginationParams: any,
  orderingParams: any,
) => {
  const page = Number(_get(paginationParams, GetUrlParams.Page));
  const perPage = Number(_get(paginationParams, GetUrlParams.PerPage));
  const orderingField = _get(orderingParams, GetUrlParams.OrderingField);
  const orderingDirection = _get(
    orderingParams,
    GetUrlParams.OrderingDirection,
  );

  return { page, perPage, orderingField, orderingDirection };
};
