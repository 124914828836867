import React from 'react';
import { useTranslation } from 'react-i18next';
import { ApolloError } from '@apollo/client/errors';
import { FieldItem, Values } from '@lib/interfaces/form';
import PageBody from '@lib/layouts/DashboardLayout/PageBody/PageBody';
import AppContentMaxWidth from '@lib/layouts/AppLayout/AppContentMaxWidth/AppContentMaxWidth';
import Typography from '@lib/components/Typography/Typography';
import ErrorPage from '@lib/components/ErrorPage/ErrorPage';
import CircleLoader from '@lib/components/CircleLoader/CircleLoader';
import Form, { FormRef } from '@lib/components/ReactHookForm/FormContainer';
import getStatusFromError from '@lib/utils/getStatusFromError';
import TopNavigationHeader from '@lib/layouts/AppLayout/TopNavigationHeader/TopNavigationHeader';
import { APP_URLS } from 'constants/urls';
import styles from './TaskCompletionForm.module.scss';

interface Props {
  actionsComponent?: React.JSX.ElementType;
  defaultValues?: Values;
  error?: ApolloError;
  fields: FieldItem[];
  formRef: React.MutableRefObject<FormRef | undefined>;
  id: string;
  loading: boolean;
  onSubmit?: (values: Values) => void;
  taskCompletionError?: ApolloError;
  taskCompletionLoading?: boolean;
}

export default function TaskCompletionForm(props: Props) {
  const { t } = useTranslation();
  const {
    actionsComponent,
    defaultValues = {},
    error,
    fields,
    formRef,
    id,
    loading,
    onSubmit,
    taskCompletionError,
    taskCompletionLoading,
  } = props;

  const isView = actionsComponent === undefined || onSubmit === undefined;

  if (error) {
    return (
      <ErrorPage
        status={getStatusFromError(error)}
        description={error?.message}
      />
    );
  }

  return (
    <>
      <TopNavigationHeader
        title={isView ? t('view-form') : t('form-completing')}
        backUrl={APP_URLS.app.tasks.view.getDynamicPath({ pathParts: { id } })}
      />
      <PageBody withoutHeader>
        {loading ? (
          <CircleLoader />
        ) : (
          <AppContentMaxWidth>
            {!isView && (
              <div className={styles.formInfo}>
                <Typography variant="body">
                  {t('indicates-required-question')}
                </Typography>
              </div>
            )}
            <Form
              formRef={formRef}
              actionsComponent={actionsComponent}
              defaultValues={defaultValues}
              fields={fields}
              formId="completion"
              // formItemsClassName={styles.formItems}
              graphQLErrors={taskCompletionError?.graphQLErrors}
              loading={taskCompletionLoading}
              onSubmit={(values) =>
                onSubmit && onSubmit({ ...defaultValues, ...values })
              }
            />
          </AppContentMaxWidth>
        )}
      </PageBody>
    </>
  );
}
