import React from 'react';
import { useTranslation } from 'react-i18next';
import { Task, TaskScopeNameEnum } from 'graphql-common';
import _valuesIn from 'lodash/valuesIn';
import _without from 'lodash/without';
import CircleLoader from '@lib/components/CircleLoader/CircleLoader';
import Pagination from '@lib/components/Pagination/Pagination';
import useSearchParam from '@lib/hooks/useSearchParam';
import CircleLoaderWrapper, {
  CircleLoaderWrapperType,
} from '@lib/components/CircleLoaderWrapper/CircleLoaderWrapper';
import TaskPreview from 'components/TaskPreview/TaskPreview';
import SupportiveMessage from '@lib/components/SupportiveMessage/SupportiveMessage';
import IconButton, {
  IconButtonTypes,
} from '@lib/components/IconButton/IconButton';
import { TaskScopeNameEnumExtended } from 'constants/enums';
import { useLocalStorage } from 'usehooks-ts';
import { TASK_FILTER_FORM } from '@lib/enums/localStorageKeys';
import TasksEmptyState from './TasksEmptyState';
import TasksFilter from './TasksFilter';
import styles from './Tasks.module.scss';

type Props = {
  isFilterByQRCodeMessageVisible: boolean;
  isOnline: boolean;
  isTimeoutError?: boolean;
  onResetFilter: () => void;
  onTabParamChange: (v: string) => void;
  prevTabParam?: TaskScopeNameEnumExtended;
  setFilterByQRCodeMessageVisible: (v: boolean) => void;
  tabParam?: TaskScopeNameEnumExtended;
  tasks: Task[];
  tasksFirstLoading: boolean;
  tasksLoading: boolean;
  totalEntries: number;
};

export default function Tasks(props: Props) {
  const {
    isFilterByQRCodeMessageVisible,
    isOnline,
    isTimeoutError,
    onResetFilter,
    onTabParamChange,
    prevTabParam,
    setFilterByQRCodeMessageVisible,
    tabParam,
    tasks,
    tasksFirstLoading,
    tasksLoading,
    totalEntries,
  } = props;

  const { t } = useTranslation();
  const [filterParams] = useLocalStorage(TASK_FILTER_FORM, {});
  const [searchQuery, setSearchQuery] = useSearchParam();
  const isContentVisible = !!totalEntries && !!tasks.length;
  const isEmptyStateVisible = !isContentVisible && !tasksFirstLoading;
  const isPaginationVisible =
    !!totalEntries && !!tasks.length && totalEntries > tasks.length;
  const isSearchApplied = !!searchQuery;
  const isFilterApplied = !!_without(_valuesIn(filterParams), undefined).length;
  const isEmptyState =
    !tasksLoading &&
    isEmptyStateVisible &&
    !isSearchApplied &&
    !isFilterApplied;
  const isEmptyStateBySearch = isEmptyStateVisible && isSearchApplied;
  const isEmptyStateByFilter = isEmptyStateVisible && isFilterApplied;

  const emptyState = (
    <TasksEmptyState
      {...{
        isEmptyState,
        isEmptyStateByFilter,
        isEmptyStateBySearch,
        isOnline,
        isTimeoutError,
        onResetFilter,
        onTabParamChange,
        setSearchQuery,
        tabParam,
      }}
    />
  );

  const scope = (
    tasksLoading ? prevTabParam || tabParam : tabParam
  ) as TaskScopeNameEnum;

  const content = (
    <>
      <div className={styles.tasksGrid}>
        {tasks.map((item) => (
          <div key={item.id}>
            <TaskPreview data={item} scope={scope} />
          </div>
        ))}
      </div>
      {isPaginationVisible && (
        <div className={styles.pagination}>
          <Pagination totalEntries={totalEntries} />
        </div>
      )}
    </>
  );

  return (
    <div>
      {tasksFirstLoading ? (
        <CircleLoader />
      ) : (
        <CircleLoaderWrapper
          isLoading={tasksLoading}
          type={CircleLoaderWrapperType.blur}
        >
          {!isEmptyState && isOnline && (
            <TasksFilter onResetFilter={onResetFilter} />
          )}
          {isFilterByQRCodeMessageVisible && (
            <SupportiveMessage
              text={t('tasks-qr-code-filter-text')}
              className={styles.message}
              actions={
                <IconButton
                  icon="close"
                  type={IconButtonTypes.PrimaryOutlined}
                  onClick={() => setFilterByQRCodeMessageVisible(false)}
                />
              }
            />
          )}
          {isEmptyStateVisible || !isOnline || isTimeoutError
            ? emptyState
            : content}
        </CircleLoaderWrapper>
      )}
    </div>
  );
}
