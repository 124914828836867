import { useTranslation } from 'react-i18next';
import {
  useInterventionBulkCloseMutation,
  useInterventionDestroyMutation,
} from 'graphql-common';
import { showGraphQLErrors } from '@lib/utils/graphQLErrors';

export default function useMutations() {
  const { t } = useTranslation();

  const [interventionDestroyMutation, interventionDestroyMutationResult] =
    useInterventionDestroyMutation({
      onError: (error) => showGraphQLErrors(error, t),
    });

  const [interventionBulkCloseMutation] = useInterventionBulkCloseMutation({
    onError: (error) => showGraphQLErrors(error, t),
  });

  return {
    interventionBulkCloseMutation,
    interventionDestroyMutation,
    interventionDestroyMutationResult,
  };
}
