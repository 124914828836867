import React from 'react';
import { useTranslation } from 'react-i18next';
import AppContentMaxWidth from '@lib/layouts/AppLayout/AppContentMaxWidth/AppContentMaxWidth';
import TabBar from '@lib/components/TabBar/TabBar';
import PageBody from '@lib/layouts/DashboardLayout/PageBody/PageBody';
import { TabBarItem as TabBarItemType } from '@lib/components/TabBar/constants';
import CircleLoader from '@lib/components/CircleLoader/CircleLoader';
import TopNavigationHeader from '@lib/layouts/AppLayout/TopNavigationHeader/TopNavigationHeader';
import { APP_URLS } from 'constants/urls';
import { InterventionViewMode } from '../enums';
import getInterventionViewPageTabs from '../utils/getInterventionViewPageTabs';
import { UseInterventionActionsResult } from '../utils/useInterventionActions';
import { UseModalManagementResult } from '../utils/useModalManagement';
import InterventionViewDetails from './InterventionViewDetails';
import InterventionViewActions from './InterventionViewActions';

type Props = {
  useModalManagementResult: UseModalManagementResult;
  useInterventionActionsResult: UseInterventionActionsResult;
  viewMode: InterventionViewMode;
  isViewOnly: boolean;
};

export default function InterventionViewPage({
  viewMode,
  useModalManagementResult,
  useInterventionActionsResult,
  isViewOnly,
}: Props) {
  const { t } = useTranslation();
  const { interventionQueryHookResult, onViewChange } =
    useInterventionActionsResult;
  const { firstLoading, actionsCounter } = interventionQueryHookResult;

  const tabs = getInterventionViewPageTabs({ t, actionsCounter });

  return (
    <>
      <TopNavigationHeader
        title={t('intervention-details')}
        backUrl={APP_URLS.app.interventions.index.getPathWithQuery()}
      />
      <PageBody withoutHeader>
        <AppContentMaxWidth>
          {firstLoading ? (
            <CircleLoader />
          ) : (
            <>
              <TabBar
                items={tabs}
                active={viewMode}
                onActiveChange={({ value }: TabBarItemType) =>
                  onViewChange(value)
                }
              />
              {viewMode === InterventionViewMode.View && (
                <InterventionViewDetails
                  useModalManagementResult={useModalManagementResult}
                  useInterventionActionsResult={useInterventionActionsResult}
                  isViewOnly={isViewOnly}
                />
              )}
              {viewMode === InterventionViewMode.ViewActions && (
                <InterventionViewActions
                  useModalManagementResult={useModalManagementResult}
                  useInterventionActionsResult={useInterventionActionsResult}
                  isViewOnly={isViewOnly}
                />
              )}
            </>
          )}
        </AppContentMaxWidth>
      </PageBody>
    </>
  );
}
