import React from 'react';
import { useTranslation } from 'react-i18next';
import _upperFirst from 'lodash/upperFirst';
import _toLower from 'lodash/toLower';
import _get from 'lodash/get';
import classNames from 'classnames';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import Typography from '@lib/components/Typography/Typography';
import Chip, { ChipTypes } from '@lib/components/Chip/Chip';
import EntityViewStyles from '@lib/assets/styles/EntityView.module.scss';
import InterventionStatusChip from '@lib/components/InterventionStatusChip/InterventionStatusChip';
import { getPriorityChipType } from '@lib/utils/priority';
import AssetPreview from '@lib/components/AssetPreview/AssetPreview';
import { getFormattedDate, transformDate } from '@lib/utils/dateTimeHelpers';
import UserChip from '@lib/components/UserChip/UserChip';
import { getTimeFromMinutesCount } from '@lib/utils/getTimeFromMinutesCount';
import UsersAvatarsPreview from '@lib/components/UsersAvatarsPreview/UsersAvatarsPreview';
import { UseInterventionActionsResult } from '../utils/useInterventionActions';
import { UseModalManagementResult } from '../utils/useModalManagement';

type Props = {
  useModalManagementResult: UseModalManagementResult;
  useInterventionActionsResult: UseInterventionActionsResult;
  isViewOnly: boolean;
};

export default function InterventionInfo({
  useModalManagementResult,
  useInterventionActionsResult,
  isViewOnly,
}: Props) {
  const { t } = useTranslation();
  const {
    interventionQueryHookResult,
    onStatusChangeHandler,
    usersQueryHookResult,
  } = useInterventionActionsResult;
  const { data } = interventionQueryHookResult;
  const {
    code,
    category,
    status,
    priority,
    site,
    siteArea,
    asset,
    createdAt,
    estimatedDate,
    totalLogisticTime,
    totalEstimatedTime,
    totalTime,
    createdBy,
    supervisor,
  } = data || {};

  const { collection: usersData, totalCount: usersTotalCount } =
    usersQueryHookResult;

  return (
    <div>
      <div className={EntityViewStyles.infoItem}>
        <p className={EntityViewStyles.infoItemLabel}>
          <MaterialIcon icon="password" symbolsOutlined />
          <Typography variant="caption">{t('code')}</Typography>
        </p>
        <div className={EntityViewStyles.infoItemContent}>
          <Typography variant="body">{code}</Typography>
        </div>
      </div>
      <div className={EntityViewStyles.infoItem}>
        <p className={EntityViewStyles.infoItemLabel}>
          <MaterialIcon icon="category" symbolsOutlined />
          <Typography variant="caption">{t('category')}</Typography>
        </p>
        <div className={EntityViewStyles.infoItemContent}>
          {category ? (
            <Chip
              type={_get(category, 'color') as unknown as ChipTypes}
              label={_get(category, 'name')}
              leftIcon="circle"
            />
          ) : (
            <Typography variant="body">-</Typography>
          )}
        </div>
      </div>
      <div className={EntityViewStyles.infoItem}>
        <p className={EntityViewStyles.infoItemLabel}>
          <MaterialIcon icon="clock_loader_20" symbolsOutlined />
          <Typography variant="caption">{t('status')}</Typography>
        </p>
        <div className={EntityViewStyles.infoItemContent}>
          {status ? (
            <InterventionStatusChip
              intervention={data}
              onStatusChangeHandler={onStatusChangeHandler}
              isViewOnly={isViewOnly}
            />
          ) : (
            <Typography variant="body">-</Typography>
          )}
        </div>
      </div>
      <div className={EntityViewStyles.infoItem}>
        <p className={EntityViewStyles.infoItemLabel}>
          <MaterialIcon icon="segment" symbolsOutlined />
          <Typography variant="caption">{t('priority')}</Typography>
        </p>
        <div className={EntityViewStyles.infoItemContent}>
          {priority ? (
            <Chip
              type={getPriorityChipType(priority)}
              label={_upperFirst(_toLower(priority))}
              leftIcon="circle"
            />
          ) : (
            <Typography variant="body">-</Typography>
          )}
        </div>
      </div>
      <div className={EntityViewStyles.infoItem}>
        <p className={EntityViewStyles.infoItemLabel}>
          <MaterialIcon icon="home_pin" symbolsOutlined />
          <Typography variant="caption">{t('site-comma-area')}</Typography>
        </p>
        <div
          className={classNames(
            EntityViewStyles.infoItemContent,
            EntityViewStyles.infoItemContentRow,
          )}
        >
          {site ? (
            <Chip type={ChipTypes.chipGray} label={_get(site, 'name')} />
          ) : (
            <Typography variant="body">-</Typography>
          )}
          {siteArea ? (
            <Chip type={ChipTypes.chipGray} label={_get(siteArea, 'name')} />
          ) : (
            <Typography variant="body">-</Typography>
          )}
        </div>
      </div>
      <div className={EntityViewStyles.infoItem}>
        <p className={EntityViewStyles.infoItemLabel}>
          <MaterialIcon icon="inventory" symbolsOutlined />
          <Typography variant="caption">{t('asset')}</Typography>
        </p>
        <div className={EntityViewStyles.infoItemContent}>
          {asset ? (
            <AssetPreview
              name={asset.name}
              code={asset?.fullCode}
              icon={
                asset.iconAttached?.urls?.small ||
                asset.iconAttached?.urls?.original ||
                asset.iconAttached?.fileTag
              }
            />
          ) : (
            <Typography variant="body">-</Typography>
          )}
        </div>
      </div>
      <div className={EntityViewStyles.infoItem}>
        <p className={EntityViewStyles.infoItemLabel}>
          <MaterialIcon icon="calendar_today" symbolsOutlined />
          <Typography variant="caption">{t('date-created')}</Typography>
        </p>
        <div className={EntityViewStyles.infoItemContent}>
          <Typography variant="body">{getFormattedDate(createdAt)}</Typography>
        </div>
      </div>
      <div className={EntityViewStyles.infoItem}>
        <p className={EntityViewStyles.infoItemLabel}>
          <MaterialIcon icon="flag" symbolsOutlined />
          <Typography variant="caption">{t('estimated-date')}</Typography>
        </p>
        <div className={EntityViewStyles.infoItemContent}>
          <Typography variant="body">
            {estimatedDate
              ? getFormattedDate(
                  transformDate(new Date(estimatedDate), false, true),
                )
              : '-'}
          </Typography>
        </div>
      </div>
      <div className={EntityViewStyles.infoItem}>
        <p className={EntityViewStyles.infoItemLabel}>
          <MaterialIcon icon="history_toggle_off" symbolsOutlined />
          <Typography variant="caption">{t('estimated-time')}</Typography>
        </p>
        <div className={EntityViewStyles.infoItemContent}>
          <Typography variant="body">
            {totalEstimatedTime
              ? getTimeFromMinutesCount(totalEstimatedTime)
              : '-'}
          </Typography>
        </div>
      </div>
      <div className={EntityViewStyles.infoItem}>
        <p className={EntityViewStyles.infoItemLabel}>
          <MaterialIcon icon="local_shipping" symbolsOutlined />
          <Typography variant="caption">{t('logistic-time')}</Typography>
        </p>
        <div className={EntityViewStyles.infoItemContent}>
          <Typography variant="body">
            {totalLogisticTime
              ? getTimeFromMinutesCount(totalLogisticTime)
              : '-'}
          </Typography>
        </div>
      </div>
      <div className={EntityViewStyles.infoItem}>
        <p className={EntityViewStyles.infoItemLabel}>
          <MaterialIcon icon="schedule" symbolsOutlined />
          <Typography variant="caption">{t('total-time')}</Typography>
        </p>
        <div className={EntityViewStyles.infoItemContent}>
          <Typography variant="body">
            {totalTime ? getTimeFromMinutesCount(totalTime) : '-'}
          </Typography>
        </div>
      </div>
      <div
        className={classNames(
          EntityViewStyles.infoItem,
          EntityViewStyles.infoItemCentered,
        )}
      >
        <p className={EntityViewStyles.infoItemLabel}>
          <MaterialIcon icon="person_edit" symbolsOutlined />
          <Typography variant="caption">{t('created-by')}</Typography>
        </p>
        <div
          className={classNames(
            EntityViewStyles.infoItemContent,
            EntityViewStyles.infoItemContentFullWidth,
          )}
        >
          {createdBy ? (
            <UserChip user={createdBy} />
          ) : (
            <Typography variant="body">-</Typography>
          )}
        </div>
      </div>
      <div
        className={classNames(
          EntityViewStyles.infoItem,
          EntityViewStyles.infoItemCentered,
        )}
      >
        <p className={EntityViewStyles.infoItemLabel}>
          <MaterialIcon icon="how_to_reg" symbolsOutlined />
          <Typography variant="caption">{t('supervisor')}</Typography>
        </p>
        <div
          className={classNames(
            EntityViewStyles.infoItemContent,
            EntityViewStyles.infoItemContentFullWidth,
          )}
        >
          {supervisor ? (
            <UserChip user={supervisor} />
          ) : (
            <Typography variant="body">-</Typography>
          )}
        </div>
      </div>
      <div
        className={classNames(
          EntityViewStyles.infoItem,
          EntityViewStyles.infoItemCentered,
        )}
      >
        <p className={EntityViewStyles.infoItemLabel}>
          <MaterialIcon icon="person" symbolsOutlined />
          <Typography variant="caption">{t('assignees-label')}</Typography>
        </p>
        <div
          className={classNames(
            EntityViewStyles.infoItemContent,
            EntityViewStyles.infoItemContentFullWidth,
          )}
        >
          {usersData?.length ? (
            <UsersAvatarsPreview
              users={usersData}
              usersTotalCount={usersTotalCount}
              usersVisibleCount={4}
              onClick={useModalManagementResult.openInterventionAssigneesModal}
              useChipForSingleUser
            />
          ) : (
            <Typography variant="body">-</Typography>
          )}
        </div>
      </div>
    </div>
  );
}
