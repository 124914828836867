import React from 'react';
import AvatarUploader from '@lib/components/AvatarUploader/AvatarUploader';
import { FieldItem, Values } from '@lib/interfaces/form';
import ProfileView from 'components/Profile/ProfileView';
import styles from '../ProfileForm.module.scss';

export default function getProfileFormFields(
  data: Values,
  isOnline?: boolean,
): FieldItem[] {
  return [
    {
      name: 'avatarAttached',
      element: AvatarUploader,
      formItemClassName: styles.avatarBlock,
      componentProps: {
        disabled: !isOnline,
      },
    },
    {
      name: 'profileView',
      customRender: () => <ProfileView data={data} />,
    },
  ];
}
