import { useState } from 'react';
import { Intervention } from 'graphql-common';

export interface UseModalManagementResult {
  closeAllModals: () => void;
  closeCategoryFormModal: () => void;
  closeInterventionAssigneesModal: () => void;
  closeInterventionCloseModal: () => void;
  closeInterventionDestroyModal: () => void;
  isCategoryFormModalOpened: boolean;
  isInterventionAssigneesModalOpened: Intervention | null;
  isInterventionCloseModalOpened: boolean;
  isInterventionDestroyModalOpened: boolean;
  openCategoryFormModal: () => void;
  openInterventionAssigneesModal: (v: Intervention | null) => void;
  openInterventionCloseModal: () => void;
  openInterventionDestroyModal: () => void;
}

export default function useModalManagement(): UseModalManagementResult {
  const [isCategoryFormModalOpened, setCategoryFormModalOpened] =
    useState(false);
  const openCategoryFormModal = () => setCategoryFormModalOpened(true);
  const closeCategoryFormModal = () => setCategoryFormModalOpened(false);

  const [isInterventionDestroyModalOpened, setInterventionDestroyModalOpened] =
    useState(false);
  const openInterventionDestroyModal = () =>
    setInterventionDestroyModalOpened(true);
  const closeInterventionDestroyModal = () =>
    setInterventionDestroyModalOpened(false);

  const [isInterventionCloseModalOpened, setInterventionCloseModalOpened] =
    useState(false);
  const openInterventionCloseModal = () =>
    setInterventionCloseModalOpened(true);
  const closeInterventionCloseModal = () =>
    setInterventionCloseModalOpened(false);

  const [
    isInterventionAssigneesModalOpened,
    setInterventionAssigneesModalOpened,
  ] = useState<Intervention | null>(null);
  const openInterventionAssigneesModal = (v: Intervention | null) =>
    setInterventionAssigneesModalOpened(v);
  const closeInterventionAssigneesModal = () =>
    setInterventionAssigneesModalOpened(null);

  const closeAllModals = () => {
    closeCategoryFormModal();
    closeInterventionDestroyModal();
    closeInterventionCloseModal();
    closeInterventionAssigneesModal();
  };

  return {
    closeAllModals,
    closeCategoryFormModal,
    closeInterventionAssigneesModal,
    closeInterventionCloseModal,
    closeInterventionDestroyModal,
    isCategoryFormModalOpened,
    isInterventionAssigneesModalOpened,
    isInterventionCloseModalOpened,
    isInterventionDestroyModalOpened,
    openCategoryFormModal,
    openInterventionAssigneesModal,
    openInterventionCloseModal,
    openInterventionDestroyModal,
  };
}
