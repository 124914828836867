import _get from 'lodash/get';
import {
  AppUserAvailableTasksQuery,
  AppUserAvailableTasksQueryVariables,
  Asset,
  FilterGroupingEnum,
  Site,
  SiteArea,
  Task,
  TaskFilterInputObject,
  TaskScopeNameEnum,
} from 'graphql-common';
import QrCodePrefix from '@lib/enums/qrCodePrefix';
import { ErrorPageStatusEnum } from '@lib/components/ErrorPage/enums';
import TasksFilterFieldNames from '@lib/enums/fieldNames/tasksFilterFieldNames';
import { APP_URLS } from 'constants/urls';
import isErrorTimeoutError from '@lib/utils/isErrorTimeoutError';
import handleValidCodeForTasksActionFromIndexedDB from './handleValidCodeForTasksActionFromIndexedDB';
import { ScannerActionType } from '../enums';
import { CodeType } from './getCodeType';
import { ParsedCodes } from './getParsedCodes';
import { HandleQrCodeSuccessProps } from './types';

const defaultVariables: AppUserAvailableTasksQueryVariables = {
  page: 1,
  limit: 2,
  taskScope: {
    name: TaskScopeNameEnum.MyTodoAvailableToComplete,
  },
  taskFilters: undefined,
  siteFindBy: { code: undefined },
  assetFindBy: { code: undefined },
  siteAreaFindBy: { code: undefined },
};

export function getFiltersAndVariables(
  parsedCodes: ParsedCodes,
  codeType: CodeType,
) {
  const filters: TaskFilterInputObject[] = [];
  const { customPrefix, entityCode, sideCode } = parsedCodes;

  const variables = { ...defaultVariables };

  if (codeType !== CodeType.Invalid) {
    if (sideCode) {
      variables.siteFindBy.code = sideCode;
      filters.push({
        siteCode: {
          grouping: FilterGroupingEnum.And,
          predicate: { in: [sideCode] },
          filterGroup: 'siteAreaAssetFilerGroup',
        },
      });
    }
    if (entityCode) {
      variables.siteAreaFindBy.code = entityCode;
      variables.assetFindBy.code = entityCode;

      if (codeType === CodeType.SiteArea) {
        filters.push({
          siteAreaCode: {
            grouping: FilterGroupingEnum.And,
            predicate: { in: [entityCode] },
            filterGroup: 'siteAreaAssetFilerGroup',
          },
        });
        filters.push({
          assetId: {
            grouping: FilterGroupingEnum.And,
            predicate: { null: true },
            filterGroup: 'siteAreaAssetFilerGroup',
          },
        });
      }
      if (codeType === CodeType.Asset) {
        filters.push({
          assetCode: {
            grouping: FilterGroupingEnum.And,
            predicate: { in: [entityCode] },
            filterGroup: 'siteAreaAssetFilerGroup',
          },
        });
      }
      if (
        codeType === CodeType.Asset &&
        customPrefix &&
        customPrefix !== QrCodePrefix.SiteArea &&
        customPrefix !== QrCodePrefix.Asset
      ) {
        filters.push({
          assetQrPrefix: {
            grouping: FilterGroupingEnum.And,
            predicate: { in: [customPrefix] },
            filterGroup: 'siteAreaAssetFilerGroup',
          },
        });
      }
    }
  }

  return { filters, variables };
}

async function handleTimeoutOrOffline(
  data: string,
  props: HandleQrCodeSuccessProps,
  parsedCodes: ParsedCodes,
  codeType: CodeType,
) {
  console.warn('Switching to IndexedDB due to timeout or offline mode');
  await handleValidCodeForTasksActionFromIndexedDB(
    data,
    props,
    parsedCodes,
    codeType,
  );
}

function handleSuccessResponse(
  data: string,
  userAvailableData: AppUserAvailableTasksQuery | undefined,
  props: HandleQrCodeSuccessProps,
  parsedCodes: ParsedCodes,
  codeType: CodeType,
) {
  const { setCode, navigate, setLoading, setErrorStatus, onSuccessScanAction } =
    props;
  const { entityCode, sideCode } = parsedCodes;

  const collection = _get(userAvailableData, 'tasks.collection', []) as Task[];
  const site = _get(userAvailableData, 'site') as Site;
  const siteArea = _get(userAvailableData, 'siteArea') as SiteArea;
  const asset = _get(userAvailableData, 'asset') as Asset;

  setLoading(false);

  if (codeType === CodeType.Asset && !asset) {
    setErrorStatus(ErrorPageStatusEnum.QrCodeScanFailed);
    return;
  }

  if (collection.length) {
    setCode(data);
  }

  if (collection.length === 1) {
    navigate(
      APP_URLS.app.tasks.completionCreate.getDynamicPath({
        pathParts: { id: collection[0].id },
      }),
    );
  } else if (collection.length && (sideCode || entityCode)) {
    const values = {
      [TasksFilterFieldNames.Site]: site
        ? [{ value: site.id, label: site.name }]
        : undefined,
      [TasksFilterFieldNames.SiteArea]:
        codeType === CodeType.SiteArea && siteArea
          ? [{ value: siteArea.id, label: siteArea.name }]
          : undefined,
      [TasksFilterFieldNames.Asset]:
        codeType === CodeType.Asset && asset
          ? [{ value: asset.id, label: asset.name }]
          : undefined,
    };
    onSuccessScanAction(values, ScannerActionType.FindTasks);
  } else {
    setErrorStatus(ErrorPageStatusEnum.QrCodeScanFailed);
  }
}

export default async function handleValidCodeForTasksAction(
  data: string,
  props: HandleQrCodeSuccessProps,
  parsedCodes: ParsedCodes,
  codeType: CodeType,
  isOnline: boolean,
) {
  const { setLoading, fetchAppTasks, setErrorStatus } = props;
  try {
    if (!isOnline) {
      await handleTimeoutOrOffline(data, props, parsedCodes, codeType);
      return;
    }

    const { filters, variables } = getFiltersAndVariables(
      parsedCodes,
      codeType,
    );
    if (filters.length) {
      variables.taskFilters = filters;
    }

    setLoading(true);
    const res = await fetchAppTasks({ variables, errorPolicy: 'ignore' });

    const { data: userAvailableData, error } = res;
    const isTimeoutError = isErrorTimeoutError(error);

    if (isTimeoutError) {
      await handleTimeoutOrOffline(data, props, parsedCodes, codeType);
    } else if (error) {
      setErrorStatus(ErrorPageStatusEnum.QrCodeScanFailed);
    } else {
      handleSuccessResponse(
        data,
        userAvailableData,
        props,
        parsedCodes,
        codeType,
      );
    }
  } catch (error) {
    console.error('handleValidCode:', error);
    setErrorStatus(ErrorPageStatusEnum.QrCodeScanFailed);
  }
}
