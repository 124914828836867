import _get from 'lodash/get';
import {
  useAssetsSearchListLazyQuery,
  useInterventionCategoriesSearchListLazyQuery,
  useSiteAreasSearchListLazyQuery,
  useSitesSearchListLazyQuery,
  useUsersSearchListLazyQuery,
} from 'graphql-common';
import { GetLoadOptions } from '@lib/components/Select/types';
import FieldNames from '@lib/enums/fieldNames/interventionsFilterFieldNames';
import { getInterventionCategoriesLoadOptions as getInterventionCategoriesLoadOptionsFn } from 'utils/fetch/interventionCategoriesSearchList';
import { getSitesLoadOptions as getSitesLoadOptionsFn } from 'utils/fetch/sitesSearchList';
import { getUsersLoadOptions as getUsersLoadOptionsFn } from 'utils/fetch/usersSearchList';
import { getAssetsLoadOptions as getAssetsLoadOptionsFn } from 'utils/fetch/assetsSearchList';
import { getSiteAreasLoadOptions as getSiteAreasLoadOptionsFn } from 'utils/fetch/siteAreasSearchList';

export default function useSearchListQueries() {
  // Intervention categories
  const [fetchInterventionCategoriesLazyQuery] =
    useInterventionCategoriesSearchListLazyQuery({
      fetchPolicy: 'cache-and-network',
    });
  const getInterventionCategoriesLoadOptions = () =>
    getInterventionCategoriesLoadOptionsFn(
      fetchInterventionCategoriesLazyQuery,
    );

  // Sites
  const [fetchSitesSearchListLazyQuery] = useSitesSearchListLazyQuery({
    fetchPolicy: 'cache-and-network',
  });
  const getSitesLoadOptions: GetLoadOptions = () =>
    getSitesLoadOptionsFn(fetchSitesSearchListLazyQuery);

  // Assets
  const [fetchAssetsLazyQuery] = useAssetsSearchListLazyQuery({
    fetchPolicy: 'cache-and-network',
  });
  const getAssetsLoadOptions = (name, values) =>
    getAssetsLoadOptionsFn(
      fetchAssetsLazyQuery,
      _get(values, [FieldNames.Site, 'value'], '') as string,
    );

  // Site areas
  const [fetchSiteAreasSearchListLazyQuery] = useSiteAreasSearchListLazyQuery({
    fetchPolicy: 'network-only',
  });
  const getSiteAreasLoadOptions: GetLoadOptions = (name, values) =>
    getSiteAreasLoadOptionsFn(
      fetchSiteAreasSearchListLazyQuery,
      _get(values, [FieldNames.Site, 'value'], '') as string,
    );

  // Users
  const [fetchUsersSearchListLazyQuery] = useUsersSearchListLazyQuery({
    fetchPolicy: 'cache-and-network',
  });
  const getSupervisorLoadOptions = () => {
    return getUsersLoadOptionsFn(fetchUsersSearchListLazyQuery);
  };
  const getAssigneeLoadOptions = () => {
    return getUsersLoadOptionsFn(fetchUsersSearchListLazyQuery);
  };

  return {
    getSitesLoadOptions,
    getAssetsLoadOptions,
    getSiteAreasLoadOptions,
    getSupervisorLoadOptions,
    getAssigneeLoadOptions,
    getInterventionCategoriesLoadOptions,
  };
}
