import QrScanner from 'qr-scanner';
import { ErrorPageStatusEnum } from '@lib/components/ErrorPage/enums';
import getCodeType, { CodeType } from 'routes/Scanner/utils/getCodeType';
import getParsedCodes from 'routes/Scanner/utils/getParsedCodes';
import { USER_SELECTED_SCANNER_ACTION } from '@lib/enums/localStorageKeys';
import { ScannerActionType } from 'routes/Scanner/enums';
import handleValidCodeForInterventionsAction from 'routes/Scanner/utils/handleValidCodeForInterventionsAction';
import handleValidCodeForCreateInterventionAction from 'routes/Scanner/utils/handleValidCodeForCreateInterventionAction';
import handleIdMode from './handleIdMode';
import handleValidCodeForTasksAction from './handleValidCodeForTasksAction';
import { HandleQrCodeSuccessProps } from './types';

export default async function handleQrCodeSuccess(
  { data }: QrScanner.ScanResult,
  props: HandleQrCodeSuccessProps,
) {
  const {
    id,
    isOnline,
    onOpenCounterIndexFormModal,
    setErrorStatus,
    setLoading,
  } = props;

  const storedSelectedScannerAction =
    (localStorage.getItem(USER_SELECTED_SCANNER_ACTION) as ScannerActionType) ||
    undefined;

  // No code
  if (!data) return undefined;

  const codeType = getCodeType(data);

  if (codeType === CodeType.Invalid) {
    setErrorStatus(ErrorPageStatusEnum.QrCodeScanFailed);
    return undefined;
  }

  const parsedCodes = getParsedCodes(data);
  const { counterCode, entityCode } = parsedCodes;
  setLoading(true);
  switch (storedSelectedScannerAction) {
    case ScannerActionType.AddCounterIndex:
      // Scan process for Counter
      if (counterCode) {
        if (isOnline) {
          onOpenCounterIndexFormModal(entityCode, counterCode);
        } else {
          setErrorStatus(ErrorPageStatusEnum.QrCodeScanFailedOffline);
        }
      } else {
        setErrorStatus(ErrorPageStatusEnum.QrCodeScanFailedCounter);
      }
      break;
    case ScannerActionType.CreateIntervention:
      if (!isOnline) {
        setErrorStatus(ErrorPageStatusEnum.QrCodeScanFailedOffline);
        break;
      }
      if (counterCode) {
        setErrorStatus(ErrorPageStatusEnum.QrCodeScanFailed);
        break;
      }
      await handleValidCodeForCreateInterventionAction(
        data,
        props,
        parsedCodes,
        codeType,
      );
      break;
    case ScannerActionType.FindInterventions:
      if (!isOnline) {
        setErrorStatus(ErrorPageStatusEnum.QrCodeScanFailedOffline);
        break;
      }
      if (counterCode) {
        setErrorStatus(ErrorPageStatusEnum.QrCodeScanFailed);
        break;
      }
      await handleValidCodeForInterventionsAction(
        data,
        props,
        parsedCodes,
        codeType,
      );
      break;
    case ScannerActionType.FindTasks:
      if (counterCode) {
        setErrorStatus(ErrorPageStatusEnum.QrCodeScanFailed);
        break;
      }
      // Scan process from task page
      if (id) {
        handleIdMode(data, props);
        break;
      }
      // Scan process from scanner page
      await handleValidCodeForTasksAction(
        data,
        props,
        parsedCodes,
        codeType,
        isOnline,
      );
      break;
    default:
  }

  setLoading(false);
  return undefined;
}
