import { RefObject, useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import _toNumber from 'lodash/toNumber';
import QrScanner from 'qr-scanner';
import {
  Task,
  TaskScopeNameEnum,
  useAppTaskForScannerQuery,
  useAppUserAvailableInterventionsLazyQuery,
  useAppUserAvailableTasksLazyQuery,
  useInterventionPrefillDataLazyQuery,
} from 'graphql-common';
import { Values } from '@lib/interfaces/form';
import AssetCounterIndexFieldNames from '@lib/enums/fieldNames/assetCounterIndexFieldNames';
import toast from '@lib/components/Toast/Toast';
import { ErrorPageStatusEnum } from '@lib/components/ErrorPage/enums';
import { CounterIndexAction } from 'components/AssetCounterIndexModalForm/enums';
import useManageCodes from 'hooks/useManageCodes';
import useTaskIndexedDB from 'hooks/useTaskIndexedDB';
import useAssetCounterQueryHook from 'utils/fetch/useAssetCounterQueryHook';
import { useLocalStorage } from 'usehooks-ts';
import {
  INTERVENTION_FILTER_FORM,
  TASK_FILTER_FORM,
} from '@lib/enums/localStorageKeys';
import { ScannerActionType } from 'routes/Scanner/enums';
import { APP_URLS } from 'constants/urls';
import InterventionsFilterFieldNames from '@lib/enums/fieldNames/interventionsFilterFieldNames';
import useFilterParams from '@lib/hooks/useFilterParams';
import { GetUrlParams } from '@lib/enums/urls';
import useTabParam from '@lib/hooks/useTabParam';
import { CustomTaskScopeNameEnum } from 'constants/enums';
import handleQrCodeSuccess from './handleQrCodeSuccess';
import { UseModalManagementResult } from './useModalManagement';
import useCounterMutations from './useCounterMutations';

type Args = {
  id: string;
  isOnline: boolean;
  setErrorStatus: (v: ErrorPageStatusEnum | undefined) => void;
  setLoading: (v: boolean) => void;
  setScannerRenderDate: (v: string) => void;
  useModalManagementResult: UseModalManagementResult;
  closeScanner: () => void;
  isLoadingRef: RefObject<boolean>;
};

export default function useActions(args: Args) {
  const {
    id,
    isOnline,
    setErrorStatus,
    setLoading,
    setScannerRenderDate,
    useModalManagementResult,
    closeScanner,
    isLoadingRef,
  } = args;
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [tab] = useTabParam();
  const [, setFilterParams] = useFilterParams();
  const [, setTasksFilterForm] = useLocalStorage(TASK_FILTER_FORM, {});
  const [, setInterventionsFilterForm] = useLocalStorage(
    INTERVENTION_FILTER_FORM,
    {},
  );
  const [codesForApiCall, setCodesForApiCall] = useState<{
    entityCode: string | null;
    counterCode: string | null;
  }>({
    entityCode: null,
    counterCode: null,
  });
  const navigate = useNavigate();
  const { setCode } = useManageCodes();
  const { openCounterIndexFormModal } = useModalManagementResult;

  const useMutationsResult = useCounterMutations();

  // Tasks
  const [fetchAppTasks] = useAppUserAvailableTasksLazyQuery({
    fetchPolicy: 'network-only',
  });

  // Interventions
  const [fetchAppInterventions] = useAppUserAvailableInterventionsLazyQuery({
    fetchPolicy: 'network-only',
  });
  const [fetchInterventionPrefillData] = useInterventionPrefillDataLazyQuery({
    fetchPolicy: 'network-only',
  });

  const { data: downloadData, loading: downloadDataLoading } = useTaskIndexedDB(
    isOnline ? undefined : id,
  );
  const taskQuery = useAppTaskForScannerQuery({
    skip: !id,
    variables: { id: id || '' },
    fetchPolicy: 'cache-and-network',
  });
  const taskDataLoading = isOnline
    ? _get(taskQuery, 'loading')
    : downloadDataLoading;
  const taskData = isOnline
    ? (_get(taskQuery, 'data.data') as Task)
    : downloadData;
  const assetCode = _get(taskData, 'asset.code', '') as string;
  const assetQrPrefix = _get(taskData, 'asset.qrPrefix', '') as string;
  const siteAreaCode = _get(taskData, 'siteArea.code', '') as string;

  // Counter
  const counterQueryHookResult = useAssetCounterQueryHook({
    code: codesForApiCall?.counterCode,
    assetCode: codesForApiCall?.entityCode,
    skip: !(codesForApiCall?.counterCode && codesForApiCall?.entityCode),
  });
  const { data } = counterQueryHookResult;
  const { id: counterId, assetId: counterAssetId } = data || {};

  // Actions
  const onCloseModal = () => {
    useModalManagementResult.closeAllModals();
    setCodesForApiCall({ entityCode: null, counterCode: null });
    useMutationsResult.assetCounterMeasureCreateMutationResult?.reset();
    useMutationsResult.assetCounterResetToZeroMutationResult?.reset();
    useMutationsResult.assetCounterMeasureCreateMutationResult?.reset();
    useMutationsResult.assetCounterResetToZeroMutationResult?.reset();
    setScannerRenderDate(new Date().toISOString());
  };

  const onCounterMeasureCreate = (values: Values) => {
    useMutationsResult.assetCounterMeasureCreateMutation({
      variables: {
        assetId: counterAssetId,
        attributes: {
          index: _toNumber(_get(values, AssetCounterIndexFieldNames.Index)),
          counterId,
        },
      },
      onCompleted: () => {
        toast({ content: t('update-asset-counter-success') });
        onCloseModal();
      },
    });
  };

  const onCounterResetToZero = () => {
    useMutationsResult.assetCounterResetToZeroMutation({
      variables: {
        assetId: counterAssetId,
        id: counterId,
      },
      onCompleted: () => {
        toast({ content: t('update-asset-counter-success') });
        onCloseModal();
      },
    });
  };

  const onCounterIndexFormSubmit = (values: Values) => {
    const action = _get(values, [AssetCounterIndexFieldNames.Action, 'value']);
    if (action === CounterIndexAction.Index) {
      onCounterMeasureCreate(values);
    } else if (action === CounterIndexAction.Zero) {
      onCounterResetToZero();
    }
  };

  const onSuccessScanAction = useCallback(
    (values: Values, scannerAction: ScannerActionType) => {
      if (values && Object.keys(values).length > 0) {
        if (scannerAction === ScannerActionType.FindTasks) {
          setTasksFilterForm(values);
          navigate(
            APP_URLS.app.tasks.index.getPathWithQuery({
              [GetUrlParams.Tab]: isOnline
                ? tab || TaskScopeNameEnum.MyTodoAvailableToComplete
                : CustomTaskScopeNameEnum.Downloads,
            }),
          );
        } else if (scannerAction === ScannerActionType.FindInterventions) {
          setInterventionsFilterForm(values);
          navigate(
            APP_URLS.app.interventions.index.getPathWithQuery({
              [GetUrlParams.Tab]: tab,
            }),
          );
        } else if (scannerAction === ScannerActionType.CreateIntervention) {
          const assetId = _get(values, [
            InterventionsFilterFieldNames.Asset,
            0,
            'value',
          ]);
          const siteId = _get(values, [
            InterventionsFilterFieldNames.Site,
            0,
            'value',
          ]);
          const siteAreaId = _get(values, [
            InterventionsFilterFieldNames.SiteArea,
            0,
            'value',
          ]);
          if (pathname === APP_URLS.app.interventions.add.path) {
            setFilterParams({
              [GetUrlParams.AssetId]: assetId,
              [GetUrlParams.SiteId]: siteId,
              [GetUrlParams.SiteAreaId]: siteAreaId,
            });
          } else {
            navigate(
              APP_URLS.app.interventions.add.getPathWithQuery({
                assetId,
                siteId,
                siteAreaId,
              }),
            );
          }
        }
        closeScanner();
      }
    },
    [
      closeScanner,
      setTasksFilterForm,
      navigate,
      isOnline,
      tab,
      setInterventionsFilterForm,
      pathname,
      setFilterParams,
    ],
  );

  const assetCounterIndexMutationLoading =
    _get(useMutationsResult, [
      'assetCounterMeasureCreateMutationResult',
      'loading',
    ]) ||
    _get(useMutationsResult, [
      'assetCounterResetToZeroMutationResult',
      'loading',
    ]);
  const assetCounterIndexMutationError =
    _get(useMutationsResult, [
      'assetCounterMeasureCreateMutationResult',
      'error',
    ]) ||
    _get(useMutationsResult, [
      'assetCounterResetToZeroMutationResult',
      'error',
    ]);

  const qrCodeSuccessCallback = (result: QrScanner.ScanResult) => {
    if (!isLoadingRef.current) {
      handleQrCodeSuccess(result, {
        assetCode,
        assetQrPrefix,
        fetchAppTasks,
        fetchAppInterventions,
        fetchInterventionPrefillData,
        id,
        isOnline,
        navigate,
        setCode,
        setErrorStatus,
        setLoading,
        siteAreaCode,
        onSuccessScanAction,
        onOpenCounterIndexFormModal: (
          entityCode: string,
          counterCode: string,
        ) => {
          setCodesForApiCall({ entityCode, counterCode });
          openCounterIndexFormModal();
        },
      });
    }
  };

  return {
    assetCounterIndexMutationError,
    assetCounterIndexMutationLoading,
    counterQueryHookResult,
    loading: taskDataLoading,
    onCloseModal,
    onCounterIndexFormSubmit,
    qrCodeSuccessCallback,
  };
}
