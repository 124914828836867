import { ErrorPageStatusEnum } from '@lib/components/ErrorPage/enums';
import { APP_URLS } from 'constants/urls';
import { HandleQrCodeSuccessProps } from './types';

export default function handleIdMode(
  data: string,
  {
    id,
    assetCode,
    assetQrPrefix,
    siteAreaCode,
    setCode,
    navigate,
    setErrorStatus,
  }: HandleQrCodeSuccessProps,
) {
  try {
    if (id !== undefined) {
      let isAvailable = false;
      if (assetCode) {
        isAvailable = data.indexOf(assetCode) > -1;
      }
      if (assetQrPrefix) {
        isAvailable = data.indexOf(assetQrPrefix) > -1;
      }
      if (siteAreaCode && !isAvailable) {
        isAvailable = data.indexOf(siteAreaCode) > -1;
      }
      if (isAvailable) {
        setCode(data);
        navigate(
          APP_URLS.app.tasks.completionCreate.getDynamicPath({
            pathParts: { id },
          }),
        );
      } else {
        setErrorStatus(ErrorPageStatusEnum.QrCodeScanFailed);
      }
    }
  } catch (error) {
    console.error('handleIdMode:', error);
  }
}
