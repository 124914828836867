import React from 'react';
import { TFunction } from 'i18next';
import { LazyQueryExecFunction } from '@apollo/client';
import { UsersSearchListQuery } from 'graphql-common';
import { FieldItem } from '@lib/interfaces/form';
import Input from '@lib/components/Input/Input';
import FieldNames from '@lib/enums/fieldNames/interventionActionFieldNames';
import {
  MAX_TEXT_EDITOR_FIELD_LENGTH,
  MAX_TEXT_FIELD_LENGTH,
} from '@lib/enums/form';
import { InputTypes } from '@lib/components/Input/enums';
import Select, {
  SelectComponents,
  SelectProps,
} from '@lib/components/Select/Select';
import { GetLoadOptions } from '@lib/components/Select/types';
import UserChipsList from '@lib/components/UserChipsListField/UserChipsListField';
import Typography from '@lib/components/Typography/Typography';
import UserOption from '@lib/components/Select/components/UserOption';
import DatePicker from '@lib/components/DateTimePicker/DatePicker';
import {
  getAttachmentsValidation,
  getFieldValidation,
  getOptionsArrayValidation,
  getOptionValidation,
  getTimeValidation,
} from '@lib/utils/validationUtils';
import { TIME_MASK } from '@lib/components/Input/masks';
import FilesUploader from '@lib/components/FilesUploader/FilesUploader';
import { UppyDocumentationAllowedFileTypes } from '@lib/enums/mimeTypes';
import { ButtonSizes } from '@lib/components/Button/Button';
import { FilesPreview } from '@lib/components/FilesUploader/enums';
import MultiSelectValueContainer from '@lib/components/Select/components/MultiSelectValueContainer';
import { getInterventionStatusOptions } from '@lib/utils/status';
import ChipValueContainer from '@lib/components/Select/components/ChipValueContainer';
import ChipCircleOption from '@lib/components/Select/components/ChipCircleOption';
import formStyles from '@lib/assets/styles/Form.module.scss';
import TextEditor from '@lib/components/TextEditor/TextEditor';

type Args = {
  actionId?: string;
  getAssigneeLoadOptions: GetLoadOptions;
  t: TFunction<'translation', undefined>;
  fetchUsersSearchListLazyQuery: LazyQueryExecFunction<
    UsersSearchListQuery,
    any
  >;
};

export default function getInterventionActionFormFields(
  args: Args,
): FieldItem[] {
  const { getAssigneeLoadOptions, actionId, t, fetchUsersSearchListLazyQuery } =
    args;
  return [
    {
      name: 'details-title',
      customRender: () => (
        <Typography variant="h3" key="details">
          {t('details')}
        </Typography>
      ),
    },
    {
      name: FieldNames.Status,
      label: t('action-status'),
      element: Select,
      validation: getOptionValidation(true, t),
      componentProps: {
        options: getInterventionStatusOptions(),
        isSearchable: false,
        isClearable: false,
        components: {
          ValueContainer: ChipValueContainer,
          Option: ChipCircleOption,
        },
      },
    },
    {
      name: FieldNames.Description,
      label: t('description-action-results'),
      element: TextEditor,
      componentProps: {
        fetchUsersSearchListLazyQuery,
      },
      validation: getFieldValidation(true, MAX_TEXT_EDITOR_FIELD_LENGTH, t),
    },
    {
      name: FieldNames.AttachmentsAttached,
      label: '',
      validation: getAttachmentsValidation(false, t),
      element: FilesUploader,
      componentProps: {
        showOfflineMessage: true,
        filesView: FilesPreview.Documents,
        allowedFileTypes: UppyDocumentationAllowedFileTypes,
        note: t('files-upload-validation-msg', { sizeImg: 5, sizePdf: 25 }),
        maxFileSize: 26214400,
        uploadButtonProps: {
          buttonText: t('upload-files'),
          buttonSize: ButtonSizes.large,
          leftIcon: 'upload',
          fullWidth: true,
        },
      },
    },
    {
      name: 'assign-action',
      customRender: () => (
        <Typography variant="h3" key="subjects">
          {t('assign-the-action')}
        </Typography>
      ),
    },
    {
      name: FieldNames.Assignee,
      label: t('assignees-label'),
      element: Select,
      validation: getOptionsArrayValidation(true, t),
      componentProps: {
        isAsync: true,
        isMulti: true,
        getLoadOptions: getAssigneeLoadOptions,
        defaultOptions: false,
        components: {
          Option: UserOption,
          ValueContainer: MultiSelectValueContainer,
        } as unknown as SelectComponents,
      } as SelectProps,
      hideErrorsSpace: true,
    },
    {
      name: `${FieldNames.Assignee}-list`,
      element: UserChipsList,
      componentProps: {
        fieldName: FieldNames.Assignee,
        actionId,
      },
      isUseWatch: true,
    },
    {
      name: 'timing-title',
      customRender: () => (
        <Typography variant="h3" key="timing">
          {t('timing')}
        </Typography>
      ),
    },
    {
      name: FieldNames.EstimatedTime,
      label: `${t('estimated-time')} hh:mm`,
      validation: getTimeValidation(false, t),
      element: Input,
      formItemClassName: formStyles.formColumn,
      componentProps: {
        mask: TIME_MASK,
      },
    },
    {
      name: FieldNames.EstimatedDate,
      label: t('estimated-date'),
      element: DatePicker,
      componentProps: {
        type: InputTypes.date,
      },
      validation: getFieldValidation(false, MAX_TEXT_FIELD_LENGTH, t),
      formItemClassName: formStyles.formColumn,
    },
    {
      name: FieldNames.LogisticTime,
      label: `${t('logistic-time')} hh:mm`,
      validation: getTimeValidation(false, t),
      element: Input,
      formItemClassName: formStyles.formColumn,
      componentProps: {
        mask: TIME_MASK,
      },
    },
    {
      name: FieldNames.DurationTime,
      label: `${t('time-spent')} hh:mm`,
      description: t('time-spent-description'),
      validation: getTimeValidation(false, t),
      element: Input,
      formItemClassName: formStyles.formColumn,
      componentProps: {
        mask: TIME_MASK,
      },
    },
  ];
}
