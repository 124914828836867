import { TFunction } from 'i18next';
import _get from 'lodash/get';
import Select, { SelectComponents } from '@lib/components/Select/Select';
import DateRangePicker from '@lib/components/DateTimePicker/DateRangePicker';
import { GetLoadOptions } from '@lib/components/Select/types';
import { InputTypes } from '@lib/components/Input/enums';
import { FieldChangeCallBackArgs, Values } from '@lib/interfaces/form';
import { getTaskPriorityOptions } from '@lib/utils/priority';
import AssetOption from '@lib/components/Select/components/AssetOption';
import AssetSingleValue from '@lib/components/Select/components/AssetSingleValue';
import TasksFilterFieldNames from '@lib/enums/fieldNames/tasksFilterFieldNames';
import ChipCircleOption from '@lib/components/Select/components/ChipCircleOption';

type Args = {
  getFormCategoriesLoadOptions: GetLoadOptions;
  getSitesLoadOptions: GetLoadOptions;
  t: TFunction<'translation', undefined>;
};

export default function useFilterFields(args: Args) {
  const { getFormCategoriesLoadOptions, getSitesLoadOptions, t } = args;
  return [
    {
      name: TasksFilterFieldNames.Period,
      label: t('period'),
      element: DateRangePicker,
      componentProps: {
        type: InputTypes.dateRange,
      },
    },
    {
      name: TasksFilterFieldNames.Site,
      label: t('sites-label'),
      element: Select,
      componentProps: {
        defaultOptions: true,
        isAsync: true,
        isMulti: true,
        getLoadOptions: getSitesLoadOptions,
        maxMenuHeight: 200,
        usePortal: true,
      },
      fieldChangeCallBack: ({
        type,
        setValue,
        name,
      }: FieldChangeCallBackArgs) => {
        if (type === 'change' && name === TasksFilterFieldNames.Site) {
          setValue(TasksFilterFieldNames.SiteArea, null, {
            shouldValidate: true,
          });
          setValue(TasksFilterFieldNames.Asset, null, { shouldValidate: true });
        }
      },
    },
    {
      name: TasksFilterFieldNames.SiteArea,
      label: t('qr-code-scan-result-area'),
      element: Select,
      componentProps: {
        isMulti: true,
        isClearable: false,
        maxMenuHeight: 200,
        menuIsOpen: false,
        usePortal: true,
      },
      isVisibleFn: (values: Values, name?: string) => {
        if (name) {
          return (
            _get(values, [TasksFilterFieldNames.Site, 0, 'value']) &&
            _get(values, [TasksFilterFieldNames.SiteArea, 0, 'value'])
          );
        }
        return false;
      },
    },
    {
      name: TasksFilterFieldNames.Asset,
      label: t('qr-code-scan-result-asset'),
      element: Select,
      componentProps: {
        isMulti: true,
        isClearable: false,
        maxMenuHeight: 200,
        menuIsOpen: false,
        components: {
          Option: AssetOption,
          SingleValue: AssetSingleValue,
        } as unknown as SelectComponents,
        usePortal: true,
      },
      isVisibleFn: (values: Values, name?: string) => {
        if (name) {
          return (
            _get(values, [TasksFilterFieldNames.Site, 0, 'value']) &&
            _get(values, [TasksFilterFieldNames.Asset, 0, 'value'])
          );
        }
        return false;
      },
    },
    {
      name: TasksFilterFieldNames.Priority,
      label: t('priority'),
      element: Select,
      componentProps: {
        isSearchable: false,
        isMulti: true,
        options: getTaskPriorityOptions(),
        menuPlacement: 'bottom',
        components: {
          // ValueContainer: ChipValueContainer,
          Option: ChipCircleOption,
        },
      },
    },
    {
      name: TasksFilterFieldNames.FormCategory,
      label: t('category'),
      element: Select,
      componentProps: {
        defaultOptions: true,
        isAsync: true,
        isMulti: true,
        getLoadOptions: getFormCategoriesLoadOptions,
        maxMenuHeight: 200,
      },
    },
  ];
}
