import React from 'react';
import * as Yup from 'yup';
import {
  FormAnswer,
  FormTemplateQuestion,
  FormTemplateQuestionFieldsKindEnum,
  GroupEnum,
} from 'graphql-common';
import { useTranslation } from 'react-i18next';
import Typography from '@lib/components/Typography/Typography';
import { FieldItem, Values } from '@lib/interfaces/form';
import Image from '@lib/components/Image/Image';
import Input from '@lib/components/Input/Input';
import { InputTypes } from '@lib/components/Input/enums';
import {
  MAX_TEXT_FIELD_LENGTH,
  QUESTION_NUMBER_INPUT_PROPS,
} from '@lib/enums/form';
import DatePicker from '@lib/components/DateTimePicker/DatePicker';
import DateRangePicker from '@lib/components/DateTimePicker/DateRangePicker';
import DateTimePicker from '@lib/components/DateTimePicker/DateTimePicker';
import TimeRangePicker from '@lib/components/DateTimePicker/TimeRangePicker';
import TimePicker from '@lib/components/DateTimePicker/TimePicker';
import InputMinMax from '@lib/components/Input/InputMinMax';
import Rating from '@lib/components/Rating/Rating';
import RadioButtonGroup, {
  RadioButtonWrapViews,
} from '@lib/components/RadioButtonGroup/RadioButtonGroup';
import {
  numberRangeValidationFn,
  rangeRequiredValidationFn,
  timeRangeValidationFn,
} from '@lib/utils/yup';
import CheckBoxGroup from '@lib/components/CheckBoxGroup/CheckBoxGroup';
import Select from '@lib/components/Select/Select';
import RadioButtonAsButton from '@lib/components/RadioButtonAsButton/RadioButtonAsButton';
import FilesUploader from '@lib/components/FilesUploader/FilesUploader';
import { UppyImageAllowedFileTypes } from '@lib/enums/mimeTypes';
import styles from 'components/TaskCompletionForm/TaskCompletionForm.module.scss';
import classNames from 'classnames';
import CheckboxButtonGrid from '@lib/components/CheckboxButtonGrid/CheckboxButtonGrid';
import SupportiveMessage, {
  SupportiveMessageColor,
  SupportiveMessageIconColor,
  SupportiveMessageIconType,
  SupportiveMessageType,
} from '@lib/components/SupportiveMessage/SupportiveMessage';
import {
  getAttachmentsValidation,
  getFieldValidation,
  getOptionsArrayValidation,
  getOptionsValidation,
  getOptionValidation,
  getRadioButtonGridValidation,
} from '@lib/utils/validationUtils';
import { getFieldOptions, getResponseFormattedValue } from './utils';

export default function useFields(
  questions: FormTemplateQuestion[],
  lastAllowedAnswers?: FormAnswer[],
  disabled?: boolean,
) {
  const { t } = useTranslation();
  const formFields: FieldItem[] = [];
  const defaultValues: Values = {};

  if (!questions) return { formFields, defaultValues };

  questions
    .sort((a, b) => a.position - b.position)
    .forEach((question, index) => {
      const {
        id: questionId,
        name,
        description,
        attachmentsAttached,
        fields,
      } = question;
      const isQuestionRequired =
        !disabled && fields.some(({ required }) => required);

      if (name || description) {
        const isQuestionsWithSectionField =
          fields[0].kind === FormTemplateQuestionFieldsKindEnum.Section;
        formFields.push({
          name: `questionRender-${index}`,
          customRender: () => (
            <div
              className={classNames({
                [styles.questionContent]: index > 0,
                [styles.sectionQuestionContent]:
                  index > 0 && isQuestionsWithSectionField,
              })}
            >
              {name && (
                <Typography
                  variant={isQuestionsWithSectionField ? 'h1' : 'h3'}
                  className={styles.questionName}
                >
                  {name}
                  {isQuestionRequired && (
                    <span className={styles.required}>*</span>
                  )}
                </Typography>
              )}
              {description && (
                <Typography
                  variant="body"
                  className={styles.questionDescription}
                >
                  {description}
                </Typography>
              )}
              {!!attachmentsAttached?.length && (
                <div className={styles.questionAttachments}>
                  {attachmentsAttached.map((attachment) => (
                    <Image
                      key={attachment.id}
                      src={
                        attachment?.urls?.small || attachment?.urls?.original
                      }
                    />
                  ))}
                </div>
              )}
            </div>
          ),
        });
      }

      fields.forEach(
        ({ id: fieldId, kind, options, required, settings = {} }) => {
          const valueName = `question-${questionId}-field-${fieldId}`;
          const commonProps = {
            name: valueName,
            isDisabledFn: () => !!disabled,
          };
          const {
            allowDisplayLastAnswer,
            limitToOneRespPerRow,
            limitToOneRespPerColumn,
          } = settings;
          const questionAnswer = lastAllowedAnswers?.length
            ? lastAllowedAnswers.find(
                ({ questionId: respQuestionId, fieldId: respFieldId }) =>
                  questionId === respQuestionId && fieldId === respFieldId,
              )
            : undefined;
          const respValue = getResponseFormattedValue(questionAnswer, t);
          defaultValues[valueName] = undefined;
          if (allowDisplayLastAnswer && respValue) {
            formFields.push({
              name: `messageRender-${fieldId}`,
              customRender: () => (
                <SupportiveMessage
                  text={
                    <>
                      {t('the-last-statement')}: <strong>{respValue}</strong>
                    </>
                  }
                  hasBottomSpace={false}
                />
              ),
            });
          }
          switch (kind) {
            case FormTemplateQuestionFieldsKindEnum.Section:
              formFields.push({
                ...commonProps,
                element: Input,
                isVisibleFn: () => false,
                isDisabledFn: () => true,
              });
              break;
            case FormTemplateQuestionFieldsKindEnum.Input:
              formFields.push({
                ...commonProps,
                label: t('your-answer'),
                validation: getFieldValidation(
                  required,
                  MAX_TEXT_FIELD_LENGTH,
                  t,
                ),
                element: Input,
              });
              break;
            case FormTemplateQuestionFieldsKindEnum.TextArea:
              formFields.push({
                ...commonProps,
                label: t('your-answer'),
                validation: getFieldValidation(
                  required,
                  MAX_TEXT_FIELD_LENGTH,
                  t,
                ),
                element: Input,
                componentProps: { type: InputTypes.textarea },
              });
              break;
            case FormTemplateQuestionFieldsKindEnum.NumberInput:
              formFields.push({
                ...commonProps,
                label: t('your-answer'),
                validation: getFieldValidation(
                  required,
                  MAX_TEXT_FIELD_LENGTH,
                  t,
                ),
                element: Input,
                componentProps: { ...QUESTION_NUMBER_INPUT_PROPS },
              });
              break;
            case FormTemplateQuestionFieldsKindEnum.MinMax:
              formFields.push({
                ...commonProps,
                isOptionalFn: () => !required,
                validation: Yup.array()
                  .of(Yup.number())
                  .test('is-range', t('required-range-error'), (value) =>
                    numberRangeValidationFn(value, required),
                  )
                  .test('is-required', t('required-field-error'), (value) =>
                    rangeRequiredValidationFn(value, required),
                  ),
                element: InputMinMax,
              });
              break;
            case FormTemplateQuestionFieldsKindEnum.Date:
              formFields.push({
                ...commonProps,
                label: t('select-date'),
                validation: getFieldValidation(
                  required,
                  MAX_TEXT_FIELD_LENGTH,
                  t,
                ),
                element: DatePicker,
                componentProps: {
                  type: InputTypes.date,
                },
              });
              break;
            case FormTemplateQuestionFieldsKindEnum.DateRange:
              formFields.push({
                ...commonProps,
                label: t('select-date-range'),
                validation: getOptionsValidation(required, true, t),
                element: DateRangePicker,
                componentProps: {
                  type: InputTypes.dateRange,
                },
              });
              break;
            case FormTemplateQuestionFieldsKindEnum.Time:
              formFields.push({
                ...commonProps,
                label: t('select-time'),
                validation: getFieldValidation(
                  required,
                  MAX_TEXT_FIELD_LENGTH,
                  t,
                ),
                element: TimePicker,
                componentProps: {
                  type: InputTypes.time,
                },
              });
              break;
            case FormTemplateQuestionFieldsKindEnum.TimeRange:
              formFields.push({
                ...commonProps,
                label: t('select-time'),
                isOptionalFn: () => !required,
                validation: Yup.array()
                  .of(Yup.string())
                  .test('is-range', t('required-range-error'), (value) =>
                    required ? timeRangeValidationFn(value, required) : true,
                  )
                  .test('is-required', t('required-field-error'), (value) =>
                    rangeRequiredValidationFn(value, required),
                  ),
                element: TimeRangePicker,
                componentProps: {
                  type: InputTypes.dateTime,
                },
              });
              break;
            case FormTemplateQuestionFieldsKindEnum.Datetime:
              formFields.push({
                ...commonProps,
                label: t('select-date-and-time'),
                validation: getFieldValidation(
                  required,
                  MAX_TEXT_FIELD_LENGTH,
                  t,
                ),
                element: DateTimePicker,
                componentProps: {
                  type: InputTypes.dateTime,
                },
              });
              break;
            case FormTemplateQuestionFieldsKindEnum.RadioButton:
              formFields.push({
                ...commonProps,
                label: '',
                validation: getOptionValidation(required, t),
                element: RadioButtonGroup,
                componentProps: { options: getFieldOptions(options) },
              });
              break;
            case FormTemplateQuestionFieldsKindEnum.SingleSelect:
              formFields.push({
                ...commonProps,
                label: t('select-option'),
                validation: getOptionValidation(required, t),
                element: Select,
                componentProps: {
                  options: getFieldOptions(options),
                  isSearchable: false,
                },
              });
              break;
            case FormTemplateQuestionFieldsKindEnum.Checkbox:
              formFields.push({
                ...commonProps,
                label: '',
                validation: getOptionsArrayValidation(required, t),
                element: CheckBoxGroup,
                componentProps: { options: getFieldOptions(options) },
              });
              break;
            case FormTemplateQuestionFieldsKindEnum.Rating:
              formFields.push({
                ...commonProps,
                validation: getOptionValidation(required, t),
                element: Rating,
                componentProps: { options: getFieldOptions(options) },
              });
              break;
            case FormTemplateQuestionFieldsKindEnum.CheckboxButtonGrid:
              formFields.push({
                ...commonProps,
                validation: getRadioButtonGridValidation(required, t),
                element: CheckboxButtonGrid,
                componentProps: {
                  options: getFieldOptions(options),
                  limitToOneRespPerRow,
                  limitToOneRespPerColumn,
                },
              });
              break;
            case FormTemplateQuestionFieldsKindEnum.DecisionBox:
              formFields.push({
                ...commonProps,
                label: '',
                validation: getOptionsArrayValidation(required, t),
                element: CheckBoxGroup,
                componentProps: { options: getFieldOptions(options) },
              });
              break;
            case FormTemplateQuestionFieldsKindEnum.Attachment:
              formFields.push({
                ...commonProps,
                label: '',
                validation: getAttachmentsValidation(required, t),
                element: FilesUploader,
                componentProps: {
                  showOfflineMessage: true,
                  options: getFieldOptions(options),
                  allowedFileTypes: UppyImageAllowedFileTypes,
                  note: t('image-upload-validation-msg', { size: 5 }),
                  showDeleteAllButton: true,
                },
              });
              break;
            case FormTemplateQuestionFieldsKindEnum.YesNo:
              formFields.push({
                ...commonProps,
                validation: getOptionValidation(required, t),
                element: RadioButtonGroup,
                componentProps: {
                  options: getFieldOptions(options).map(({ group }) => ({
                    value: group,
                    label: group,
                    leftIcon:
                      group === GroupEnum.Yes ? 'check_circle' : 'cancel',
                  })),
                  RadioButtonComponent: RadioButtonAsButton,
                  wrapView: RadioButtonWrapViews.list,
                  radioButtonComponentProps: {
                    fullWidth: true,
                    leftIconClassName: disabled ? undefined : styles.blueIcon,
                  },
                },
              });
              break;
            case FormTemplateQuestionFieldsKindEnum.ImportantNotice:
              formFields.push({
                name: `question-${questionId}-field-${fieldId}`,
                customRender: () => (
                  <SupportiveMessage
                    text={options?.[0]?.values?.[0]}
                    leftIcon="warning"
                    color={SupportiveMessageColor.Yellow}
                    iconColor={SupportiveMessageIconColor.Inner}
                    iconType={SupportiveMessageIconType.FilledWithBorder}
                    type={SupportiveMessageType.FilledWithBorder}
                  />
                ),
              });
              break;
            default:
              break;
          }
        },
      );
    });

  return { formFields, defaultValues };
}
