import React from 'react';
import { ContainerProps } from 'react-select';
import SelectContainer from '@lib/components/Select/components/IconButtonSelectContainer';

export default function CameraSelectContainer(containerProps: ContainerProps) {
  return (
    <SelectContainer
      {...containerProps}
      iconButtonProps={{
        icon: 'photo_camera',
        symbolsOutlined: true,
      }}
    />
  );
}
