import { useTranslation } from 'react-i18next';
import {
  useInterventionActionCreateMutation,
  useInterventionActionDestroyMutation,
  useInterventionActionUpdateMutation,
  useInterventionBulkCloseMutation,
  useInterventionCreateMutation,
  useInterventionDestroyMutation,
  useInterventionUpdateMutation,
} from 'graphql-common';
import { showGraphQLErrors } from '@lib/utils/graphQLErrors';

export default function useMutations() {
  const { t } = useTranslation();

  const [interventionCreateMutation, interventionCreateMutationResult] =
    useInterventionCreateMutation({
      onError: (error) => showGraphQLErrors(error, t),
    });

  const [interventionUpdateMutation, interventionUpdateMutationResult] =
    useInterventionUpdateMutation({
      onError: (error) => showGraphQLErrors(error, t),
    });

  const [interventionDestroyMutation] = useInterventionDestroyMutation({
    onError: (error) => showGraphQLErrors(error, t),
  });

  const [interventionBulkCloseMutation] = useInterventionBulkCloseMutation({
    onError: (error) => showGraphQLErrors(error, t),
  });

  const [
    interventionActionCreateMutation,
    interventionActionCreateMutationResult,
  ] = useInterventionActionCreateMutation({
    onError: (error) => showGraphQLErrors(error, t),
  });

  const [
    interventionActionUpdateMutation,
    interventionActionUpdateMutationResult,
  ] = useInterventionActionUpdateMutation({
    onError: (error) => showGraphQLErrors(error, t),
  });

  const [interventionActionDestroyMutation] =
    useInterventionActionDestroyMutation({
      onError: (error) => showGraphQLErrors(error, t),
    });

  return {
    interventionActionCreateMutation,
    interventionActionCreateMutationResult,
    interventionActionDestroyMutation,
    interventionActionUpdateMutation,
    interventionActionUpdateMutationResult,
    interventionBulkCloseMutation,
    interventionCreateMutation,
    interventionCreateMutationResult,
    interventionDestroyMutation,
    interventionUpdateMutation,
    interventionUpdateMutationResult,
  };
}
