import React from 'react';
import { useTranslation } from 'react-i18next';
import { Values } from '@lib/interfaces/form';
import Typography from '@lib/components/Typography/Typography';
import replaceNullsWithUndefined from '@lib/utils/replaceNullsWithUndefined';
import Form from '@lib/components/ReactHookForm/FormContainer';
import useIsOnline from '@lib/hooks/useIsOnline';
import getDefaultLangFormValuesFromQueryData from './utils/getDefaultLangFormValuesFromQueryData';
import getLangFormFields from './utils/getLangFormFields';
import getPreparedDataFromValues from './utils/getPreparedDataFromValues';
import { OnUpdateProfile } from './types';
import styles from './ProfileForm.module.scss';

interface Props {
  defaultValues: Values;
  loading: boolean;
  onSubmit: OnUpdateProfile;
}

function LangForm(props: Props) {
  const { defaultValues, onSubmit, loading } = props;
  const { t } = useTranslation();
  const isOnline = useIsOnline();

  const fields = getLangFormFields({
    t,
    loading,
    isOnline,
  });

  const onSubmitHandler = (values: Values) => {
    onSubmit(getPreparedDataFromValues(values));
  };

  const preparedDefaultValues = replaceNullsWithUndefined(
    getDefaultLangFormValuesFromQueryData(defaultValues),
  );

  return (
    <div className={styles.wrapSecondary}>
      <Typography variant="h3" className={styles.title}>
        {t('app-language')}
      </Typography>
      <Form
        defaultValues={preparedDefaultValues}
        fields={fields}
        formClassName={styles.form}
        onSubmit={onSubmitHandler}
        submitOnChange
      />
    </div>
  );
}

export default LangForm;
