import React from 'react';
import { InterventionScopeNameEnum } from 'graphql-common';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import EmptyState from '@lib/components/EmptyState/EmptyState';
import Trans from '@lib/components/Trans/Trans';
import { useTranslation } from 'react-i18next';
import { IconName } from '@lib/components/Modal/enums';

type Props = {
  isAddActionDisabled?: boolean;
  isEmptyState: boolean;
  isEmptyStateByFilter: boolean;
  isEmptyStateBySearch: boolean;
  onResetFilter: () => void;
  openCreateInterventionPage: () => void;
  setSearchQuery: (v: string) => void;
  tabParam?: InterventionScopeNameEnum;
  isViewOnly: boolean;
};

function getEmptyStateTextKeys(tabParam?: InterventionScopeNameEnum) {
  switch (tabParam) {
    case InterventionScopeNameEnum.OpenedMyParticipation:
      return ['no-my-interventions-yet', 'no-my-interventions-text'];
    case InterventionScopeNameEnum.Opened:
      return ['no-opened-interventions-yet', 'no-opened-interventions-text'];
    case InterventionScopeNameEnum.Closed:
      return ['no-closed-interventions-yet', 'no-closed-interventions-text'];
    default:
      return ['no-interventions-yet', 'no-interventions-text'];
  }
}

function InterventionListEmptyState(props: Props) {
  const {
    isAddActionDisabled,
    isEmptyState,
    isEmptyStateByFilter,
    isEmptyStateBySearch,
    onResetFilter,
    openCreateInterventionPage,
    setSearchQuery,
    tabParam,
    isViewOnly,
  } = props;
  const { t } = useTranslation();
  const [emptyStateTitleKey, emptyStateTextKey] =
    getEmptyStateTextKeys(tabParam);

  if (isEmptyStateBySearch) {
    return (
      <EmptyState
        title={t('no-results-found')}
        text={t('no-results-found-text')}
        icon={IconName.SearchOff}
        actions={[
          <Button
            key="back"
            buttonText={t('go-back')}
            buttonType={ButtonTypes.primaryFilled}
            leftIcon="arrow_back"
            onClick={() => setSearchQuery('')}
          />,
        ]}
      />
    );
  }

  if (isEmptyStateByFilter) {
    return (
      <EmptyState
        title={t('no-results-found')}
        text={t('try-something-else')}
        icon={IconName.SearchOff}
        actions={[
          <Button
            key="back"
            buttonText={t('clear-filtering')}
            buttonType={ButtonTypes.primaryFilled}
            onClick={onResetFilter}
          />,
        ]}
      />
    );
  }

  if (isEmptyState) {
    return (
      <EmptyState
        title={t(emptyStateTitleKey)}
        text={<Trans i18nKey={emptyStateTextKey} />}
        icon={IconName.Cancel}
        actions={
          tabParam === InterventionScopeNameEnum.Closed || isViewOnly
            ? undefined
            : [
                <Button
                  key="add"
                  leftIcon={isAddActionDisabled ? 'lock' : 'add'}
                  buttonText={t('create-intervention')}
                  buttonType={ButtonTypes.primaryFilled}
                  onClick={openCreateInterventionPage}
                  disabled={isAddActionDisabled}
                />,
              ]
        }
      />
    );
  }

  return null;
}

export default InterventionListEmptyState;
