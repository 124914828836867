import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import _isEmpty from 'lodash/isEmpty';
import { Values } from '@lib/interfaces/form';
import Modal from '@lib/components/Modal/Modal';
import Form from '@lib/components/ReactHookForm/FormContainer';
import Button, {
  ButtonSizes,
  ButtonTypes,
} from '@lib/components/Button/Button';
import formStyles from '@lib/assets/styles/Form.module.scss';
import Chip, { ChipSizes, ChipTypes } from '@lib/components/Chip/Chip';
import IconButton, {
  IconButtonTypes,
} from '@lib/components/IconButton/IconButton';
import useOutletContext, { ContextProps } from 'hooks/useOutletContext';
import { ScannerActionType } from 'routes/Scanner/enums';
import { useLocalStorage } from 'usehooks-ts';
import { INTERVENTION_FILTER_FORM } from '@lib/enums/localStorageKeys';
import ViewOnlyChip from '@lib/components/ViewOnlyChip/ViewOnlyChip';
import useWindowDimensions from '@lib/hooks/useWindowDimensions';
import InterventionListFilterActions from './InterventionListFilterActions';
import useSearchListQueries from './utils/useSearchListQueries';
import useFilterFields from './utils/useFilterFields';
import styles from './InterventionListFilter.module.scss';

type Props = {
  isViewOnly: boolean;
  onCreateIntervention: () => void;
  onResetFilter: () => void;
};

export default function InterventionListFilter(props: Props) {
  const { onCreateIntervention, isViewOnly, onResetFilter } = props;
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const [filterParams, setFilterParams] = useLocalStorage(
    INTERVENTION_FILTER_FORM,
    {},
  );
  const [isFilterOpened, setFilterOpened] = useState(false);
  const openFilter = () => setFilterOpened(true);
  const closeFilter = () => setFilterOpened(false);
  const clearFilter = () => {
    setFilterOpened(false);
    setFilterParams({});
  };
  const { openScanner }: ContextProps = useOutletContext();

  const useSearchListQueriesResult = useSearchListQueries();
  const {
    getSitesLoadOptions,
    getAssetsLoadOptions,
    getSupervisorLoadOptions,
    getAssigneeLoadOptions,
    getInterventionCategoriesLoadOptions,
  } = useSearchListQueriesResult;

  const defaultValues =
    filterParams && Object.keys(filterParams).length > 0 ? filterParams : {};

  const filterCounter = Object.values(defaultValues).filter(
    (value) => !_isEmpty(value),
  ).length;

  const fields = useFilterFields({
    getAssetsLoadOptions,
    getAssigneeLoadOptions,
    getInterventionCategoriesLoadOptions,
    getSitesLoadOptions,
    getSupervisorLoadOptions,
    t,
  });

  const onSubmit = (values: Values) => {
    setFilterParams(values);
    closeFilter();
  };

  const onOpenScanner = () => openScanner(ScannerActionType.FindInterventions);

  const createBtn =
    width > 374 ? (
      <Button
        buttonType={ButtonTypes.primaryFilled}
        buttonSize={ButtonSizes.large}
        buttonText={t('create')}
        leftIcon="add"
        onClick={onCreateIntervention}
      />
    ) : (
      <IconButton
        type={IconButtonTypes.PrimaryFilled}
        icon="add"
        onClick={onCreateIntervention}
      />
    );

  return (
    <>
      <div className={styles.filterWrap}>
        <div className={styles.filter}>
          <div className={styles.filterRow}>
            <div>
              <IconButton
                icon="qr_code"
                type={IconButtonTypes.PrimaryFilled}
                onClick={onOpenScanner}
              />
            </div>
            <div>
              <Chip
                counter={filterCounter}
                label={t('filter')}
                leftIcon="filter_alt"
                leftIconSymbolsOutlined
                onClick={openFilter}
                size={ChipSizes.large}
                type={ChipTypes.chipFilter}
                tooltipForCroppedTextDisabled
              />
            </div>
            {!!filterCounter && (
              <div className={styles.clearBtnCol}>
                <IconButton
                  type={IconButtonTypes.TertiaryFilled}
                  icon="cancel"
                  onClick={onResetFilter}
                />
              </div>
            )}
          </div>
          <div className={styles.toolsRow}>
            {isViewOnly ? <ViewOnlyChip /> : createBtn}
          </div>
        </div>
      </div>
      <Modal
        isOpen={isFilterOpened}
        caption={t('filter')}
        onClose={closeFilter}
        modalContentClassName={styles.modalContentClassName}
      >
        <Form
          fields={fields}
          onSubmit={onSubmit}
          defaultValues={defaultValues}
          actionsComponent={InterventionListFilterActions}
          actionsComponentProps={{
            onCancel: clearFilter,
          }}
          formClassName={formStyles.form}
        />
      </Modal>
    </>
  );
}
