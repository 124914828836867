import React from 'react';
import styles from './TabsWrap.module.scss';

interface Props {
  children: React.ReactNode;
}

export default function TabsWrap({ children }: Props) {
  return (
    <div className={styles.tabsWrap}>
      <div className={styles.tabsScroll}>{children}</div>
    </div>
  );
}
