import { useEffect } from 'react';
import usePaginationParams from '@lib/hooks/usePaginationParams';
import useSearchParam from '@lib/hooks/useSearchParam';
import useAppTasksQueryHook from 'utils/fetch/useAppTasksQueryHook';
import useTasksIndexedDB from 'hooks/useTasksIndexedDB';
import { CustomTaskScopeNameEnum } from 'constants/enums';
import useIsOnline from '@lib/hooks/useIsOnline';
import usePrevious from '@lib/hooks/usePrevious';
import useOutletContext, { ContextProps } from 'hooks/useOutletContext';
import { useLocalStorage } from 'usehooks-ts';
import { TASK_FILTER_FORM } from '@lib/enums/localStorageKeys';

type Props = {
  tabParam?: string;
};

export default function useActions({ tabParam }: Props) {
  const [searchQuery] = useSearchParam();
  const [paginationParams] = usePaginationParams();
  const [filterParams] = useLocalStorage(TASK_FILTER_FORM, {});
  const { tasksSyncLoading }: ContextProps = useOutletContext();
  const prevTasksSyncLoading = usePrevious(tasksSyncLoading);
  const isOnline = useIsOnline();
  const isPrevOnline = usePrevious(isOnline);

  // Tasks
  const {
    taskCounterData,
    tasks,
    tasksError,
    tasksFirstLoading,
    tasksLoading,
    totalTasksCount,
    refetchTasks,
  } = useAppTasksQueryHook({
    filterParams,
    paginationParams,
    orderingParams: paginationParams,
    searchQuery,
    tabParam,
  });
  const {
    data: downloadsData,
    totalCount: downloadsTotalCount,
    limitValue: downloadsLimitValue,
    loading: downloadsLoading,
    refetch: refetchDownloads,
  } = useTasksIndexedDB(
    {
      filterParams,
      paginationParams,
      searchQuery,
    },
    tabParam !== CustomTaskScopeNameEnum.Downloads,
  );

  useEffect(() => {
    if (
      tabParam === CustomTaskScopeNameEnum.Downloads ||
      prevTasksSyncLoading !== tasksSyncLoading
    ) {
      refetchDownloads();
    }
  }, [prevTasksSyncLoading, refetchDownloads, tabParam, tasksSyncLoading]);

  useEffect(() => {
    if (
      tabParam !== CustomTaskScopeNameEnum.Downloads &&
      !isPrevOnline &&
      isOnline
    ) {
      refetchTasks();
    }
  }, [refetchTasks, tabParam, isOnline, isPrevOnline]);

  return {
    downloadsData,
    downloadsLimitValue,
    downloadsLoading,
    downloadsTotalCount,
    taskCounterData,
    tasks,
    tasksError,
    tasksFirstLoading,
    tasksLoading,
    totalTasksCount,
  };
}
