import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Task, TaskStatusEnum } from 'graphql-common';
import _get from 'lodash/get';
import _upperFirst from 'lodash/upperFirst';
import _toLower from 'lodash/toLower';
import Collapse from '@lib/components/Collapse/Collapse';
import AppContentMaxWidth from '@lib/layouts/AppLayout/AppContentMaxWidth/AppContentMaxWidth';
import PageBody from '@lib/layouts/DashboardLayout/PageBody/PageBody';
import CircleLoader from '@lib/components/CircleLoader/CircleLoader';
import { ApolloError } from '@apollo/client/errors';
import Typography from '@lib/components/Typography/Typography';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import Chip, { ChipTypes } from '@lib/components/Chip/Chip';
import { getFormattedDate } from '@lib/utils/dateTimeHelpers';
import AssetPreview from '@lib/components/AssetPreview/AssetPreview';
import ErrorPage from '@lib/components/ErrorPage/ErrorPage';
import isMobile from '@lib/utils/isMobile';
import CountMark, {
  CountMarkSizes,
  CountMarkTypes,
} from '@lib/components/CountMark/CountMark';
import useWindowDimensions from '@lib/hooks/useWindowDimensions';
import getStatusFromError from '@lib/utils/getStatusFromError';
import useIsOnline from '@lib/hooks/useIsOnline';
import { getPriorityChipType } from '@lib/utils/priority';
import TaskScopeChip from 'components/TaskScopeChip/TaskScopeChip';
import TaskViewAction from 'components/Task/TaskViewAction';
import styles from '@lib/assets/styles/EntityView.module.scss';
import SyncLabel from '@lib/components/SyncLabel/SyncLabel';
import TopNavigationHeader from '@lib/layouts/AppLayout/TopNavigationHeader/TopNavigationHeader';
import { APP_URLS } from 'constants/urls';
import TextEditor from '@lib/components/TextEditor/TextEditor';

interface Props {
  isFormAvailable?: boolean;
  isFormDisabled?: boolean;
  isSynced?: boolean;
  taskData: Task | null;
  taskError?: ApolloError;
  taskLoading?: boolean;
  isTaskTimeoutError?: boolean;
}

function TaskView(props: Props) {
  const {
    isFormAvailable,
    isFormDisabled,
    isSynced,
    taskData,
    taskError,
    taskLoading = false,
    isTaskTimeoutError,
  } = props;
  const isOnline = useIsOnline();
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const { phone, tablet } = isMobile();
  const [isBlockedUI, setBlockedUI] = useState(!(phone || tablet));
  const {
    id = '',
    asset,
    lastDeadlineAt,
    completedAt,
    deadlineAt,
    description,
    formTemplate,
    lateFor,
    name,
    priority,
    site,
    siteArea,
    status,
  } = taskData || {};
  const categoryName = _get(formTemplate, 'category.name');
  const categoryColor = _get(formTemplate, 'category.color');

  const isOffline = siteArea?.poorWifi || asset?.poorWifi;

  useEffect(() => {
    setBlockedUI(!(phone || tablet));
  }, [phone, tablet, width]);

  if (taskError && isOnline && taskLoading) {
    return (
      <ErrorPage
        status={getStatusFromError(taskError)}
        description={taskError?.message}
      />
    );
  }

  if (taskData === null && (!isOnline || isTaskTimeoutError) && !taskLoading) {
    return <ErrorPage status={503} />;
  }
  if (taskData === null) return null;

  return (
    <>
      <TopNavigationHeader
        title={t('task-details')}
        backUrl={APP_URLS.app.tasks.index.getPathWithQuery()}
        predictorFnUsePrevUrl={({ prevUrl }) => {
          return (
            prevUrl !==
              APP_URLS.app.tasks.completionView.getDynamicPath({
                pathParts: { id },
              }) &&
            prevUrl !==
              APP_URLS.app.tasks.completionCreate.getDynamicPath({
                pathParts: { id },
              })
          );
        }}
      />
      <PageBody withoutHeader>
        <div>
          {taskLoading ? (
            <CircleLoader />
          ) : (
            <AppContentMaxWidth>
              {categoryColor && categoryName && (
                <div className={styles.topHeader}>
                  <Chip
                    type={categoryColor}
                    label={categoryName}
                    leftIcon="circle"
                  />
                  {isOffline && status !== TaskStatusEnum.Completed && (
                    <SyncLabel isSynced={!!isSynced} />
                  )}
                </div>
              )}
              <div className={styles.header}>
                <Collapse minHeight={120} useCropBlurWhite>
                  <Typography variant="h2" className={styles.title}>
                    {name}
                  </Typography>
                </Collapse>
              </div>
              <div className={styles.infoList}>
                <div className={styles.infoItem}>
                  <p className={styles.infoItemLabel}>
                    <MaterialIcon icon="segment" symbolsOutlined />
                    <Typography variant="caption">{t('priority')}</Typography>
                  </p>
                  <div className={styles.infoItemContent}>
                    {priority ? (
                      <Chip
                        type={getPriorityChipType(priority)}
                        label={_upperFirst(_toLower(priority))}
                        leftIcon="circle"
                      />
                    ) : (
                      <Typography variant="body">-</Typography>
                    )}
                  </div>
                </div>
                <div className={styles.infoItem}>
                  <p className={styles.infoItemLabel}>
                    <MaterialIcon icon="clock_loader_20" symbolsOutlined />
                    <Typography variant="caption">{t('status')}</Typography>
                  </p>
                  <div className={styles.infoItemContent}>
                    {status ? (
                      <TaskScopeChip task={taskData} />
                    ) : (
                      <Typography variant="body">-</Typography>
                    )}
                  </div>
                </div>
                <div className={styles.infoItem}>
                  <p className={styles.infoItemLabel}>
                    <MaterialIcon icon="home_pin" symbolsOutlined />
                    <Typography variant="caption">
                      {t('site-comma-area')}
                    </Typography>
                  </p>
                  <div
                    className={classNames(
                      styles.infoItemContent,
                      styles.infoItemContentRow,
                    )}
                  >
                    {site ? (
                      <Chip
                        type={ChipTypes.chipGray}
                        label={_get(site, 'name')}
                      />
                    ) : (
                      <Typography variant="body">-</Typography>
                    )}
                    {siteArea ? (
                      <Chip
                        type={ChipTypes.chipGray}
                        label={_get(siteArea, 'name')}
                      />
                    ) : (
                      <Typography variant="body">-</Typography>
                    )}
                  </div>
                </div>
                <div className={styles.infoItem}>
                  <p className={styles.infoItemLabel}>
                    <MaterialIcon icon="history" symbolsOutlined />
                    <Typography variant="caption">
                      {t('last-task-order')}
                    </Typography>
                  </p>
                  <div className={styles.infoItemContent}>
                    <Typography variant="body">
                      {lastDeadlineAt
                        ? getFormattedDate(lastDeadlineAt as string)
                        : '-'}
                    </Typography>
                  </div>
                </div>
                {status === TaskStatusEnum.Completed && (
                  <div className={styles.infoItem}>
                    <p className={styles.infoItemLabel}>
                      <MaterialIcon
                        icon="assignment_turned_in"
                        symbolsOutlined
                      />
                      <Typography variant="caption">
                        {t('completion-date')}
                      </Typography>
                    </p>
                    <div className={styles.infoItemContent}>
                      <Typography variant="body">
                        {completedAt
                          ? getFormattedDate(completedAt as string)
                          : '-'}
                      </Typography>
                    </div>
                  </div>
                )}
                {status !== TaskStatusEnum.Completed &&
                  status !== TaskStatusEnum.Archived && (
                    <div className={styles.infoItem}>
                      <p className={styles.infoItemLabel}>
                        <MaterialIcon icon="update" symbolsOutlined />
                        <Typography variant="caption">
                          {t('next-task-order')}
                        </Typography>
                      </p>
                      <div className={styles.infoItemContent}>
                        <Typography variant="body">
                          {deadlineAt
                            ? getFormattedDate(deadlineAt as string)
                            : '-'}
                        </Typography>
                      </div>
                    </div>
                  )}
                {lateFor && (
                  <div className={styles.infoItem}>
                    <p className={styles.infoItemLabel}>
                      <MaterialIcon icon="clock_loader_20" symbolsOutlined />
                      <Typography variant="caption">{t('late-for')}</Typography>
                    </p>
                    <div className={styles.infoItemContent}>
                      <CountMark
                        value={`${lateFor} ${t('day-short')}`}
                        type={CountMarkTypes.red}
                        size={CountMarkSizes.large}
                      />
                    </div>
                  </div>
                )}
                <div className={styles.infoItem}>
                  <p className={styles.infoItemLabel}>
                    <MaterialIcon icon="inventory" symbolsOutlined />
                    <Typography variant="caption">{t('asset')}</Typography>
                  </p>
                  <div className={styles.infoItemContent}>
                    {asset ? (
                      <AssetPreview
                        name={asset.name}
                        code={asset?.code}
                        icon={
                          asset.iconAttached?.urls?.small ||
                          asset.iconAttached?.urls?.original ||
                          asset.iconAttached?.fileTag
                        }
                      />
                    ) : (
                      <Typography variant="body">-</Typography>
                    )}
                  </div>
                </div>
                {!!description && (
                  <>
                    <div className={styles.infoItem}>
                      <p className={styles.infoItemLabel}>
                        <MaterialIcon icon="description" symbolsOutlined />
                        <Typography variant="caption">
                          {t('description')}
                        </Typography>
                      </p>
                    </div>
                    <TextEditor
                      readOnly
                      value={description}
                      className={styles.description}
                    />
                  </>
                )}
              </div>
              <div>
                <TaskViewAction
                  disabled={isBlockedUI}
                  isFormDisabled={isFormDisabled}
                  isFormAvailable={isFormAvailable}
                  taskData={taskData}
                />
              </div>
            </AppContentMaxWidth>
          )}
        </div>
      </PageBody>
    </>
  );
}

export default TaskView;
