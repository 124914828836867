import React from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { hotjar } from 'react-hotjar';
import { init as initFullStory } from '@fullstory/browser';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isoWeek from 'dayjs/plugin/isoWeek';
import 'dayjs/locale/en-gb';
import { TIMEOUT_ERROR } from '@lib/constants/fetch';
import '@lib/assets/styles/reset.css';
import i18next from './locales/locales';
import App from './routes';
import reportWebVitals from './reportWebVitals';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(isoWeek);
dayjs.extend(customParseFormat);
dayjs.locale('en-gb');

const {
  MODE,
  VITE_API_URL,
  VITE_RELEASE_VERSION,
  VITE_SENTRY_DSN,
  VITE_SENTRY_TOKEN,
  VITE_SYSTEM_PLATFORM_URL,
  VITE_HOTJAR_ID,
  VITE_FULLSTORY_ID,
  // @ts-ignore
} = import.meta.env;
const PLATFORM_URL = VITE_SYSTEM_PLATFORM_URL as string;
const API_URL = VITE_API_URL as string;

if (MODE !== 'development') {
  Sentry.init({
    dsn: VITE_SENTRY_DSN,
    release: VITE_RELEASE_VERSION,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    ignoreErrors: [
      'Failed to fetch',
      'TypeError: Failed to fetch',
      'TypeError: NetworkError when attempting to fetch resource.',
      'Response not successful: Received status code 503',
      TIMEOUT_ERROR,
    ],
    beforeSend(event, hint) {
      if (event.exception) {
        const errorMessage = event.exception?.values?.[0]?.value;
        const errorFile =
          event.exception?.values?.[0]?.stacktrace?.frames?.[0]?.filename;
        event.contexts = {
          ...event.contexts,
          customContext: {
            errorMessage,
            errorFile,
          },
        };
        event.fingerprint = ['{{ default }}', errorMessage, errorFile];
        hint.originalException = event.exception;
      }
      event.extra = {
        ...event.extra,
        'X-Sentry-Token': VITE_SENTRY_TOKEN,
        hint: JSON.stringify(hint),
      };
      return event;
    },
    tracesSampleRate: 0.3,
    tracePropagationTargets: [PLATFORM_URL, API_URL],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });

  if (VITE_HOTJAR_ID) hotjar.initialize({ id: VITE_HOTJAR_ID, sv: 6 });

  if (VITE_FULLSTORY_ID) initFullStory({ orgId: VITE_FULLSTORY_ID });
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </React.StrictMode>,
);

window.addEventListener('beforeinstallprompt', (event) => {
  event.preventDefault();
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
