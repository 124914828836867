import {
  FilterGroupingEnum,
  Intervention,
  InterventionFilterInputObject,
  InterventionScopeNameEnum,
  InterventionsQueryVariables,
  useInterventionsIdsLazyQuery,
  useInterventionsQuery,
} from 'graphql-common';
import _get from 'lodash/get';
import { GetUrlParams } from '@lib/enums/urls';
import createSearchFilters from '@lib/utils/createSearchFilters';
import { ApolloError } from '@apollo/client/errors';
import { FetchIdsResponse } from '@lib/utils/getAllTableItemsIds';
import TasksFilterFieldNames from '@lib/enums/fieldNames/tasksFilterFieldNames';
import InterventionsFilterFieldNames from '@lib/enums/fieldNames/interventionsFilterFieldNames';
import { addFilter, parseFilterValues } from 'utils/fetch/helpers';
import { ListQueryHookProps } from './types';

const FILTER_MAP = [
  {
    key: 'priority',
    paramName: InterventionsFilterFieldNames.Priority,
  },
  { key: 'siteId', paramName: InterventionsFilterFieldNames.Site },
  { key: 'siteAreaId', paramName: InterventionsFilterFieldNames.SiteArea },
  { key: 'assetId', paramName: InterventionsFilterFieldNames.Asset },
  {
    key: 'assigneeUsersId',
    paramName: InterventionsFilterFieldNames.Assignees,
  },
  { key: 'createdById', paramName: InterventionsFilterFieldNames.CreatedBy },
  {
    key: 'categoryId',
    paramName: InterventionsFilterFieldNames.InterventionCategory,
  },
];

export type UseAssetInterventionsQueryHookResult = {
  collection: Intervention[];
  error: ApolloError | undefined;
  fetchIds: () => Promise<FetchIdsResponse<unknown>>;
  firstLoading: boolean;
  loading: boolean;
  refetch: () => Promise<unknown>;
  totalCount: number;
  totalPages: number;
  counters: {
    closed: number;
    opened: number;
    openedMyParticipation: number;
  };
};

type Args = ListQueryHookProps & {
  scopeName: InterventionScopeNameEnum;
  assetId?: string;
};

const createFilters = (
  filterParams: any,
  searchQuery?: string,
  assetId?: string,
): InterventionFilterInputObject[] => {
  const filters: InterventionFilterInputObject[] = [];

  if (searchQuery) {
    filters.push(
      ...createSearchFilters(searchQuery, [
        { fieldName: 'name' },
        { fieldName: 'code' },
        { fieldName: 'assetName' },
        { fieldName: 'assetCode' },
        { fieldName: 'siteCode' },
        { fieldName: 'siteName' },
        { fieldName: 'siteAreaName' },
        { fieldName: 'siteAreaCode' },
        { fieldName: 'priority', predicateType: 'eq' },
      ]),
    );
  }

  if (assetId) {
    filters.push({
      assetId: {
        grouping: FilterGroupingEnum.And,
        predicate: { eq: assetId },
        filterGroup: 'assetFilter',
      },
    });
  }

  const periodValue = _get(
    filterParams,
    TasksFilterFieldNames.Period,
    [],
  ) as string[];
  const periodStart = periodValue[0];
  const periodEnd = periodValue[1];
  if (periodStart) {
    filters.push({
      createdAt: {
        grouping: FilterGroupingEnum.And,
        predicate: { gteq: periodStart },
      },
    });
  }
  if (periodEnd) {
    filters.push({
      createdAt: {
        grouping: FilterGroupingEnum.And,
        predicate: { lteq: periodEnd },
      },
    });
  }

  FILTER_MAP.forEach((item) => {
    const { key, paramName } = item;
    const values = parseFilterValues(_get(filterParams, paramName));
    addFilter(filters, key, values);
  });

  return filters;
};

export default function useInterventionsQueryHook({
  fetchPolicy = 'no-cache',
  paginationParams,
  orderingParams,
  filterParams,
  searchQuery,
  skip,
  usePrevious = true,
  scopeName,
  assetId,
}: Args): UseAssetInterventionsQueryHookResult {
  const page = Number(_get(paginationParams, GetUrlParams.Page));
  const perPage = Number(_get(paginationParams, GetUrlParams.PerPage));
  const orderingField = _get(orderingParams, GetUrlParams.OrderingField);
  const orderingDirection = _get(
    orderingParams,
    GetUrlParams.OrderingDirection,
  );

  const queryVariables: InterventionsQueryVariables = {
    page,
    limit: perPage,
    scopeName,
    sorts:
      orderingField && orderingDirection
        ? { [orderingField]: orderingDirection }
        : undefined,
    filters: createFilters(filterParams, searchQuery, assetId),
  };

  const query = useInterventionsQuery({
    fetchPolicy,
    variables: queryVariables,
    skip,
  });
  const [fetchIds] = useInterventionsIdsLazyQuery({
    fetchPolicy: 'no-cache',
    variables: { scopeName, onlyIds: true, filters: queryVariables.filters },
  });

  const { loading, data, previousData, refetch, error } = query;
  const queryData = usePrevious ? data || previousData : data;
  const firstLoading = loading && previousData === undefined;

  const collection = _get(queryData, 'data.collection', []) as Intervention[];
  const metadata = _get(queryData, 'data.metadata');
  const totalCount = _get(metadata, 'totalCount', 0);
  const totalPages = _get(metadata, 'totalPages', 1);

  const counters = {
    closed: _get(queryData, ['closedCounter', 'metadata', 'totalCount'], 0),
    opened: _get(queryData, ['openedCounter', 'metadata', 'totalCount'], 0),
    openedMyParticipation: _get(
      queryData,
      ['openedMyParticipationCounter', 'metadata', 'totalCount'],
      0,
    ),
  };

  return {
    fetchIds,
    refetch,
    collection,
    error,
    firstLoading,
    loading,
    totalPages,
    totalCount,
    counters,
  };
}
