import { RouteBuilder } from '@lib/utils/routeBuilder';
import {
  DEFAULT_LIST_PARAMS,
  GetUrlParams,
  ORDERING_DIRECTION_ASC,
  PAGINATION_PARAMS,
  PATH_ACTION_ID,
  PATH_ACTIONS,
  PATH_ADD,
  PATH_AUTH,
  PATH_EDIT,
  PATH_ID,
  PATH_INTERVENTIONS,
  PATH_PERFORM_TASKS,
  PATH_SETUP,
  PATH_VIEW,
} from '@lib/enums/urls';
import { InterventionScopeNameEnum } from 'graphql-common';

export const APP_URLS = {
  root: new RouteBuilder({
    path: '/',
  }),
  auth: {
    index: new RouteBuilder({
      path: `/${PATH_AUTH}`,
    }),
    login: new RouteBuilder({
      path: `/${PATH_AUTH}/login`,
    }),
    forgotPassword: new RouteBuilder({
      path: `/${PATH_AUTH}/forgot-password`,
    }),
    resetPassword: new RouteBuilder({
      path: `/${PATH_AUTH}/reset-password`,
    }),
    logout: new RouteBuilder({
      path: `/${PATH_AUTH}/logout`,
    }),
  },
  setup: {
    index: new RouteBuilder({
      path: `/${PATH_SETUP}`,
    }),
    finishRegistration: new RouteBuilder({
      path: `/${PATH_SETUP}/finish-registration`,
    }),
    confirmEmail: new RouteBuilder({
      path: `/${PATH_SETUP}/confirm-email`,
    }),
    updatePassword: new RouteBuilder({
      path: `/${PATH_SETUP}/update-password`,
    }),
  },
  app: {
    index: new RouteBuilder({
      path: `/`,
    }),
    profile: new RouteBuilder({
      path: `/profile`,
    }),
    tasks: {
      index: new RouteBuilder({
        path: `/${PATH_PERFORM_TASKS}`,
        defaultParams: {
          ...PAGINATION_PARAMS,
          [GetUrlParams.OrderingField]: 'deadlineAt',
          [GetUrlParams.OrderingDirection]: ORDERING_DIRECTION_ASC,
        },
      }),
      view: new RouteBuilder({
        path: `/${PATH_PERFORM_TASKS}/${PATH_ID}/${PATH_VIEW}`,
      }),
      completionCreate: new RouteBuilder({
        path: `/${PATH_PERFORM_TASKS}/${PATH_ID}/completion-${PATH_ADD}`,
      }),
      completionView: new RouteBuilder({
        path: `/${PATH_PERFORM_TASKS}/${PATH_ID}/completion-${PATH_VIEW}`,
      }),
      scanner: new RouteBuilder({
        path: `/${PATH_PERFORM_TASKS}/${PATH_ID}/scanner`,
      }),
    },
    interventions: {
      index: new RouteBuilder({
        path: `/${PATH_INTERVENTIONS}`,
        defaultParams: {
          ...DEFAULT_LIST_PARAMS,
          tab: InterventionScopeNameEnum.OpenedMyParticipation,
        },
      }),
      add: new RouteBuilder({
        path: `/${PATH_INTERVENTIONS}/${PATH_ADD}`,
      }),
      edit: new RouteBuilder({
        path: `/${PATH_INTERVENTIONS}/${PATH_ID}/${PATH_EDIT}`,
      }),
      view: new RouteBuilder({
        path: `/${PATH_INTERVENTIONS}/${PATH_ID}/${PATH_VIEW}`,
      }),
      viewActions: new RouteBuilder({
        path: `/${PATH_INTERVENTIONS}/${PATH_ID}/${PATH_VIEW}-${PATH_ACTIONS}`,
        defaultParams: {
          ...DEFAULT_LIST_PARAMS,
        },
      }),
      actionAdd: new RouteBuilder({
        path: `/${PATH_INTERVENTIONS}/${PATH_ID}/${PATH_ACTIONS}/${PATH_ADD}`,
      }),
      actionEdit: new RouteBuilder({
        path: `/${PATH_INTERVENTIONS}/${PATH_ID}/${PATH_ACTIONS}/${PATH_ACTION_ID}/${PATH_EDIT}`,
      }),
      actionView: new RouteBuilder({
        path: `/${PATH_INTERVENTIONS}/${PATH_ID}/${PATH_ACTIONS}/${PATH_ACTION_ID}/${PATH_VIEW}`,
      }),
    },
    notFound: new RouteBuilder({
      path: `/not-found`,
    }),
    unauthorized: new RouteBuilder({
      path: `/unauthorized`,
    }),
    accessDenied: new RouteBuilder({
      path: `/access-denied`,
    }),
  },
  notFound: new RouteBuilder({
    path: '/not-found',
  }),
};
