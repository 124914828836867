import _get from 'lodash/get';
import { InputTelValue } from '@lib/components/Input/InputTel';
import { Values } from '@lib/interfaces/form';

export default function getDefaultValuesFromQueryData(data?: Values): Values {
  return {
    ...data,
    phoneNumber: {
      number: _get(data, 'phoneNumber.international'),
      country: _get(data, 'phoneNumber.country'),
    } as InputTelValue,
  };
}
