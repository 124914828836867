import _get from 'lodash/get';
import {
  ComponentEnum,
  LevelEnum,
  TaskScopeNameEnum,
  useAssetsSearchListLazyQuery,
  useInterventionCategoriesSearchListLazyQuery,
  useSiteAreasSearchListLazyQuery,
  useSitesSearchListLazyQuery,
  useTasksSearchListLazyQuery,
  useUsersSearchListLazyQuery,
} from 'graphql-common';
import { GetLoadOptions } from '@lib/components/Select/types';
import FieldNames from '@lib/enums/fieldNames/interventionFieldNames';
import { getInterventionCategoriesLoadOptions as getInterventionCategoriesLoadOptionsFn } from 'utils/fetch/interventionCategoriesSearchList';
import { getSitesLoadOptions as getSitesLoadOptionsFn } from 'utils/fetch/sitesSearchList';
import { getUsersLoadOptions as getUsersLoadOptionsFn } from 'utils/fetch/usersSearchList';
import { getTasksLoadOptions as getTasksLoadOptionsFn } from 'utils/fetch/tasksSearchList';
import { getAssetsLoadOptions as getAssetsLoadOptionsFn } from 'utils/fetch/interventionAssetsSearchList';
import { getSiteAreasLoadOptions as getSiteAreasLoadOptionsFn } from 'utils/fetch/siteAreasSearchList';

export default function useSearchListQueries() {
  // Intervention categories
  const [fetchInterventionCategoriesLazyQuery] =
    useInterventionCategoriesSearchListLazyQuery({
      fetchPolicy: 'cache-and-network',
    });
  const getInterventionCategoriesLoadOptions = () =>
    getInterventionCategoriesLoadOptionsFn(
      fetchInterventionCategoriesLazyQuery,
    );

  // Sites
  const [fetchSitesSearchListLazyQuery] = useSitesSearchListLazyQuery({
    fetchPolicy: 'cache-and-network',
  });
  const getSitesLoadOptions: GetLoadOptions = () =>
    getSitesLoadOptionsFn(fetchSitesSearchListLazyQuery);

  // Assets
  const [fetchAssetsLazyQuery] = useAssetsSearchListLazyQuery({
    fetchPolicy: 'cache-and-network',
  });
  const getAssetsLoadOptions = (name, values) =>
    getAssetsLoadOptionsFn(
      fetchAssetsLazyQuery,
      _get(values, [FieldNames.Site, 'value'], '') as string,
      _get(values, [FieldNames.SiteArea, 'value'], '') as string,
    );

  // Site areas
  const [fetchSiteAreasSearchListLazyQuery] = useSiteAreasSearchListLazyQuery({
    fetchPolicy: 'network-only',
  });
  const getSiteAreasLoadOptions: GetLoadOptions = (name, values) =>
    getSiteAreasLoadOptionsFn(
      fetchSiteAreasSearchListLazyQuery,
      _get(values, [FieldNames.Site, 'value'], '') as string,
    );

  // Users
  const [fetchUsersSearchListLazyQuery] = useUsersSearchListLazyQuery({
    fetchPolicy: 'cache-and-network',
  });
  const getSupervisorLoadOptions = (name, values) =>
    getUsersLoadOptionsFn(fetchUsersSearchListLazyQuery, {
      custom: {
        byCore: {
          siteId: _get(values, [FieldNames.Site, 'value'], '') as string,
          onlyActive: true,
          access: {
            component: ComponentEnum.InterventionsManagement,
            level: LevelEnum.Write,
          },
        },
      },
    });
  const getAssigneeLoadOptions = (name, values) => {
    return getUsersLoadOptionsFn(fetchUsersSearchListLazyQuery, {
      custom: {
        byCore: {
          siteId: _get(values, [FieldNames.Site, 'value'], '') as string,
          onlyActive: true,
          access: {
            component: ComponentEnum.InterventionsManagement,
            level: LevelEnum.Read,
          },
        },
      },
    });
  };

  // Tasks
  const [fetchTasksLazyQuery] = useTasksSearchListLazyQuery({
    fetchPolicy: 'no-cache',
  });
  const getTasksLoadOptions: GetLoadOptions = (name, values) => {
    const siteAreaValue = _get(values, [
      FieldNames.SiteArea,
      'value',
    ]) as string;
    const selectedSiteAreaIds = siteAreaValue ? [siteAreaValue] : [];
    const assetIdsValue = _get(
      values,
      [FieldNames.Asset, 'value'],
      '',
    ) as string;
    const selectedAssetIds = assetIdsValue ? [assetIdsValue] : [];
    return getTasksLoadOptionsFn({
      fetchLazyQuery: fetchTasksLazyQuery,
      scope: {
        name: TaskScopeNameEnum.NonSuccessWithoutIntervention,
      },
      selectedSiteIds: [_get(values, [FieldNames.Site, 'value'], '') as string],
      selectedSiteAreaIds,
      selectedAssetIds,
    });
  };

  return {
    getSitesLoadOptions,
    getAssetsLoadOptions,
    getSiteAreasLoadOptions,
    getSupervisorLoadOptions,
    getAssigneeLoadOptions,
    getTasksLoadOptions,
    getInterventionCategoriesLoadOptions,
  };
}
