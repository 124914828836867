import React from 'react';
import { useTranslation } from 'react-i18next';
import { Task, TaskStatusEnum } from 'graphql-common';
import Button, {
  ButtonSizes,
  ButtonTypes,
} from '@lib/components/Button/Button';
import { APP_URLS } from 'constants/urls';
import useOutletContext, { ContextProps } from 'hooks/useOutletContext';
import { ScannerActionType } from 'routes/Scanner/enums';

interface Props {
  disabled?: boolean;
  isFormAvailable?: boolean;
  isFormDisabled?: boolean;
  taskData: Task;
}

function TaskViewAction(props: Props) {
  const { disabled, isFormAvailable, isFormDisabled, taskData } = props;
  const { t } = useTranslation();
  const { id, status } = taskData || {};

  const { openScanner }: ContextProps = useOutletContext();
  const onOpenScanner = () => openScanner(ScannerActionType.FindTasks);

  if (status === TaskStatusEnum.Completed) {
    return (
      <Button
        buttonSize={ButtonSizes.large}
        buttonText={t('view-form')}
        buttonType={ButtonTypes.primaryFilled}
        fullWidth
        rightIcon="arrow_forward"
        to={APP_URLS.app.tasks.completionView.getDynamicPath({
          pathParts: { id },
        })}
      />
    );
  }

  if (isFormAvailable && isFormDisabled) {
    return isFormDisabled ? (
      <Button
        buttonSize={ButtonSizes.large}
        buttonText={t('view-form')}
        buttonType={ButtonTypes.primaryOutlined}
        fullWidth
        rightIcon="arrow_forward"
        to={APP_URLS.app.tasks.completionCreate.getDynamicPath({
          pathParts: { id },
        })}
        disabled={disabled}
      />
    ) : (
      <Button
        buttonSize={ButtonSizes.large}
        buttonText={t('fill-form')}
        buttonType={ButtonTypes.primaryFilled}
        fullWidth
        rightIcon="arrow_forward"
        to={APP_URLS.app.tasks.completionCreate.getDynamicPath({
          pathParts: { id },
        })}
        disabled={disabled}
      />
    );
  }

  if (isFormAvailable && !isFormDisabled) {
    return isFormDisabled ? (
      <Button
        buttonSize={ButtonSizes.large}
        buttonText={t('view-form')}
        buttonType={ButtonTypes.primaryOutlined}
        fullWidth
        rightIcon="arrow_forward"
        to={APP_URLS.app.tasks.completionCreate.getDynamicPath({
          pathParts: { id },
        })}
        disabled={disabled}
      />
    ) : (
      <Button
        buttonSize={ButtonSizes.large}
        buttonText={t('fill-form')}
        buttonType={ButtonTypes.primaryFilled}
        fullWidth
        rightIcon="arrow_forward"
        to={APP_URLS.app.tasks.completionCreate.getDynamicPath({
          pathParts: { id },
        })}
        disabled={disabled}
      />
    );
  }

  return (
    <Button
      buttonSize={ButtonSizes.large}
      buttonText={t('scan-area-qr-code-to-perform')}
      buttonType={ButtonTypes.primaryFilled}
      fullWidth
      rightIcon="arrow_forward"
      onClick={() => onOpenScanner()}
      disabled={disabled}
    />
  );
}

export default TaskViewAction;
