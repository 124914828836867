import { FormAnswer, FormTemplateQuestionFieldOption } from 'graphql-common';
import { TFunction } from 'i18next';

export function getResponseFormattedValue(
  questionAnswer: FormAnswer | undefined,
  t: TFunction<'translation', undefined>,
) {
  const resp = questionAnswer?.resp.map(({ value }) => value).sort();
  if (resp?.length === 1 && resp[0]) {
    return `${resp[0]}`;
  }
  if (resp?.length === 2 && resp[0] && resp[1]) {
    return `${t('min').toLowerCase()} ${resp[0]}; ${t('max').toLowerCase()} ${resp[1]}`;
  }
  return undefined;
}

export function getFieldOptions(options: FormTemplateQuestionFieldOption[]) {
  return [...options]
    .sort((a, b) => a.position - b.position)
    .map(({ values, group, id }) => ({
      value: values?.[0],
      label: values?.[0],
      group,
      id,
    }));
}
