import React from 'react';
import useTabParam from '@lib/hooks/useTabParam';
import { ComponentEnum, TaskScopeNameEnum } from 'graphql-common';
import { GetUrlParams, ORDERING_DIRECTION_ASC } from '@lib/enums/urls';
import usePrevious from '@lib/hooks/usePrevious';
import { TaskScopeNameEnumExtended } from 'constants/enums';
import Tasks from './Tasks';
import useActions from './utils/useActions';

function TasksContainer() {
  const [tabParam, setTabParam] = useTabParam(
    TaskScopeNameEnum.MyTodoAvailableToComplete,
    {
      [GetUrlParams.OrderingField]: 'deadlineAt',
      [GetUrlParams.OrderingDirection]: ORDERING_DIRECTION_ASC,
    },
  );
  const prevTabParam = usePrevious(tabParam);

  const {
    downloadsData,
    downloadsLimitValue,
    downloadsLoading,
    downloadsTotalCount,
    taskCounterData,
    tasks,
    tasksError,
    tasksFirstLoading,
    tasksLoading,
    totalTasksCount,
  } = useActions({ tabParam });

  return (
    <Tasks
      downloadsData={downloadsData}
      downloadsLimitValue={downloadsLimitValue}
      downloadsLoading={downloadsLoading}
      downloadsTotalCount={downloadsTotalCount}
      onTabParamChange={setTabParam}
      tabParam={tabParam as TaskScopeNameEnumExtended}
      prevTabParam={prevTabParam as TaskScopeNameEnumExtended}
      taskCounterData={taskCounterData}
      tasks={tasks}
      tasksError={tasksError}
      tasksFirstLoading={tasksFirstLoading}
      tasksLoading={tasksLoading}
      totalEntries={totalTasksCount}
    />
  );
}

TasksContainer.displayName = `${ComponentEnum.TasksPerforming}-TasksContainer`;

export default TasksContainer;
