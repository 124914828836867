import * as Sentry from '@sentry/react';
import _debounce from 'lodash/debounce';

const debouncedCaptureException = _debounce(
  (error: Error | unknown) => Sentry.captureException(error),
  2000,
  { leading: true, trailing: false },
);

export function captureException(error: Error | unknown, response?: unknown) {
  // eslint-disable-next-line no-console
  if (response) console.warn('captureException: ', response);
  debouncedCaptureException(error);
}
