import React from 'react';
import {
  components as DefaultSelectComponents,
  ContainerProps,
} from 'react-select';
import IconButton, {
  IconButtonProps,
  IconButtonTypes,
} from '@lib/components/IconButton/IconButton';
import styles from '../Select.module.scss';

export default function IconButtonSelectContainer({
  children,
  iconButtonProps,
  ...props
}: ContainerProps & { iconButtonProps: IconButtonProps }) {
  return (
    <div className={styles.iconButtonSelectContainer}>
      <div>
        <IconButton
          {...iconButtonProps}
          type={
            props?.selectProps?.menuIsOpen
              ? IconButtonTypes.SecondaryInvert
              : IconButtonTypes.TertiaryInvert
          }
        />
      </div>
      <div>
        <DefaultSelectComponents.SelectContainer {...props}>
          {children}
        </DefaultSelectComponents.SelectContainer>
      </div>
    </div>
  );
}
