import { TaskScopeNameEnum } from 'graphql-common';
import _get from 'lodash/get';
import { TFunction } from 'i18next';
import { TaskCounter } from '@lib/interfaces/task';
import { CustomTaskScopeNameEnum } from 'constants/enums';

type Args = {
  downloadsTotalCount: number;
  t: TFunction<'translation', undefined>;
  taskCounterData?: TaskCounter;
};

export default function getTableTabs({
  downloadsTotalCount,
  t,
  taskCounterData,
}: Args) {
  return [
    {
      label: t('to-do'),
      value: TaskScopeNameEnum.MyTodoAvailableToComplete,
      counter: _get(taskCounterData, 'myTodoAvailableToComplete'),
    },
    {
      label: t('upcoming'),
      value: TaskScopeNameEnum.MyTodoUpcoming,
      counter: _get(taskCounterData, 'myTodoUpcoming'),
    },
    {
      label: t('completed'),
      value: TaskScopeNameEnum.MyCompleted,
      counter: _get(taskCounterData, 'myCompleted'),
    },
    {
      label: t('downloads'),
      value: CustomTaskScopeNameEnum.Downloads,
      counter: downloadsTotalCount,
    },
  ];
}
