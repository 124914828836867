import { TFunction } from 'i18next';
import _without from 'lodash/without';
import {
  ComponentEnum,
  InterventionScopeNameEnum,
  LevelEnum,
  TaskScopeNameEnum,
} from 'graphql-common';
import { NavItem, NavItemTypeEnum } from '@lib/components/Menu/Menu';
import { APP_URLS } from 'constants/urls';
import { CustomTaskScopeNameEnum } from 'constants/enums';
import Icons from '@lib/components/Icons/Icons';
import Chip, { ChipSizes, ChipTypes } from '@lib/components/Chip/Chip';
import React from 'react';

type Args = {
  closeScanner: () => void;
  getUserAccessByCompany: (component: ComponentEnum) => undefined | LevelEnum;
  getUserAccessByComponent: (component: ComponentEnum) => undefined | LevelEnum;
  isOnline: boolean;
  isScannerVisible: boolean;
  openScanner: () => void;
  pathname: string;
  t: TFunction<'translation', undefined>;
};

function getToProp(to: string, pathname: string, isScannerVisible: boolean) {
  const cleanPathname = pathname.split('?')[0];
  const cleanTo = to.split('?')[0];
  const pathPartsArray = _without(cleanPathname.split('/'), '');
  const toPartsArray = _without(cleanTo.split('/'), '');
  if (
    isScannerVisible &&
    (to.indexOf(pathname) === 0 || pathPartsArray[0] === toPartsArray[0])
  ) {
    return undefined;
  }
  return to;
}

export default function getNavItems({
  isOnline,
  t,
  openScanner,
  closeScanner,
  isScannerVisible,
  pathname,
  getUserAccessByComponent,
  getUserAccessByCompany,
}: Args): NavItem<undefined>[] {
  const isTasksAvailable =
    getUserAccessByComponent(ComponentEnum.TasksPerforming) !==
    LevelEnum.LimitedRead;
  const isInterventionsAvailable =
    getUserAccessByComponent(ComponentEnum.InterventionsManagement) !==
    LevelEnum.LimitedRead;
  const isInterventionsLocked =
    getUserAccessByCompany(ComponentEnum.InterventionsManagement) ===
    LevelEnum.LimitedRead;
  const isCountersAvailable =
    getUserAccessByComponent(ComponentEnum.AssetsCounterReading) !==
    LevelEnum.LimitedRead;
  const result: NavItem<unknown>[] = [];
  if (isTasksAvailable) {
    result.push({
      id: 'tasks',
      title: t('my-tasks'),
      leftIcon: Icons.tasks,
      to: getToProp(
        APP_URLS.app.tasks.index.getPathWithQuery({
          tab: isOnline
            ? TaskScopeNameEnum.MyTodoAvailableToComplete
            : CustomTaskScopeNameEnum.Downloads,
        }),
        pathname,
        isScannerVisible,
      ),
      onClick: isScannerVisible ? closeScanner : undefined,
      type: NavItemTypeEnum.Primary,
    });
  }
  if (isInterventionsAvailable || isInterventionsLocked) {
    result.push({
      id: 'interventions',
      title: t('interventions'),
      leftIcon: Icons.build,
      rightIcon: isInterventionsLocked
        ? (isCollapsed?: boolean) => (
            <Chip
              leftIcon="lock"
              size={isCollapsed ? ChipSizes.extraSmall : ChipSizes.small}
              type={ChipTypes.chipBlue}
            />
          )
        : undefined,
      to: getToProp(
        APP_URLS.app.interventions.index.getPathWithQuery({
          tab: InterventionScopeNameEnum.OpenedMyParticipation,
        }),
        pathname,
        isScannerVisible,
      ),
      onClick: isScannerVisible ? closeScanner : undefined,
      type: NavItemTypeEnum.Primary,
    });
  }
  if (isTasksAvailable || isInterventionsAvailable || isCountersAvailable) {
    result.push({
      id: 'scanner',
      title: t('scan-qr-code'),
      leftIcon: Icons.qrCode,
      onClick: () => openScanner(),
      type: NavItemTypeEnum.Primary,
      predictorFnActive: () => isScannerVisible,
    });
  }
  return result;
}
