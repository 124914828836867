import QrCodePrefix from '@lib/enums/qrCodePrefix';
import isValidCode from './isCodeValid';

export enum CodeType {
  Asset = 'Asset',
  Invalid = 'Invalid',
  SiteArea = 'SiteArea',
}

export default function getCodeType(code: string): CodeType {
  if (!isValidCode(code)) return CodeType.Invalid;

  if (
    code.startsWith(`${QrCodePrefix.SiteArea}-`) ||
    code.startsWith(`${QrCodePrefix.SiteArea}:`)
  ) {
    return CodeType.SiteArea;
  }
  if (
    code.startsWith(`${QrCodePrefix.Asset}-`) ||
    code.startsWith(`${QrCodePrefix.Asset}:`)
  ) {
    return CodeType.Asset;
  }

  return CodeType.Asset;
}
