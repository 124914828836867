import React from 'react';
import _get from 'lodash/get';
import {
  AppFullTaskQuery,
  Task,
  TasksMyTodoCompletableByDateDataQuery,
} from 'graphql-common';
import { LazyQueryExecFunction } from '@apollo/client';
import {
  CompletableTodoTask,
  StoredCompletion,
  SyncProcess,
  SyncStatus,
} from 'components/TaskSyncTracker/types';
import {
  getAllTasksFromDB,
  removeOutdatedTasksFromIndexedDB,
  saveToIndexedDB,
} from 'utils/indexedDBUtils';
import { captureException } from 'utils/captureException';
import { CacheNames } from 'constants/enums';
import {
  storeCompletableTodoTasks,
  storeUtils,
} from 'components/TaskSyncTracker/utils/storeUtils';
import { TIMEOUT_ERROR } from '@lib/constants/fetch';
import isErrorTimeoutError from '@lib/utils/isErrorTimeoutError';
import { fetchCompletableTasks } from './fetchCompletableTasks';

export const shouldFetchSyncTasks = ({
  completionTotalCount,
  isOnline,
  isServiceWorkerReady,
  lastTasksFetchTimeRef,
  syncStatus,
  syncTrackerSeconds,
}) => {
  if (
    completionTotalCount ||
    !isServiceWorkerReady ||
    !isOnline ||
    syncStatus === SyncStatus.idle ||
    syncStatus === SyncStatus.success ||
    syncStatus === SyncStatus.error
  ) {
    return false;
  }
  if (!lastTasksFetchTimeRef.current) return true;
  const now = new Date();
  const diff = now.getTime() - lastTasksFetchTimeRef.current.getTime();
  const seconds = syncTrackerSeconds
    ? parseInt(syncTrackerSeconds, 10) * 1000
    : 0;
  return diff >= seconds;
};

type SyncTasksArgs = {
  fetchTask: LazyQueryExecFunction<AppFullTaskQuery, any>;
  fetchTasksMyTodoCompletableByDateData: LazyQueryExecFunction<
    TasksMyTodoCompletableByDateDataQuery,
    any
  >;
  setExpectedSyncItems: (v: CompletableTodoTask[] | StoredCompletion[]) => void;
  setFailedSyncItems: (v: CompletableTodoTask[] | StoredCompletion[]) => void;
  setFetchedSyncItems: React.Dispatch<
    React.SetStateAction<CompletableTodoTask[] | StoredCompletion[]>
  >;
  setSyncStatus: React.Dispatch<React.SetStateAction<SyncStatus>>;
  syncTrackerDays: number;
  onProcessStart: (v: SyncProcess) => void;
  onProcessEnd: () => void;
};

export const syncTasks = async ({
  fetchTask,
  fetchTasksMyTodoCompletableByDateData,
  setExpectedSyncItems,
  setFailedSyncItems,
  setFetchedSyncItems,
  setSyncStatus,
  syncTrackerDays,
  onProcessStart,
  onProcessEnd,
}: SyncTasksArgs) => {
  try {
    onProcessStart(SyncProcess.tasks);
    const response = await fetchCompletableTasks(
      fetchTasksMyTodoCompletableByDateData,
      syncTrackerDays,
    );
    const { error } = response;
    const isTimeoutError = isErrorTimeoutError(error);
    const completableTodoTasks: CompletableTodoTask[] = _get(
      response,
      ['data', 'data'],
      [],
    ).map(({ id, offlineImportantDataDigest }) => ({
      id,
      offlineImportantDataDigest,
    }));

    if (isTimeoutError) {
      throw new Error(TIMEOUT_ERROR);
    }

    const localCompletableTodoTasks = storeUtils();

    let tasksToFetch: CompletableTodoTask[] = [];
    let cachedTasks: Task[] = [];

    if (!localCompletableTodoTasks || localCompletableTodoTasks.length === 0) {
      tasksToFetch = completableTodoTasks;
    } else if (localCompletableTodoTasks.length) {
      cachedTasks = await getAllTasksFromDB();
      tasksToFetch = completableTodoTasks.filter(
        (completableTodoTask: CompletableTodoTask) => {
          const localTask = localCompletableTodoTasks.find(
            (item) =>
              item.id === completableTodoTask.id &&
              cachedTasks.findIndex(
                ({ id: cachedId }) => cachedId === item.id,
              ) > -1,
          );
          return (
            !localTask ||
            localTask.offlineImportantDataDigest !==
              completableTodoTask.offlineImportantDataDigest
          );
        },
      );
    }

    // Determine outdated tasks
    const outdatedTasksIds = cachedTasks
      .filter(
        (cachedTask) =>
          !completableTodoTasks.find(
            (completableTask) => completableTask.id === cachedTask.id,
          ),
      )
      .map(({ id }) => id);

    await removeOutdatedTasksFromIndexedDB(outdatedTasksIds);

    setExpectedSyncItems(tasksToFetch);

    if (tasksToFetch.length) {
      setSyncStatus(SyncStatus.idle);
    } else {
      onProcessEnd();
    }

    const newFetchedTasks: CompletableTodoTask[] = [];
    const newFailedTasks: CompletableTodoTask[] = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const task of tasksToFetch) {
      try {
        // eslint-disable-next-line no-await-in-loop
        const taskResponse = await fetchTask({ variables: { id: task.id } });
        const taskData = _get(taskResponse, ['data', 'data']);
        if (!taskData) {
          newFailedTasks.push(task);
          setFailedSyncItems([...newFailedTasks]);
        } else {
          saveToIndexedDB(CacheNames.tasks, {
            ...taskData,
          });
          newFetchedTasks.push(task);
          setFetchedSyncItems([...newFetchedTasks]);
        }
      } catch {
        newFailedTasks.push(task);
        setFailedSyncItems([...newFailedTasks]);
      }
    }

    // All tasks processed, set status
    const hasErrors = newFailedTasks.length > 0;
    setSyncStatus(hasErrors ? SyncStatus.error : SyncStatus.success);

    storeCompletableTodoTasks(completableTodoTasks);
  } catch (error) {
    if (typeof error === 'string') {
      captureException(new Error(error));
    } else if (error instanceof Error) {
      captureException(error);
    }
    setSyncStatus(SyncStatus.error);
    throw error;
  }
};
