import React from 'react';
import { useTranslation } from 'react-i18next';
import { ComponentEnum, Intervention, LevelEnum } from 'graphql-common';
import { useTableItemsBulkSelect } from '@lib/hooks/useTableItemsBulkSelect';
import AppContentMaxWidth from '@lib/layouts/AppLayout/AppContentMaxWidth/AppContentMaxWidth';
import PageBody from '@lib/layouts/DashboardLayout/PageBody/PageBody';
import ErrorPage from '@lib/components/ErrorPage/ErrorPage';
import getStatusFromError from '@lib/utils/getStatusFromError';
import ConfirmationModal from '@lib/components/ConfirmationModal/ConfirmationModal';
import Trans from '@lib/components/Trans/Trans';
import TabBar from '@lib/components/TabBar/TabBar';
import TabsWrap from '@lib/layouts/AppLayout/TabsWrap/TabsWrap';
import getTabs from 'routes/Interventions/utils/getTabs';
import InterventionList from 'routes/Interventions/InterventionList';
import InterventionAssigneesModal from 'components/InterventionAssigneesModal/InterventionAssigneesModal';
import useOutletContext, { ContextProps } from 'hooks/useOutletContext';
import { IconName } from '@lib/components/Modal/enums';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import { PlanState } from '@lib/enums/plan';
import EmptyState from '@lib/components/EmptyState/EmptyState';
import useIsOnline from '@lib/hooks/useIsOnline';
import { ErrorPageStatusEnum } from '@lib/components/ErrorPage/enums';
import isErrorTimeoutError from '@lib/utils/isErrorTimeoutError';
import useModalManagement from './utils/useModalManagement';
import useActions from './utils/useActions';

export default function InterventionsContainer() {
  const { t } = useTranslation();
  const isOnline = useIsOnline();

  const {
    getUserAccessByCompany,
    getUserAccessByComponent,
    handleOpenPlanModal,
  }: ContextProps = useOutletContext();
  const isViewLocked =
    getUserAccessByCompany(ComponentEnum.InterventionsManagement) ===
    LevelEnum.LimitedRead;
  const isViewOnly =
    getUserAccessByComponent(ComponentEnum.InterventionsManagement) ===
    LevelEnum.Read;

  const useBulkSelectResult = useTableItemsBulkSelect<Intervention>();
  const useModalManagementResult = useModalManagement();
  const {
    closeAllModals,
    isInterventionAssigneesModalOpened,
    openInterventionAssigneesModal,
  } = useModalManagementResult;

  const useInterventionActionsResult = useActions({
    isViewLocked,
    useBulkSelectResult,
    useModalManagementResult,
  });
  const {
    interventionsQueryHook,
    onConfirmedInterventionClose,
    onConfirmedInterventionDestroy,
    onTabChange,
    tabParam,
  } = useInterventionActionsResult;

  const tableTabs = getTabs({
    t,
    interventionsCounter: interventionsQueryHook.counters,
  });
  const { totalCount } = interventionsQueryHook;
  const bulkSelectedItemsCount = totalCount
    ? useBulkSelectResult.getSelectedItemsCount(totalCount)
    : 0;

  if (!isOnline || isErrorTimeoutError(interventionsQueryHook.error)) {
    return <ErrorPage status={ErrorPageStatusEnum.Status503} />;
  }

  if (interventionsQueryHook.error) {
    return (
      <ErrorPage
        status={getStatusFromError(interventionsQueryHook.error)}
        description={interventionsQueryHook.error?.message}
      />
    );
  }

  if (isViewLocked) {
    return (
      <PageBody withoutHeader>
        <EmptyState
          icon={IconName.Lock}
          title={t('lock-state-title-interventions')}
          text={<Trans i18nKey="lock-state-text-interventions" />}
          actions={[
            <Button
              key="upgrade"
              buttonText={t('upgrade-plan')}
              buttonType={ButtonTypes.primaryFilled}
              onClick={() => handleOpenPlanModal(PlanState.Locked)}
            />,
          ]}
        />
      </PageBody>
    );
  }

  return (
    <>
      <PageBody>
        <AppContentMaxWidth>
          <TabsWrap>
            <TabBar
              items={tableTabs}
              active={tabParam as string}
              onActiveChange={onTabChange}
              withoutCrop
            />
          </TabsWrap>
          <InterventionList
            useInterventionActionsResult={useInterventionActionsResult}
            openInterventionAssigneesModal={openInterventionAssigneesModal}
            isViewOnly={isViewOnly}
          />
        </AppContentMaxWidth>
      </PageBody>
      <ConfirmationModal
        confirmButtonLabel={t('yes-delete')}
        description={<Trans i18nKey="intervention-delete-text" />}
        icon={IconName.Delete}
        isOpen={useModalManagementResult.isInterventionDestroyModalOpened}
        onClose={useModalManagementResult.closeAllModals}
        onConfirm={onConfirmedInterventionDestroy}
        title={t('are-you-sure')}
      />
      <ConfirmationModal
        confirmButtonLabel={t('yes-close')}
        description={
          <Trans
            i18nKey={
              bulkSelectedItemsCount > 1
                ? 'interventions-close-text'
                : 'intervention-close-text'
            }
          />
        }
        icon={IconName.Warning}
        isOpen={useModalManagementResult.isInterventionCloseModalOpened}
        onClose={useModalManagementResult.closeAllModals}
        onConfirm={onConfirmedInterventionClose}
        title={t('are-you-sure')}
      />
      <InterventionAssigneesModal
        caption={t('all-assignees')}
        interventionId={isInterventionAssigneesModalOpened?.id}
        isOpen={!!isInterventionAssigneesModalOpened?.id}
        onClose={closeAllModals}
      />
    </>
  );
}
