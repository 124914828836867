import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import AppLayoutCommon from '@lib/layouts/AppLayout/AppLayout';
import useIsOnline from '@lib/hooks/useIsOnline';
import PlatformSwitcher, {
  Platforms,
} from '@lib/components/PlatformSwitcher/PlatformSwitcher';
import {
  checkUserAccessByComponent,
  checkUserAccessForPage,
  checkUserAccessItemIdsByComponent,
} from '@lib/utils/accesses';
import { captureException } from 'utils/captureException';
import TaskSyncTracker from 'components/TaskSyncTracker/TaskSyncTracker';
import { CacheNames } from 'constants/enums';
import {
  ComponentEnum,
  useAuthenticationSessionDestroyMutation,
} from 'graphql-common';
import SupportiveMessage, {
  SupportiveMessageColor,
  SupportiveMessageType,
} from '@lib/components/SupportiveMessage/SupportiveMessage';
import { OfflineTracker } from '@lib/components/OfflineTracker/OfflineTracker';
import toast from '@lib/components/Toast/Toast';
import { USER_SELECTED_SCANNER_ACTION } from '@lib/enums/localStorageKeys';
import { ScannerActionType } from 'routes/Scanner/enums';
import QrcodeScannerContainer from 'routes/Scanner/QrcodeScannerContainer';
import { removeTableFromIndexedDB } from 'utils/indexedDBUtils';
import useWhoamiQueryHook from 'utils/fetch/useWhoamiQueryHook';
import { APP_URLS } from 'constants/urls';
import getHomePageUrl from './utils/getHomePageUrl';
import getNavItems from './utils/getNavItems';
import styles from './AppLayout.module.scss';

const { VITE_AUTH_PLATFORM_URL: AUTH_PLATFORM } = import.meta.env;

export default function AppLayout(): React.ReactElement {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const apolloClient = useApolloClient();
  const [tasksSyncLoading, setTaskSyncLoading] = useState(false);
  const [isScannerVisible, setScannerVisible] = useState(false);
  const isOnline = useIsOnline();
  const { currentUser } = useWhoamiQueryHook();

  const getUserAccessByUrl = (pathnameStr: string) =>
    checkUserAccessForPage(currentUser.finalAccesses || [], pathnameStr);
  const getUserAccessByComponent = useCallback(
    (component: ComponentEnum) =>
      checkUserAccessByComponent(currentUser.finalAccesses || [], component),
    [currentUser.finalAccesses],
  );
  const getUserAccessItemIdsByComponent = (component: ComponentEnum) =>
    checkUserAccessItemIdsByComponent(
      currentUser?.finalAccesses ?? [],
      component,
    );
  const getUserAccessByCompany = (component: ComponentEnum) =>
    checkUserAccessByComponent(
      currentUser?.company.plan.accesses ?? [],
      component,
    );
  const logoUrl = getHomePageUrl(currentUser.finalAccesses || []);

  const callbackOnLogout = async () => {
    localStorage.clear();
    await removeTableFromIndexedDB(CacheNames.tasks);
    await removeTableFromIndexedDB(CacheNames.taskCompletions);
    await removeTableFromIndexedDB(CacheNames.apiCalls);
    window.location.href = AUTH_PLATFORM;
  };

  const [logoutMutation] = useAuthenticationSessionDestroyMutation({
    onCompleted: () => {
      apolloClient.clearStore().then(callbackOnLogout);
    },
    onError: (error: Error) => {
      toast({ content: error.message || 'Oops! Something went wrong' });
      callbackOnLogout();
    },
  });

  const openScanner = (newScannerActionType?: ScannerActionType) => {
    if (newScannerActionType) {
      localStorage.setItem(USER_SELECTED_SCANNER_ACTION, newScannerActionType);
    }
    setScannerVisible(true);
  };
  const closeScanner = useCallback(() => setScannerVisible(false), []);

  const onLogoutHandler = () => logoutMutation({ variables: { input: {} } });

  const navItems = getNavItems({
    closeScanner,
    getUserAccessByCompany,
    getUserAccessByComponent,
    isOnline,
    isScannerVisible,
    openScanner,
    pathname,
    t,
  });

  const components = {
    topContent: (
      <div className={styles.stickyTopContent}>
        <OfflineTracker>
          <SupportiveMessage
            color={SupportiveMessageColor.Yellow}
            text={t('you-are-offline')}
            leftIcon="wifi_off"
            type={SupportiveMessageType.FilledOnly}
            className={styles.stickyTopContentOfflineMessage}
          />
        </OfflineTracker>
        <TaskSyncTracker setTaskSyncLoading={setTaskSyncLoading} />
      </div>
    ),
  };

  const tools = (
    <PlatformSwitcher selected={Platforms.User} available={Platforms.Admin} />
  );

  const outletContext = {
    closeScanner,
    getUserAccessByCompany,
    getUserAccessByComponent,
    getUserAccessByUrl,
    getUserAccessItemIdsByComponent,
    onLogoutHandler,
    openScanner,
    tasksSyncLoading,
  };

  useEffect(() => {
    setScannerVisible(false);
  }, [pathname]);

  return (
    <>
      <AppLayoutCommon
        captureException={captureException}
        components={components}
        navItems={navItems}
        currentUser={currentUser}
        profileUrl={APP_URLS.app.profile.path}
        logoUrl={logoUrl}
        tools={tools}
        outletContext={outletContext}
      />
      {isScannerVisible && (
        <QrcodeScannerContainer
          closeScanner={closeScanner}
          getUserAccessByComponent={getUserAccessByComponent}
        />
      )}
    </>
  );
}
