import _get from 'lodash/get';
import { Counter, useAssetCounterQuery } from 'graphql-common';
import { ApolloError } from '@apollo/client/errors';

type Args = {
  assetCode?: string | null | undefined;
  assetId?: string | null | undefined;
  code?: string | null | undefined;
  id?: string | null | undefined;
  skip?: boolean;
};

export type UseAssetCounterQueryHookResult = {
  data: Counter;
  loading: boolean;
  error: ApolloError | undefined;
};

export default function useAssetCounterQueryHook(
  args: Args,
): UseAssetCounterQueryHookResult {
  const { id, code, assetId, skip = true, assetCode } = args;
  const query = useAssetCounterQuery({
    skip,
    variables: {
      findBy: { id, code },
      assetFindBy: { id: assetId, code: assetCode },
    },
    fetchPolicy: 'network-only',
  });
  const data = _get(query, 'data.data') as Counter;
  const loading = _get(query, 'loading');
  const error = _get(query, 'error');

  return {
    data,
    loading,
    error,
  };
}
