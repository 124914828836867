import { Asset, Site, SiteArea } from 'graphql-common';
import FieldNames from '@lib/enums/fieldNames/interventionFieldNames';

export default function getInterventionDefaultValues({
  site,
  siteArea,
  asset,
}: {
  site?: Site | null;
  siteArea?: SiteArea | null;
  asset?: Asset | null;
}) {
  return {
    [FieldNames.Name]: '',
    [FieldNames.Description]: '',
    [FieldNames.Site]: site?.id ? { label: site.name, value: site.id } : null,
    [FieldNames.SiteArea]: siteArea?.id
      ? { label: siteArea?.name, value: siteArea?.id }
      : null,
    [FieldNames.Asset]: asset?.id
      ? { label: asset?.name, value: asset?.id, data: asset }
      : null,
  };
}
