import { TaskCompletionCreateInputObject } from 'graphql-common';

export type CompletableTodoTask = {
  id: string;
  taskName: string;
  offlineImportantDataDigest: string;
};

export enum SyncStatus {
  closed = 'closed',
  error = 'error',
  idle = 'idle',
  success = 'success',
}

export enum SyncProcess {
  tasks = 'tasks',
  completions = 'completions',
}

export interface StoredCompletion extends TaskCompletionCreateInputObject {
  id: string;
  taskName: string;
}
