import React from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { Task, TaskScopeNameEnum } from 'graphql-common';
import _upperFirst from 'lodash/upperFirst';
import _toLower from 'lodash/toLower';
import Chip, { ChipTypes } from '@lib/components/Chip/Chip';
import Typography from '@lib/components/Typography/Typography';
import { getPriorityChipType } from '@lib/utils/priority';
import { getFormattedDate } from '@lib/utils/dateTimeHelpers';
import { dayDateFormat } from '@lib/enums/dateTime';
import { useTranslation } from 'react-i18next';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import { APP_URLS } from 'constants/urls';
import OfflineLabel from '@lib/components/OfflineLabel/OfflineLabel';
import useTaskIndexedDB from 'hooks/useTaskIndexedDB';
import {
  CustomTaskScopeNameEnum,
  TaskScopeNameEnumExtended,
} from 'constants/enums';
import styles from './TaskPreview.module.scss';

interface Props {
  data: Task;
  scope?: TaskScopeNameEnumExtended;
}

export default function TaskPreview(props: Props) {
  const { data, scope } = props;
  const {
    id,
    priority,
    name,
    deadlineAt,
    completedAt,
    lateFor,
    site,
    siteArea,
    asset,
  } = data;
  const { t, i18n } = useTranslation();
  const { data: downloadData } = useTaskIndexedDB(id);

  const currentLocale = i18n.language;

  const isTodo =
    scope === TaskScopeNameEnum.MyTodoAvailableToComplete ||
    scope === CustomTaskScopeNameEnum.Downloads ||
    scope === TaskScopeNameEnum.MyTodoUpcoming;
  const isDeadlineMissed = isTodo ? !!lateFor : false;

  const link = APP_URLS.app.tasks.view.getDynamicPath({ pathParts: { id } });

  const isOffline = siteArea?.poorWifi || asset?.poorWifi;

  const isSynced = !!downloadData?.id;

  const date = isTodo
    ? getFormattedDate(deadlineAt as string, dayDateFormat, currentLocale)
    : getFormattedDate(completedAt as string, dayDateFormat, currentLocale);

  return (
    <NavLink
      to={link}
      className={classNames(styles.root, {
        [styles.rootWithDeadlineMissed]: isDeadlineMissed,
      })}
    >
      <div className={styles.header}>
        <div>
          <Chip
            type={getPriorityChipType(priority)}
            label={_upperFirst(_toLower(priority))}
            leftIcon="circle"
          />
        </div>
        {!!date && (
          <div className={styles.date}>
            <div>
              {isTodo ? (
                <MaterialIcon icon="schedule" size="md-16" />
              ) : (
                <MaterialIcon icon="check_circle" size="md-16" />
              )}
            </div>
            <Typography variant="caption">
              {isTodo ? `${t('on')} ${date}` : date}
            </Typography>
          </div>
        )}
      </div>
      <Typography variant="h3">{name}</Typography>
      <div className={styles.info}>
        <div className={styles.chipsRow}>
          {site?.id ? (
            <div>
              <Chip type={ChipTypes.chipGray} label={site.name} key={site.id} />
            </div>
          ) : null}
          {siteArea?.id ? (
            <div>
              <Chip
                type={ChipTypes.chipGray}
                label={siteArea.name}
                key={siteArea.id}
              />
            </div>
          ) : null}
          {asset?.id ? (
            <div>
              <Chip
                type={ChipTypes.chipGray}
                label={asset.name}
                key={asset.id}
              />
            </div>
          ) : null}
        </div>
      </div>
      {isOffline && (
        <div className={styles.footerOffline}>
          <OfflineLabel />
          {isTodo && isSynced && (
            <MaterialIcon
              icon="offline_pin"
              className={styles.syncedIcon}
              size="md-16"
            />
          )}
          {isTodo && !isSynced && (
            <MaterialIcon
              icon="sync_disabled"
              className={styles.syncDisabledIcon}
              size="md-16"
            />
          )}
        </div>
      )}
      {isDeadlineMissed && (
        <div className={styles.footerDeadlineMissed}>
          <MaterialIcon icon="error" size="md-16" symbolsOutlined />
          <Typography variant="caption">
            {t('deadline-has-been-missed')}
          </Typography>
        </div>
      )}
    </NavLink>
  );
}
