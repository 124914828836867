import Dexie from 'dexie';
import { CacheNames } from 'constants/enums';
import { Task } from 'graphql-common';
import { captureException } from 'utils/captureException';

Dexie.debug = 'dexie';

export const indexedDB = new Dexie(CacheNames.apiDB);
indexedDB.version(1).stores({
  [CacheNames.tasks]: 'id,createdAt,deadlineAt',
  [CacheNames.taskCompletions]: 'id',
  [CacheNames.apiCalls]: 'request',
});

export const saveToIndexedDB = async (
  storeName: string,
  data: unknown,
): Promise<void> => {
  try {
    await indexedDB.table(storeName).put(data);
  } catch (error) {
    captureException(error);
  }
};

export const removeTableFromIndexedDB = async (
  storeName: string,
): Promise<void> => {
  try {
    await indexedDB.table(storeName).clear();
  } catch (error) {
    captureException(error);
  }
};

export const removeByIdFromIndexedDB = async (
  storeName: string,
  id: string,
): Promise<void> => {
  if (id) {
    try {
      await indexedDB.table(storeName).delete(id);
    } catch (error) {
      captureException(error);
    }
  }
};

export async function getResponseFromIDB(
  request: Request,
): Promise<Response | null> {
  try {
    const body = await request.clone().text();
    const data = await indexedDB.table(CacheNames.apiCalls).get(body);
    return data
      ? new Response(JSON.stringify(data.response), {
          headers: { 'Content-Type': 'application/json' },
        })
      : null;
  } catch (error) {
    console.error('Error getting response from IDB:', error);
    return null;
  }
}

export async function cacheResponseInIDB(
  request: Request,
  response: Response,
): Promise<void> {
  try {
    if (response.ok) {
      const body = await request.clone().text();
      const data = await response.clone().json();
      await saveToIndexedDB(CacheNames.apiCalls, {
        request: body,
        response: data,
      });
    }
  } catch (error) {
    console.error('Error caching response in IDB:', error);
  }
}

export const removeOutdatedTasksFromIndexedDB = async (
  taskIds: string[],
): Promise<void> => {
  if (taskIds.length) {
    try {
      const tasksTable = indexedDB.table(CacheNames.tasks);
      await Dexie.Promise.all(taskIds.map((id) => tasksTable.delete(id)));
    } catch (error) {
      captureException(error);
    }
  }
};

export async function getAllTasksFromDB(): Promise<Task[]> {
  try {
    const store = indexedDB.table(CacheNames.tasks);
    return await store.toArray();
  } catch (error) {
    captureException(error);
    return [];
  }
}
