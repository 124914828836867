import getStatusFromError from '@lib/utils/getStatusFromError';
import { ErrorPageStatusEnum } from '@lib/components/ErrorPage/enums';
import { TIMEOUT_ERROR } from '@lib/constants/fetch';
import { ApolloError } from '@apollo/client/errors';

export default function isErrorTimeoutError(error?: ApolloError) {
  const errorStatus = error ? getStatusFromError(error) : undefined;
  return (
    errorStatus === ErrorPageStatusEnum.Status503 ||
    error?.message === TIMEOUT_ERROR
  );
}
