import _get from 'lodash/get';
import { GetUrlParams } from '@lib/enums/urls';
import { fetchDataFromDB } from 'hooks/useTasksIndexedDB';
import { APP_URLS } from 'constants/urls';
import QrCodePrefix from '@lib/enums/qrCodePrefix';
import TasksFilterFieldNames from '@lib/enums/fieldNames/tasksFilterFieldNames';
import { ErrorPageStatusEnum } from '@lib/components/ErrorPage/enums';
import { ScannerActionType } from '../enums';
import { CodeType } from './getCodeType';
import { ParsedCodes } from './getParsedCodes';
import { HandleQrCodeSuccessProps } from './types';

export default async function handleValidCodeForTasksActionFromIndexedDB(
  data: string,
  {
    setCode,
    navigate,
    onSuccessScanAction,
    setLoading,
    setErrorStatus,
  }: HandleQrCodeSuccessProps,
  parsedCodes: ParsedCodes,
  codeType: CodeType,
) {
  try {
    const filterParams: Record<string, any> = {};
    const { customPrefix, entityCode, sideCode } = parsedCodes;

    if (sideCode) {
      filterParams.siteCode = [{ value: sideCode }];
    }
    if (entityCode && codeType === CodeType.SiteArea) {
      filterParams.siteAreaCode = [{ value: entityCode }];
      filterParams.asset = null;
    }
    if (entityCode && codeType === CodeType.Asset) {
      filterParams.assetCode = [{ value: entityCode }];
    }
    if (
      customPrefix &&
      codeType === CodeType.Asset &&
      customPrefix !== QrCodePrefix.SiteArea &&
      customPrefix !== QrCodePrefix.Asset
    ) {
      filterParams.assetQrPrefix = [{ value: customPrefix }];
    }

    setLoading(true);
    const { paginatedData: tasks } = await fetchDataFromDB(filterParams, {
      [GetUrlParams.PerPage]: 2,
      [GetUrlParams.Page]: 1,
    });
    if (tasks.length === 1) {
      setCode(data);
      navigate(
        APP_URLS.app.tasks.completionCreate.getDynamicPath({
          pathParts: { id: tasks[0].id },
        }),
      );
    } else if ((sideCode || entityCode) && tasks.length) {
      const site = _get(tasks, [0, 'site']);
      const siteArea = _get(tasks, [0, 'siteArea']);
      const asset = _get(tasks, [0, 'asset']);
      const values = {
        [TasksFilterFieldNames.Site]:
          sideCode && site && sideCode === site.code
            ? [
                {
                  value: site.id,
                  label: site.name,
                },
              ]
            : undefined,
        [TasksFilterFieldNames.SiteArea]:
          entityCode &&
          siteArea &&
          codeType === CodeType.SiteArea &&
          entityCode === siteArea.code
            ? [
                {
                  value: siteArea.id,
                  label: siteArea.name,
                },
              ]
            : undefined,
        [TasksFilterFieldNames.Asset]:
          entityCode &&
          asset &&
          codeType === CodeType.Asset &&
          entityCode === asset.code
            ? [
                {
                  value: asset.id,
                  label: asset.name,
                },
              ]
            : undefined,
      };
      if (
        values[TasksFilterFieldNames.Site] === undefined ||
        (values[TasksFilterFieldNames.SiteArea] === undefined &&
          values[TasksFilterFieldNames.Asset] === undefined)
      ) {
        setErrorStatus(ErrorPageStatusEnum.QrCodeScanFailed);
      } else {
        setCode(data);
        onSuccessScanAction(values, ScannerActionType.FindTasks);
      }
    } else {
      setErrorStatus(ErrorPageStatusEnum.QrCodeScanFailed);
    }
  } catch (error) {
    console.error('Error fetching tasks from IndexedDB:', error);
  } finally {
    setLoading(false);
  }
}
