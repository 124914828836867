import _get from 'lodash/get';
import {
  FormAnswer,
  FormTemplateQuestionFieldsKindEnum,
  RespGroupEnum,
} from 'graphql-common';
import { Values } from '@lib/interfaces/form';
import { CheckboxButtonGridValue } from '@lib/components/CheckboxButtonGrid/types';
import FormTemplateFieldNames from '@lib/enums/fieldNames/formTemplateFieldNames';

export default function getValues(answers: FormAnswer[]) {
  const values: Values = {};
  answers.forEach(
    ({ questionId, fieldId, resp, field, attachmentsAttached, kind }) => {
      const key = `question-${questionId}-field-${fieldId}`;
      const radioButtonGridValue: CheckboxButtonGridValue = [];
      const fieldOptions = _get(field, FormTemplateFieldNames.Options);
      const selectedOptions = resp.filter((r) => r.selected);
      const selectedOptionId = resp.find((r) => r.selected)?.optionId;
      const selectedFieldOption = fieldOptions.find(
        ({ id }) => id === selectedOptionId,
      );
      switch (kind) {
        case FormTemplateQuestionFieldsKindEnum.Input:
        case FormTemplateQuestionFieldsKindEnum.TextArea:
        case FormTemplateQuestionFieldsKindEnum.NumberInput:
        case FormTemplateQuestionFieldsKindEnum.Date:
        case FormTemplateQuestionFieldsKindEnum.Datetime:
        case FormTemplateQuestionFieldsKindEnum.Time:
          values[key] = resp[0].value;
          break;
        case FormTemplateQuestionFieldsKindEnum.SingleSelect:
        case FormTemplateQuestionFieldsKindEnum.RadioButton:
          if (selectedFieldOption && selectedFieldOption?.values?.[0]) {
            values[key] = { value: selectedFieldOption.values[0] };
          }
          break;
        case FormTemplateQuestionFieldsKindEnum.Checkbox:
        case FormTemplateQuestionFieldsKindEnum.DecisionBox:
          if (selectedFieldOption && selectedFieldOption?.values?.[0]) {
            values[key] = selectedOptions.map(({ optionId }) => {
              const option = fieldOptions.find(({ id }) => id === optionId);
              const optionValue = _get(option, [
                FormTemplateFieldNames.Values,
                0,
              ]);
              return { label: optionValue, value: optionValue };
            });
          }
          break;
        case FormTemplateQuestionFieldsKindEnum.CheckboxButtonGrid:
          resp.forEach(({ valueId, optionId }) => {
            if (valueId && optionId) {
              radioButtonGridValue.push({ valueId, optionId });
            }
          });
          values[key] = radioButtonGridValue;
          break;
        case FormTemplateQuestionFieldsKindEnum.Rating:
          values[key] = resp[0].value
            ? { value: Number(resp[0].value) }
            : undefined;
          break;
        case FormTemplateQuestionFieldsKindEnum.MinMax:
          values[key] = [
            resp.find(({ group }) => group === RespGroupEnum.Min)?.value,
            resp.find(({ group }) => group === RespGroupEnum.Max)?.value,
          ];
          break;
        case FormTemplateQuestionFieldsKindEnum.DateRange:
          values[key] = [
            resp.find(({ group }) => group === RespGroupEnum.StartDate)?.value,
            resp.find(({ group }) => group === RespGroupEnum.EndDate)?.value,
          ];
          break;
        case FormTemplateQuestionFieldsKindEnum.TimeRange:
          values[key] = [
            resp.find(({ group }) => group === RespGroupEnum.StartTime)?.value,
            resp.find(({ group }) => group === RespGroupEnum.EndTime)?.value,
          ];
          break;
        case FormTemplateQuestionFieldsKindEnum.YesNo:
          values[key] = { value: resp.find((r) => r.selected)?.group };
          break;
        case FormTemplateQuestionFieldsKindEnum.Attachment:
          values[key] = attachmentsAttached;
          break;
        default:
          values[key] = undefined;
      }
    },
  );
  return values;
}
