import { TFunction } from 'i18next';
import _without from 'lodash/without';
import { Intervention } from 'graphql-common';
import { NavItem, NavItemTypeEnum } from '@lib/components/Menu/Menu';
import Icons from '@lib/components/Icons/Icons';

type Args = {
  onEdit?: (v: Intervention) => void;
  onDelete?: (v: Intervention) => void;
  onClose?: (v: Intervention) => void;
  onCreateAction?: () => void;
  t: TFunction<'translation', undefined>;
};

export default function getInterventionViewPageActionsMenu({
  onEdit,
  onDelete,
  onClose,
  onCreateAction,
  t,
}: Args) {
  return _without(
    [
      onEdit
        ? {
            id: 'edit',
            title: t('edit'),
            onClick: (item: Intervention) => onEdit(item),
            leftIcon: Icons.edit,
            type: NavItemTypeEnum.Secondary,
          }
        : undefined,
      onCreateAction
        ? {
            id: 'create-action',
            title: t('create-action'),
            onClick: () => onCreateAction(),
            leftIcon: Icons.add,
            type: NavItemTypeEnum.Secondary,
          }
        : undefined,
      onDelete
        ? {
            id: 'delete',
            title: t('delete'),
            onClick: (item: Intervention) => onDelete(item),
            leftIcon: Icons.delete,
            type: NavItemTypeEnum.Secondary,
          }
        : undefined,
      onClose
        ? {
            id: 'close',
            title: t('close'),
            onClick: (item: Intervention) => onClose(item),
            leftIcon: Icons.close,
            type: NavItemTypeEnum.Secondary,
          }
        : undefined,
    ],
    undefined,
  ) as NavItem<Intervention>[];
}
