import { TasksMyTodoCompletableByDateDataQuery } from 'graphql-common';
import { LazyQueryExecFunction } from '@apollo/client';
import { addDaysFn, endOfDayFn } from '@lib/utils/dateTimeHelpers';

export const fetchCompletableTasks = (
  fetchTasksMyTodoCompletableByDateData: LazyQueryExecFunction<
    TasksMyTodoCompletableByDateDataQuery,
    any
  >,
  syncTrackerDays: number,
) => {
  return fetchTasksMyTodoCompletableByDateData({
    variables: {
      date: syncTrackerDays
        ? addDaysFn(endOfDayFn(new Date()), +syncTrackerDays)
        : endOfDayFn(new Date()),
    },
  });
};
