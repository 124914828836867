import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ScopeEnum,
  useAuthenticationPasswordsForgotMutation,
  UserSelfUpdateInputObject,
  useUserSelfUpdateMutation,
  WhoamiDocument,
  AuthenticationPasswordsUpdateInput,
  useAuthenticationPasswordsUpdateMutation,
} from 'graphql-common';
import _get from 'lodash/get';
import _omit from 'lodash/omit';
import _pick from 'lodash/pick';
import _isEmpty from 'lodash/isEmpty';
import _cloneDeep from 'lodash/cloneDeep';
import toast from '@lib/components/Toast/Toast';
import getAppUrlWithDomain from '@lib/utils/getAppUrlWithDomain';
import { APP_URLS } from 'constants/urls';
import Profile from 'components/Profile/Profile';
import { Values } from '@lib/interfaces/form';
import useWhoamiQueryHook from 'utils/fetch/useWhoamiQueryHook';
import useOutletContext, { ContextProps } from 'hooks/useOutletContext';

const { VITE_AUTH_PLATFORM_URL: AUTH_PLATFORM } = import.meta.env;

function ProfileContainer() {
  const { t } = useTranslation();
  const { currentUser, currentUserEmail } = useWhoamiQueryHook();

  const { onLogoutHandler }: ContextProps = useOutletContext();

  const [updateMutation, updateMutationResult] = useUserSelfUpdateMutation({
    onCompleted: () => {
      toast({ content: t('update-user-success') });
    },
    onError: (error) => {
      toast({ content: error.message || 'Oops! Something went wrong' });
    },
    update: (cache, result) => {
      try {
        const cacheData = _cloneDeep(
          cache.readQuery({
            query: WhoamiDocument,
          }),
        );
        const data = _get(result, 'data.data', {});
        const existingData = _get(cacheData, 'whoami', {});
        if (existingData && data) {
          const updatedData = {
            // @ts-ignore
            ...cacheData,
            whoami: {
              ...existingData,
              ...data,
            },
          };
          cache.writeQuery({
            query: WhoamiDocument,
            data: updatedData,
          });
        }
      } catch (e) {
        console.warn(e);
      }
    },
  });

  const [sendPasswordResetMutation] = useAuthenticationPasswordsForgotMutation({
    onCompleted: (response) => {
      const message = _get(response, 'data.message', '');
      toast({ content: message });
    },
    onError: (error) => {
      toast({ content: error.message || 'Oops! Something went wrong' });
    },
  });

  const [passwordResetMutation, passwordResetMutationResult] =
    useAuthenticationPasswordsUpdateMutation({
      onCompleted: () => {
        toast({ content: t('reset-password-success') });
      },
      onError: (error) => {
        toast({ content: error.message || 'Oops! Something went wrong' });
      },
    });

  const { error: updateMutationError, loading: updateMutationLoading } =
    updateMutationResult;
  const graphQLErrors = updateMutationError?.graphQLErrors;

  const {
    error: passwordResetMutationError,
    loading: passwordResetMutationLoading,
    reset: passwordResetMutationReset,
  } = passwordResetMutationResult;
  const passwordResetGraphQLErrors = passwordResetMutationError?.graphQLErrors;

  const onUpdateHandler = (values: Values) => {
    let attributes = _pick(values, [
      'avatarAttached',
      'lang',
    ]) as UserSelfUpdateInputObject;
    if (values.avatarAttached === null) {
      attributes.avatarAttached = { _destroy: true };
    } else if (!_isEmpty(attributes.avatarAttached?.file)) {
      attributes = _omit(attributes, 'avatarAttached.url');
    } else {
      attributes = _omit(attributes, 'avatarAttached');
    }
    return updateMutation({
      variables: {
        input: {
          attributes,
          redirectUrl: getAppUrlWithDomain(
            APP_URLS.setup.confirmEmail.path,
            AUTH_PLATFORM,
          ),
        },
      },
    });
  };

  const onUpdatePasswordHandler = (values: Values) => {
    const attributes = _pick(values, [
      'passwordChallenge',
      'password',
      'passwordConfirmation',
    ]) as AuthenticationPasswordsUpdateInput;
    return passwordResetMutation({
      variables: {
        input: attributes,
      },
    });
  };

  const onSendPasswordResetHandler = () =>
    sendPasswordResetMutation({
      variables: {
        input: {
          email: currentUserEmail || '',
          redirectUrl: getAppUrlWithDomain(
            APP_URLS.auth.resetPassword.path,
            AUTH_PLATFORM,
          ),
          scope: ScopeEnum.User,
        },
      },
    });

  return (
    <Profile
      currentUser={currentUser}
      graphQLErrors={graphQLErrors}
      loading={updateMutationLoading}
      onLogout={onLogoutHandler}
      onSendPasswordResetHandler={onSendPasswordResetHandler}
      onUpdateHandler={onUpdateHandler}
      onUpdatePasswordHandler={onUpdatePasswordHandler}
      passwordResetGraphQLErrors={passwordResetGraphQLErrors}
      passwordResetMutationLoading={passwordResetMutationLoading}
      passwordResetMutationReset={passwordResetMutationReset}
    />
  );
}

export default ProfileContainer;
