async function isSamsungS9(): Promise<boolean> {
  const samsungS9Regex = /SM-G960|SM-G960F|SM-G965|SM-G965F/;
  const nav = navigator as Navigator & { userAgentData?: any };

  if (nav.userAgentData?.getHighEntropyValues) {
    try {
      const data = await nav.userAgentData.getHighEntropyValues(['model']);
      console.log('userAgentData', data);

      return samsungS9Regex.test(data.model);
    } catch (error) {
      console.warn('Failed to get device model via userAgentData:', error);
    }
  }

  console.log('Falling back to userAgent:', nav.userAgent);
  return samsungS9Regex.test(nav.userAgent);
}

export default isSamsungS9;
