import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
  FilterGroupingEnum,
  Intervention,
  InterventionScopeNameEnum,
  LevelEnum,
} from 'graphql-common';
import _valuesIn from 'lodash/valuesIn';
import _without from 'lodash/without';
import _get from 'lodash/get';
import CircleLoader from '@lib/components/CircleLoader/CircleLoader';
import Pagination from '@lib/components/Pagination/Pagination';
import CircleLoaderWrapper, {
  CircleLoaderWrapperType,
} from '@lib/components/CircleLoaderWrapper/CircleLoaderWrapper';
import useIsOnline from '@lib/hooks/useIsOnline';
import SupportiveMessage from '@lib/components/SupportiveMessage/SupportiveMessage';
import IconButton, {
  IconButtonTypes,
} from '@lib/components/IconButton/IconButton';
import InterventionPreview from 'components/InterventionPreview/InterventionPreview';
import { UseInterventionActionsResult } from 'routes/Interventions/utils/useActions';
import useSearchParam from '@lib/hooks/useSearchParam';
import usePrevious from '@lib/hooks/usePrevious';
import { GetUrlParams } from '@lib/enums/urls';
import useUsersQueryHook from 'utils/fetch/useUsersQueryHook';
import useOutletContext, { ContextProps } from 'hooks/useOutletContext';
import TasksFilterFieldNames from '@lib/enums/fieldNames/tasksFilterFieldNames';
import InterventionListFilter from './InterventionListFilter';
import InterventionListEmptyState from './InterventionListEmptyState';
import styles from './InterventionList.module.scss';

function InterventionListItem({
  data,
  scope,
  openInterventionAssigneesModal,
}: {
  data: Intervention;
  scope: InterventionScopeNameEnum;
  openInterventionAssigneesModal: (item: Intervention) => void;
}) {
  const usersQueryHookResult = useUsersQueryHook({
    paginationParams: {
      [GetUrlParams.Page]: '1',
      [GetUrlParams.PerPage]: '4',
    },
    skipGetAllIds: true,
    additionalFilters: [
      {
        interventionAssigneesInterventionId: {
          grouping: FilterGroupingEnum.Or,
          predicate: { eq: data.id },
        },
      },
    ],
    getWithAllIds: true,
  });
  return (
    <div>
      <InterventionPreview
        data={data}
        scope={scope}
        usersData={usersQueryHookResult.collection}
        usersTotalCount={usersQueryHookResult.totalCount}
        openInterventionAssigneesModal={openInterventionAssigneesModal}
      />
    </div>
  );
}

function InterventionList(props: {
  useInterventionActionsResult: UseInterventionActionsResult;
  openInterventionAssigneesModal: (item: Intervention) => void;
  isViewOnly: boolean;
}) {
  const {
    useInterventionActionsResult,
    openInterventionAssigneesModal,
    isViewOnly,
  } = props;

  const {
    tabParam,
    onResetFilter,
    interventionsQueryHook,
    filterParams,
    openCreateInterventionPage,
  } = useInterventionActionsResult;

  const {
    firstLoading,
    loading,
    totalCount: totalEntries,
    collection: interventions,
  } = interventionsQueryHook;

  const { t } = useTranslation();
  const isOnline = useIsOnline();
  const { pathname } = useLocation();
  const [searchQuery, setSearchQuery] = useSearchParam();
  const prevTabParam = usePrevious(tabParam);
  const [isFilterByQRCodeMessageVisible, setFilterByQRCodeMessageVisible] =
    useState(false);
  const outletContext: ContextProps = useOutletContext();
  const getUserAccessByUrl = _get(outletContext, 'getUserAccessByUrl');
  const level: LevelEnum = getUserAccessByUrl(pathname);

  const isContentVisible = !!totalEntries && !!interventions.length;
  const isEmptyStateVisible = !isContentVisible && !firstLoading;
  const isPaginationVisible =
    !!totalEntries &&
    !!interventions.length &&
    totalEntries > interventions.length;
  const isSearchApplied = !!searchQuery;
  const isFilterApplied = !!_without(_valuesIn(filterParams), undefined).length;
  const isEmptyState =
    !loading && isEmptyStateVisible && !isSearchApplied && !isFilterApplied;
  const isEmptyStateBySearch = isEmptyStateVisible && isSearchApplied;
  const isEmptyStateByFilter = isEmptyStateVisible && isFilterApplied;

  const emptyState = (
    <InterventionListEmptyState
      isViewOnly={isViewOnly}
      isAddActionDisabled={level === LevelEnum.Read}
      isEmptyState={isEmptyState}
      isEmptyStateByFilter={isEmptyStateByFilter}
      isEmptyStateBySearch={isEmptyStateBySearch}
      onResetFilter={onResetFilter}
      openCreateInterventionPage={openCreateInterventionPage}
      setSearchQuery={setSearchQuery}
      tabParam={tabParam}
    />
  );

  const scope = (
    loading ? prevTabParam || tabParam : tabParam
  ) as InterventionScopeNameEnum;

  useEffect(() => {
    const siteAreaIds = _get(filterParams, TasksFilterFieldNames.SiteArea, []);
    const assetIds = _get(filterParams, TasksFilterFieldNames.Asset, []);
    setFilterByQRCodeMessageVisible(
      !!(siteAreaIds?.length || assetIds?.length),
    );
  }, [filterParams]);

  const content = (
    <>
      <div className={styles.interventionsGrid}>
        {interventions.map((item) => (
          <InterventionListItem
            key={item.id}
            scope={scope}
            data={item}
            openInterventionAssigneesModal={openInterventionAssigneesModal}
          />
        ))}
      </div>
      {isPaginationVisible && (
        <div className={styles.pagination}>
          <Pagination totalEntries={totalEntries} />
        </div>
      )}
    </>
  );

  return (
    <div>
      {firstLoading ? (
        <CircleLoader />
      ) : (
        <CircleLoaderWrapper
          isLoading={loading}
          type={CircleLoaderWrapperType.blur}
        >
          {!isEmptyState && isOnline && (
            <InterventionListFilter
              isViewOnly={isViewOnly}
              onCreateIntervention={openCreateInterventionPage}
              onResetFilter={onResetFilter}
            />
          )}
          {isFilterByQRCodeMessageVisible && (
            <SupportiveMessage
              text={t('qr-code-filter-text')}
              className={styles.message}
              actions={
                <IconButton
                  icon="close"
                  type={IconButtonTypes.PrimaryOutlined}
                  onClick={() => setFilterByQRCodeMessageVisible(false)}
                />
              }
            />
          )}
          {isEmptyStateVisible || !isOnline ? emptyState : content}
        </CircleLoaderWrapper>
      )}
    </div>
  );
}

export default InterventionList;
