import React, { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { useTranslation } from 'react-i18next';
import { hotjar } from 'react-hotjar';
import _get from 'lodash/get';
import _without from 'lodash/without';
import { useApolloClient } from '@apollo/client';
import { LevelEnum } from 'graphql-common';
import { ToastContainer } from '@lib/components/Toast/Toast';
import CircleLoader from '@lib/components/CircleLoader/CircleLoader';
import useWhoamiQueryHook from 'utils/fetch/useWhoamiQueryHook';
import { GetUrlParams, PATH_AUTH, PATH_INTERVENTIONS } from '@lib/enums/urls';
import generateSafeRedirect from '@lib/utils/generateSafeRedirect';
import { setSentryTags } from '@lib/utils/sentryHelpers';
import UpdateHtmlLang from '@lib/components/UpdateHtmlLang/UpdateHtmlLang';
import { checkUserAccessForPage } from '@lib/utils/accesses';
import { removeTableFromIndexedDB } from 'utils/indexedDBUtils';
import { CacheNames } from 'constants/enums';
import { APP_URLS } from 'constants/urls';
import getHomePageUrl from 'layouts/AppLayout/utils/getHomePageUrl';
import {
  FullStory,
  isInitialized as isFullStoryInitialized,
} from '@fullstory/browser';

const { MODE, VITE_AUTH_PLATFORM_URL: AUTH_PLATFORM } = import.meta.env;

function ProtectedRoute() {
  const apolloClient = useApolloClient();
  const { pathname, search } = useLocation();
  const { i18n } = useTranslation();
  const urlSearchParams = new URLSearchParams(search);
  const dst = urlSearchParams.get(GetUrlParams.Dst) as string;
  const pathnameArr = _without(pathname.split('/'), '');
  const pathBase = pathnameArr.length ? pathnameArr[0] : '/';

  // Whoami
  const { data, loading, error, currentUserLang, firstLoading } =
    useWhoamiQueryHook();
  const userId = _get(data, 'whoami.resource.id');
  const userEmail = _get(data, 'whoami.resource.email');
  const username = _get(data, 'whoami.resource.username');
  const deactivatedAt = _get(data, 'whoami.resource.deactivatedAt');
  const userType = _get(data, 'whoami.resource.__typename');
  const setup = _get(data, 'whoami.resource.setup');
  const finalAccesses = _get(data, 'whoami.resource.finalAccesses', []);
  const companyAccesses = _get(
    data,
    'whoami.resource.company.plan.accesses',
    [],
  );
  const graphQLErrors = _get(error, 'graphQLErrors', []);
  const authError = graphQLErrors.find(
    ({ extensions }) => extensions.code === 'AUTHENTICATION_ERROR',
  );
  const isUserAuth = !authError && !!userId;
  const isUserDeactivated = !!deactivatedAt;
  const loginUrl = `${AUTH_PLATFORM}/${PATH_AUTH}/login`;

  const userAccessForPage = checkUserAccessForPage(finalAccesses, pathname);
  const companyAccessForPage = checkUserAccessForPage(
    companyAccesses,
    pathname,
  );
  const defaultUrlAfterAuth = getHomePageUrl(finalAccesses);

  if (MODE !== 'development' && !loading) {
    setSentryTags({ isUserAuth, userId, userEmail, userType, username });

    if (userId && hotjar.initialized()) {
      hotjar.identify(userId, {
        isUserAuth,
        userId,
        userEmail,
        userType,
        username,
      });
    }

    if (userId && isFullStoryInitialized()) {
      FullStory('setProperties', {
        type: 'user',
        properties: {
          isUserAuth,
          userId,
          userEmail,
          userType,
          username,
        },
      });
    }
  }

  const appRender = () => (
    <QueryParamProvider adapter={ReactRouter6Adapter}>
      <UpdateHtmlLang />
      <Outlet />
      <ToastContainer />
    </QueryParamProvider>
  );

  const appNavigate = (to: string, external?: boolean, dstUrl?: string) => {
    if (!external) return <Navigate to={to} replace />;
    const toUrlSearch = dstUrl && dstUrl !== '/' ? `?dst=${dstUrl}` : '';
    window.location.href = `${to}${toUrlSearch}`;
    return undefined;
  };

  useEffect(() => {
    if (i18n.language !== currentUserLang) {
      i18n.changeLanguage(currentUserLang);
    }
  }, [i18n, currentUserLang]);

  if (firstLoading) {
    return <CircleLoader />;
  }
  if (pathBase === PATH_AUTH) {
    if (dst && dst !== '/') {
      window.location.href = dst;
      return undefined;
    }
    return appNavigate(APP_URLS.app.index.path);
  }
  if ((!setup && isUserAuth) || !isUserAuth) {
    const dstUrl = generateSafeRedirect(pathname + search);
    apolloClient.clearStore().then(async () => {
      localStorage.clear();
      await removeTableFromIndexedDB(CacheNames.tasks);
      await removeTableFromIndexedDB(CacheNames.taskCompletions);
      await removeTableFromIndexedDB(CacheNames.apiCalls);
      appNavigate(loginUrl, true, dstUrl.toString());
    });
    return undefined;
  }
  if (isUserDeactivated) {
    if (pathname !== APP_URLS.app.unauthorized.path) {
      return appNavigate(APP_URLS.app.unauthorized.path);
    }
  }
  if (!isUserDeactivated && pathname === APP_URLS.app.unauthorized.path) {
    return appNavigate(APP_URLS.app.notFound.path);
  }
  if (
    companyAccessForPage === LevelEnum.LimitedRead &&
    pathname.includes(`/${PATH_INTERVENTIONS}`) &&
    !APP_URLS.app.interventions.index.isTheSameUrlAs(pathname)
  ) {
    return appNavigate(APP_URLS.app.interventions.index.path);
  }
  if (userAccessForPage === LevelEnum.LimitedRead) {
    return appNavigate(APP_URLS.app.accessDenied.path);
  }
  if (isUserAuth && !pathnameArr.length) {
    return appNavigate(defaultUrlAfterAuth);
  }
  return appRender();
}

export default ProtectedRoute;
