import React from 'react';
import { useTranslation } from 'react-i18next';
import AppContentMaxWidth from '@lib/layouts/AppLayout/AppContentMaxWidth/AppContentMaxWidth';
import PageBody from '@lib/layouts/DashboardLayout/PageBody/PageBody';
import CircleLoader from '@lib/components/CircleLoader/CircleLoader';
import { UseInterventionActionActionsResult } from 'routes/Intervention/utils/useInterventionActionActions';
import DashboardTitleHeader from '@lib/layouts/DashboardLayout/DashboardTitleHeader/DashboardTitleHeader';
import InterventionActions from 'routes/Intervention/InterventionActions/InterventionActions';
import TopNavigationHeader from '@lib/layouts/AppLayout/TopNavigationHeader/TopNavigationHeader';
import { APP_URLS } from 'constants/urls';
import InterventionInfo from '../InterventionInfo/InterventionInfo';
import { UseInterventionActionsResult } from '../utils/useInterventionActions';
import { UseModalManagementResult } from '../utils/useModalManagement';
import styles from './InterventionActionViewPage.module.scss';

type Props = {
  useModalManagementResult: UseModalManagementResult;
  useInterventionActionsResult: UseInterventionActionsResult;
  useInterventionActionActionsResult: UseInterventionActionActionsResult;
  isViewOnly: boolean;
};

export default function InterventionActionViewPage({
  useModalManagementResult,
  useInterventionActionsResult,
  useInterventionActionActionsResult,
  isViewOnly,
}: Props) {
  const { t } = useTranslation();

  const { interventionQueryHookResult } = useInterventionActionsResult;
  const { interventionActionQueryHookResult } =
    useInterventionActionActionsResult;
  const { firstLoading, data: intervention } = interventionQueryHookResult;

  return (
    <>
      <TopNavigationHeader
        title={t('intervention-action-details')}
        backUrl={APP_URLS.app.interventions.index.getPathWithQuery()}
      />
      <PageBody withoutHeader>
        <AppContentMaxWidth>
          {firstLoading ? (
            <CircleLoader />
          ) : (
            <div>
              <DashboardTitleHeader title={intervention.name} />
              <InterventionActions
                collection={[interventionActionQueryHookResult.data]}
                totalCount={1}
                useInterventionActionsResult={useInterventionActionsResult}
                useModalManagementResult={useModalManagementResult}
                isViewOnly={isViewOnly}
              />
              <div className={styles.info}>
                <InterventionInfo
                  useModalManagementResult={useModalManagementResult}
                  useInterventionActionsResult={useInterventionActionsResult}
                  isViewOnly={isViewOnly}
                />
              </div>
            </div>
          )}
        </AppContentMaxWidth>
      </PageBody>
    </>
  );
}
