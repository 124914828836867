import { Intervention } from 'graphql-common';
import FieldNames from '@lib/enums/fieldNames/interventionFieldNames';
import getFullName from '@lib/utils/getFullName';
import { getInterventionPriorityOption } from '@lib/utils/priority';
import { getInterventionStatusOption } from '@lib/utils/status';
import { getTimeFromMinutesCount } from '@lib/utils/getTimeFromMinutesCount';
import { transformDate } from '@lib/utils/dateTimeHelpers';

export default function getInterventionDefaultValuesFromQueryData(
  data: Intervention,
  assigneeIds: { id: string }[],
) {
  return {
    [FieldNames.Name]: data?.name || '',
    [FieldNames.Description]: data?.description || '',
    [FieldNames.AttachmentsAttached]: data?.attachmentsAttached || [],
    [FieldNames.Code]: data?.code || '',
    [FieldNames.Category]: data?.category
      ? { label: data.category.name, value: data.category.id }
      : null,
    [FieldNames.Priority]: data?.priority
      ? getInterventionPriorityOption(data.priority)
      : null,
    [FieldNames.Status]: data?.status
      ? getInterventionStatusOption(data.status)
      : null,
    [FieldNames.Site]: data?.site
      ? { label: data.site.name, value: data.site.id }
      : null,
    [FieldNames.SiteArea]: data?.siteArea
      ? { label: data.siteArea.name, value: data.siteArea.id }
      : null,
    [FieldNames.Asset]: data?.asset
      ? { label: data.asset.name, value: data.asset.id, data: data.asset }
      : null,
    [FieldNames.Task]: data?.task
      ? { label: data.task.name, value: data.task.id }
      : null,
    [FieldNames.Supervisor]: data?.supervisor
      ? {
          label: getFullName(data.supervisor),
          value: data.supervisor.id,
          data: data.supervisor,
        }
      : null,
    [FieldNames.Assignee]:
      assigneeIds?.length > 0
        ? assigneeIds.map(({ id }) => ({ value: id }))
        : null,
    [FieldNames.EstimatedTime]: data?.estimatedTime
      ? getTimeFromMinutesCount(data.estimatedTime)
      : '',
    [FieldNames.EstimatedDate]: data?.estimatedDate
      ? transformDate(new Date(data.estimatedDate), false, true)
      : '',
    [FieldNames.LogisticTime]: data?.logisticTime
      ? getTimeFromMinutesCount(data.logisticTime)
      : '',
    [FieldNames.DurationTime]: data?.durationTime
      ? getTimeFromMinutesCount(data.durationTime)
      : '',
  };
}
