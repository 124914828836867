import React, { memo, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _debounce from 'lodash/debounce';
import _get from 'lodash/get';
import { ComponentEnum, LevelEnum } from 'graphql-common';
import isMobile from '@lib/utils/isMobile';
import ErrorPage from '@lib/components/ErrorPage/ErrorPage';
import { ErrorPageStatusEnum } from '@lib/components/ErrorPage/enums';
import useWindowDimensions from '@lib/hooks/useWindowDimensions';
import useIsOnline from '@lib/hooks/useIsOnline';
import CircleLoader from '@lib/components/CircleLoader/CircleLoader';
import AssetCounterIndexModalForm from 'components/AssetCounterIndexModalForm/AssetCounterIndexModalForm';
import { FormRef } from '@lib/components/ReactHookForm/types';
import usePrevious from '@lib/hooks/usePrevious';
import Modal from '@lib/components/Modal/Modal';
import styles from 'layouts/AppLayout/AppLayout.module.scss';
import useModalManagement from './utils/useModalManagement';
import useActions from './utils/useActions';
import QrcodeScanner from './QrcodeScanner';

type Props = {
  closeScanner: () => void;
  getUserAccessByComponent: (component: ComponentEnum) => undefined | LevelEnum;
};

function QrcodeScannerContainer({
  closeScanner,
  getUserAccessByComponent,
}: Props) {
  const { t } = useTranslation();
  const formRef = useRef<FormRef>();
  const { phone, tablet } = isMobile();
  const isOnline = useIsOnline();
  const [isLoading, setLoading] = useState(false);
  const isLoadingRef = useRef(false);
  const [scannerRenderDate, setScannerRenderDate] = useState(
    new Date().toISOString(),
  );
  const [errorStatus, setErrorStatus] = useState<
    ErrorPageStatusEnum | undefined
  >(undefined);
  const { id = '' } = useParams();
  const windowDimensions = useWindowDimensions();
  const prevWindowDimensions = usePrevious(windowDimensions);
  const useModalManagementResult = useModalManagement();
  const [isPageReady, setIsPageReady] = useState(false);

  const { width, height } = windowDimensions;
  const prevWidth = _get(prevWindowDimensions, 'width');
  const prevHeight = _get(prevWindowDimensions, 'height');

  const useActionsResult = useActions({
    id,
    isOnline,
    setErrorStatus,
    setLoading,
    useModalManagementResult,
    setScannerRenderDate,
    closeScanner,
    isLoadingRef,
  });
  const {
    assetCounterIndexMutationError,
    assetCounterIndexMutationLoading,
    counterQueryHookResult,
    loading,
    onCloseModal,
    onCounterIndexFormSubmit,
    qrCodeSuccessCallback,
  } = useActionsResult;

  const isLoadingState = !isPageReady || (!!id && loading);

  useEffect(() => {
    isLoadingRef.current = isLoading;
  }, [isLoading]);

  useEffect(() => {
    const isMobileDevice = phone || tablet;
    if (isMobileDevice) {
      setErrorStatus(undefined);
      setIsPageReady(true);
    } else {
      setErrorStatus(ErrorPageStatusEnum.MobileOnly);
      setIsPageReady(true);
    }
  }, [phone, tablet, width]);

  useEffect(() => {
    const handleWidthChange = _debounce(() => {
      const isWidthChanged = prevWidth && width && prevWidth !== width;
      const isHeightChanged = prevHeight && height && prevHeight !== height;
      const isWindowChanged = isWidthChanged || isHeightChanged;
      if (isWindowChanged && !loading) {
        setScannerRenderDate(new Date().toISOString());
      }
    }, 300);

    handleWidthChange();

    return () => {
      handleWidthChange.cancel();
    };
  }, [width, prevWidth, loading, prevHeight, height]);

  return (
    <>
      <Modal
        isOpen
        onClose={closeScanner}
        modalContentClassName={styles.modalContentClassName}
        modalBodyClassName={styles.modalBodyClassName}
        caption={t('scanner-description')}
      >
        <>
          {isLoadingState && <CircleLoader />}
          {errorStatus && (
            <ErrorPage
              status={errorStatus}
              btnOnClickHandler={() => setErrorStatus(undefined)}
            />
          )}
          {!isLoadingState && !errorStatus && (
            <QrcodeScanner
              qrCodeSuccessCallback={qrCodeSuccessCallback}
              key={scannerRenderDate}
              getUserAccessByComponent={getUserAccessByComponent}
              loading={isLoading}
            />
          )}
        </>
      </Modal>
      <AssetCounterIndexModalForm
        assetCounter={counterQueryHookResult.data}
        assetCounterError={counterQueryHookResult.error}
        formRef={formRef}
        graphQLErrors={assetCounterIndexMutationError?.graphQLErrors}
        isOpen={useModalManagementResult.isCounterIndexFormModalOpened}
        loading={
          assetCounterIndexMutationLoading || counterQueryHookResult.loading
        }
        onCancel={onCloseModal}
        onClose={onCloseModal}
        onSubmit={onCounterIndexFormSubmit}
      />
    </>
  );
}

export default memo(QrcodeScannerContainer);
