import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Intervention, InterventionScopeNameEnum } from 'graphql-common';
import toast from '@lib/components/Toast/Toast';
import { UseBulkSelectResult } from '@lib/hooks/useTableItemsBulkSelect';
import usePaginationParams from '@lib/hooks/usePaginationParams';
import onDestroyListItemCallBack from '@lib/utils/onDestroyListItemCallBack';
import tableItemsBulkAction from '@lib/utils/tableItemsBulkAction';
import getAllTableItemsIds from '@lib/utils/getAllTableItemsIds';
import useInterventionsQueryHook, {
  UseAssetInterventionsQueryHookResult,
} from 'utils/fetch/useInterventionsQueryHook';
import { APP_URLS } from 'constants/urls';
import { TabBarItem as TabBarItemType } from '@lib/components/TabBar/constants';
import useTabParam from '@lib/hooks/useTabParam';
import useSearchParam from '@lib/hooks/useSearchParam';
import { useLocalStorage } from 'usehooks-ts';
import { INTERVENTION_FILTER_FORM } from '@lib/enums/localStorageKeys';
import { UseModalManagementResult } from './useModalManagement';
import useMutations from './useMutations';

interface ActionsProps {
  interventionScope?: InterventionScopeNameEnum;
  isViewLocked?: boolean;
  useBulkSelectResult?: UseBulkSelectResult<Intervention>;
  useModalManagementResult: UseModalManagementResult;
}

export interface UseInterventionActionsResult {
  interventionsQueryHook: UseAssetInterventionsQueryHookResult;
  onCloseModal: () => void;
  onConfirmedInterventionDestroy: () => void;
  openCreateInterventionPage: (item?: Intervention) => void;
  openEditInterventionPage: (item?: Intervention) => void;
  openInterventionDestroyModal: (item: Intervention) => void;
  openInterventionCloseModal: (item: Intervention) => void;
  openViewInterventionPage: (item?: Intervention) => void;
  onTabChange: (item?: TabBarItemType) => void;
  tabParam: InterventionScopeNameEnum;
  searchQuery?: string;
  onConfirmedInterventionClose: () => void;
  onResetFilter: () => void;
  filterParams: object;
}

export default function useActions(
  props: ActionsProps,
): UseInterventionActionsResult {
  const {
    isViewLocked,
    useModalManagementResult,
    useBulkSelectResult,
    interventionScope,
  } = props;

  const [tabParam = InterventionScopeNameEnum.Opened, setTabParam] =
    useTabParam(InterventionScopeNameEnum.OpenedMyParticipation);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [paginationParams] = usePaginationParams();
  const [filterParams, setFilterParams] = useLocalStorage(
    INTERVENTION_FILTER_FORM,
    {},
  );
  const [searchQuery] = useSearchParam();
  const { pathname, search } = useLocation();

  const useMutationsResult = useMutations();

  // Interventions
  const interventionsQueryHook = useInterventionsQueryHook({
    paginationParams,
    orderingParams: paginationParams,
    filterParams,
    searchQuery,
    scopeName: interventionScope || (tabParam as InterventionScopeNameEnum),
    skip: isViewLocked,
  });

  // Actions
  const onGetAllIds = () =>
    getAllTableItemsIds<Intervention>({
      fetchIds: interventionsQueryHook.fetchIds,
      useBulkSelectResult,
    });

  const onCloseModal = () => {
    useBulkSelectResult?.handleUnselectAllTableItems();
    useModalManagementResult.closeAllModals();
  };

  const openInterventionDestroyModal = (item: Intervention) => {
    useBulkSelectResult?.handleSelectSingleTableItem(item);
    useModalManagementResult.openInterventionDestroyModal();
  };

  const openInterventionCloseModal = (item: Intervention) => {
    useBulkSelectResult?.handleSelectSingleTableItem(item);
    useModalManagementResult.openInterventionCloseModal();
  };

  const onConfirmedInterventionDestroy = () => {
    useModalManagementResult.closeAllModals();
    toast({
      content: t('destroy-intervention-success'),
      closeCallback: () => {
        tableItemsBulkAction<Intervention>({
          action: (idsValue?: string[]) => {
            if (idsValue) {
              useMutationsResult.interventionDestroyMutation({
                variables: { id: idsValue[0] },
                onCompleted: () => {
                  useBulkSelectResult?.handleUnselectAllTableItems();
                  onDestroyListItemCallBack({
                    collection: interventionsQueryHook.collection,
                    navigate,
                    pathname,
                    refetch: interventionsQueryHook.refetch,
                    search,
                    totalPages: interventionsQueryHook.totalPages,
                  });
                },
              });
            }
          },
          useBulkSelectResult,
          onGetAllIds,
        });
      },
    });
  };

  const openCreateInterventionPage = () => {
    navigate(APP_URLS.app.interventions.add.path);
  };

  const openViewInterventionPage = (item?: Intervention) => {
    const id = item?.id;
    if (id) {
      navigate(
        APP_URLS.app.interventions.view.getDynamicPath({
          pathParts: { id: item?.id },
        }),
      );
    }
  };

  const openEditInterventionPage = (item?: Intervention) => {
    const id = item?.id;
    if (id) {
      navigate(
        APP_URLS.app.interventions.edit.getDynamicPath({
          pathParts: { id: item?.id },
        }),
      );
    }
  };

  const onTabChange = (item?: TabBarItemType) =>
    item?.value ? setTabParam(item.value) : undefined;

  const onConfirmedInterventionClose = () => {
    useModalManagementResult.closeAllModals();
    toast({
      content: t('close-interventions-success'),
      closeCallback: () => {
        tableItemsBulkAction<Intervention>({
          action: (idsValue?: string[]) => {
            if (idsValue) {
              useMutationsResult.interventionBulkCloseMutation({
                variables: { ids: idsValue },
                onCompleted: () => {
                  interventionsQueryHook.refetch();
                  useBulkSelectResult?.handleUnselectAllTableItems();
                },
              });
            }
          },
          useBulkSelectResult,
          onGetAllIds,
        });
      },
    });
  };

  const onResetFilter = () => {
    setFilterParams({});
  };

  return {
    interventionsQueryHook,
    onCloseModal,
    onConfirmedInterventionClose,
    onConfirmedInterventionDestroy,
    onResetFilter,
    onTabChange,
    openCreateInterventionPage,
    openEditInterventionPage,
    openInterventionCloseModal,
    openInterventionDestroyModal,
    openViewInterventionPage,
    filterParams,
    tabParam: tabParam as InterventionScopeNameEnum,
  };
}
