import _get from 'lodash/get';
import { LanguageEnum } from 'graphql-common';
import { Values } from '@lib/interfaces/form';

export default function getPreparedDataFromValues(values: Values) {
  return {
    ...values,
    lang: _get(values, 'lang.value') as LanguageEnum,
  };
}
