import { useOutletContext } from 'react-router-dom';
import { ComponentEnum, LevelEnum, User } from 'graphql-common';
import { PlanState } from '@lib/enums/plan';
import { ScannerActionType } from 'routes/Scanner/enums';

export type ContextProps = {
  closeScanner: () => void;
  getUserAccessByCompany: (component: ComponentEnum) => LevelEnum;
  getUserAccessByComponent: (component: ComponentEnum) => LevelEnum;
  getUserAccessItemIdsByComponent: (component: ComponentEnum) => string[];
  getUserAccessByUrl: (pathname: string) => LevelEnum;
  handleOpenPlanModal: (state: PlanState) => void;
  onLogoutHandler: () => void;
  openScanner: (v: ScannerActionType | undefined) => void;
  tasksSyncLoading: boolean;
  user: User;
};

export default function useOutletContextHook() {
  const outletContext: ContextProps = useOutletContext();
  return outletContext;
}
