import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PageBody from '@lib/layouts/DashboardLayout/PageBody/PageBody';
import AppContentMaxWidth from '@lib/layouts/AppLayout/AppContentMaxWidth/AppContentMaxWidth';
import EmptyState from '@lib/components/EmptyState/EmptyState';
import Trans from '@lib/components/Trans/Trans';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import { IconName } from '@lib/components/Modal/enums';
import styles from './QrcodeScanner.module.scss';

export default function AccessPromptState() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <PageBody withoutHeader>
      <AppContentMaxWidth className={styles.root}>
        <EmptyState
          rootClassName={styles.emptyState}
          icon={IconName.VideoCam}
          text={<Trans i18nKey="allow-use-camera" />}
          title={t('camera-permission-required')}
          actions={[
            <Button
              key="refresh"
              buttonType={ButtonTypes.primaryFilled}
              buttonText={t('refresh-page')}
              leftIcon="refresh"
              onClick={() => navigate(0)}
            />,
          ]}
        />
      </AppContentMaxWidth>
    </PageBody>
  );
}
