import { useTranslation } from 'react-i18next';
import {
  useAssetCounterMeasureCreateMutation,
  useAssetCounterResetToZeroMutation,
} from 'graphql-common';
import { showGraphQLErrors } from '@lib/utils/graphQLErrors';

export default function useCounterMutations() {
  const { t } = useTranslation();

  const [
    assetCounterMeasureCreateMutation,
    assetCounterMeasureCreateMutationResult,
  ] = useAssetCounterMeasureCreateMutation({
    onError: (error) => showGraphQLErrors(error, t),
  });

  const [
    assetCounterResetToZeroMutation,
    assetCounterResetToZeroMutationResult,
  ] = useAssetCounterResetToZeroMutation({
    onError: (error) => showGraphQLErrors(error, t),
  });

  return {
    assetCounterMeasureCreateMutation,
    assetCounterMeasureCreateMutationResult,
    assetCounterResetToZeroMutation,
    assetCounterResetToZeroMutationResult,
  };
}
