import React from 'react';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import _get from 'lodash/get';
import AppContentMaxWidth from '@lib/layouts/AppLayout/AppContentMaxWidth/AppContentMaxWidth';
import Typography from '@lib/components/Typography/Typography';
import IconButton from '@lib/components/IconButton/IconButton';
import styles from './TopNavigationHeader.module.scss';

type ContextProps = {
  prevUrl: string;
};

type PredictorFnUsePrevUrlArgs = {
  currentUrl: string;
  prevUrl: string;
};

type Props = {
  backUrl?: string;
  title: string;
  rightAction?: React.ReactNode;
  predictorFnUsePrevUrl?: ({
    currentUrl,
    prevUrl,
  }: PredictorFnUsePrevUrlArgs) => boolean;
};

export default function TopNavigationHeader(props: Props) {
  const { backUrl, title, predictorFnUsePrevUrl, rightAction } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const outletContext: ContextProps = useOutletContext();
  const prevUrl = _get(outletContext, 'prevUrl') || '';

  const onGoBack = () => {
    const { pathname, search } = location;
    const currentUrl = `${pathname}${search}`;
    const usePrevUrl =
      predictorFnUsePrevUrl?.({ currentUrl, prevUrl }) ?? false;
    if (prevUrl && usePrevUrl && prevUrl !== currentUrl) {
      navigate(prevUrl);
    } else if (backUrl) {
      navigate(backUrl);
    } else {
      navigate(-1);
    }
  };

  return (
    <div className={styles.havHeader}>
      <AppContentMaxWidth>
        <div className={styles.havHeaderGrid}>
          {backUrl ? (
            <IconButton icon="arrow_back" onClick={onGoBack} />
          ) : (
            <div />
          )}
          <Typography variant="body" strong>
            {title}
          </Typography>
          {rightAction || <div />}
        </div>
      </AppContentMaxWidth>
    </div>
  );
}
