import { ApolloError } from '@apollo/client/errors';
import _get from 'lodash/get';
import {
  User,
  UserFilterInputObject,
  UsersQueryVariables,
  useUsersIdsLazyQuery,
  useUsersIdsQuery,
  useUsersQuery,
} from 'graphql-common';
import { GetUrlParams } from '@lib/enums/urls';
import createSearchFilters from '@lib/utils/createSearchFilters';
import { FetchIdsResponse } from '@lib/utils/getAllTableItemsIds';
import { ListQueryHookProps } from './types';

export type UseUsersQueryHookResult = {
  allIds: { id: string }[];
  collection: User[];
  error?: ApolloError;
  fetchIds: () => Promise<FetchIdsResponse<unknown>>;
  firstLoading: boolean;
  loading: boolean;
  refetchUsers: () => void;
  totalCount: number;
  totalPages: number;
};

export default function useUsersQueryHook({
  searchQuery,
  paginationParams,
  additionalFilters,
  skip,
  skipGetAllIds = true,
}: ListQueryHookProps): UseUsersQueryHookResult {
  const page = _get(paginationParams, GetUrlParams.Page);
  const perPage = _get(paginationParams, GetUrlParams.PerPage);
  const orderingField = _get(paginationParams, GetUrlParams.OrderingField);
  const orderingDirection = _get(
    paginationParams,
    GetUrlParams.OrderingDirection,
  );
  const usersQueryVariables: UsersQueryVariables = {
    page: Number(page),
    limit: Number(perPage),
  };
  const filters: UserFilterInputObject[] = [];
  if (searchQuery) {
    filters.push(
      ...createSearchFilters(searchQuery, [
        { fieldName: 'email' },
        { fieldName: 'role', predicateType: 'eq' },
        { fieldName: 'fullName' },
        { fieldName: 'personalId' },
        { fieldName: 'memberAssignedSitesName' },
      ]),
    );
  }
  if (additionalFilters?.length) {
    filters.push(...additionalFilters);
  }
  if (filters.length) {
    usersQueryVariables.filters = filters;
  }
  if (orderingField && orderingDirection) {
    usersQueryVariables.sorts = {
      [orderingField]: orderingDirection,
    };
  }
  const usersQuery = useUsersQuery({
    fetchPolicy: 'cache-and-network',
    variables: usersQueryVariables,
    skip,
  });
  const usersIdsQuery = useUsersIdsQuery({
    fetchPolicy: 'no-cache',
    variables: {
      onlyIds: true,
      filters: usersQueryVariables.filters,
    },
    skip: skipGetAllIds,
  });
  const [fetchIds] = useUsersIdsLazyQuery({
    fetchPolicy: 'no-cache',
    variables: {
      onlyIds: true,
      filters: usersQueryVariables.filters,
    },
  });
  const {
    loading,
    data,
    previousData,
    refetch: refetchUsers,
    error,
  } = usersQuery;
  const queryData = data || previousData;
  const firstLoading = loading && previousData === undefined;
  const collection = _get(queryData, 'data.collection', []) as User[];
  const metadata = _get(queryData, 'data.metadata');
  const totalCount = _get(metadata, 'totalCount', 0);
  const totalPages = _get(metadata, 'totalPages', 1);
  return {
    collection,
    error,
    fetchIds,
    firstLoading,
    loading,
    refetchUsers,
    totalCount,
    totalPages,
    allIds: _get(usersIdsQuery, ['data', 'data', 'collection'], []),
  };
}
