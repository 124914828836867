import _get from 'lodash/get';
import {
  AppUserAvailableInterventionsQueryVariables,
  Asset,
  FilterGroupingEnum,
  Intervention,
  InterventionFilterInputObject,
  InterventionScopeNameEnum,
  Site,
  SiteArea,
} from 'graphql-common';
import { APP_URLS } from 'constants/urls';
import { ErrorPageStatusEnum } from '@lib/components/ErrorPage/enums';
import InterventionsFilterFieldNames from '@lib/enums/fieldNames/interventionsFilterFieldNames';
import { ScannerActionType } from 'routes/Scanner/enums';
import QrCodePrefix from '@lib/enums/qrCodePrefix';
import isErrorTimeoutError from '@lib/utils/isErrorTimeoutError';
import { CodeType } from './getCodeType';
import { ParsedCodes } from './getParsedCodes';
import { HandleQrCodeSuccessProps } from './types';

const defaultVariables: AppUserAvailableInterventionsQueryVariables = {
  page: 1,
  limit: 2,
  interventionScope: InterventionScopeNameEnum.All,
  interventionFilters: undefined,
  siteFindBy: { code: undefined },
  assetFindBy: { code: undefined },
  siteAreaFindBy: { code: undefined },
};

export default async function handleValidCodeForInterventionsAction(
  data: string,
  {
    navigate,
    fetchAppInterventions,
    setLoading,
    setErrorStatus,
    onSuccessScanAction,
  }: HandleQrCodeSuccessProps,
  parsedCodes: ParsedCodes,
  codeType: CodeType,
) {
  try {
    const filters: InterventionFilterInputObject[] = [];
    const { customPrefix, entityCode, sideCode } = parsedCodes;

    const variables = {
      ...defaultVariables,
    };

    if (codeType !== CodeType.Invalid) {
      if (sideCode) {
        variables.siteFindBy.code = sideCode;
        filters.push({
          siteCode: {
            grouping: FilterGroupingEnum.And,
            predicate: { in: [sideCode] },
            filterGroup: 'siteAreaAssetFilerGroup',
          },
        });
      }
      if (entityCode) {
        variables.siteAreaFindBy.code = entityCode;
        variables.assetFindBy.code = entityCode;
        if (codeType === CodeType.SiteArea) {
          filters.push({
            siteAreaCode: {
              grouping: FilterGroupingEnum.And,
              predicate: { in: [entityCode] },
              filterGroup: 'siteAreaAssetFilerGroup',
            },
          });
        }
        if (codeType === CodeType.Asset) {
          filters.push({
            assetCode: {
              grouping: FilterGroupingEnum.And,
              predicate: { in: [entityCode] },
              filterGroup: 'siteAreaAssetFilerGroup',
            },
          });
        }
        if (
          codeType === CodeType.Asset &&
          customPrefix &&
          customPrefix !== QrCodePrefix.SiteArea &&
          customPrefix !== QrCodePrefix.Asset
        ) {
          filters.push({
            assetQrPrefix: {
              grouping: FilterGroupingEnum.And,
              predicate: { in: [customPrefix] },
              filterGroup: 'siteAreaAssetFilerGroup',
            },
          });
        }
      }
    }
    if (filters.length) {
      variables.interventionFilters = filters;
    }
    setLoading(true);
    const res = await fetchAppInterventions({
      variables,
      errorPolicy: 'ignore',
    });
    const { data: userAvailableData, error } = res;
    const isTimeoutError = isErrorTimeoutError(error);
    if (isTimeoutError) {
      setErrorStatus(ErrorPageStatusEnum.QrCodeScanFailedOffline);
    } else if (error) {
      setErrorStatus(ErrorPageStatusEnum.QrCodeScanFailed);
    } else {
      const collection = _get(
        userAvailableData,
        'interventions.collection',
        [],
      ) as Intervention[];
      const site = _get(userAvailableData, 'site') as Site;
      const siteArea = _get(userAvailableData, 'siteArea') as SiteArea;
      const asset = _get(userAvailableData, 'asset') as Asset;
      setLoading(false);
      if (codeType === CodeType.Asset && asset === null) {
        setErrorStatus(ErrorPageStatusEnum.QrCodeScanFailed);
      } else if (collection.length === 1) {
        navigate(
          APP_URLS.app.interventions.view.getDynamicPath({
            pathParts: { id: collection[0].id },
          }),
        );
      } else if (collection.length && (sideCode || entityCode)) {
        const values = {
          [InterventionsFilterFieldNames.Site]:
            sideCode && site
              ? [
                  {
                    value: site.id,
                    label: site.name,
                  },
                ]
              : undefined,
          [InterventionsFilterFieldNames.SiteArea]:
            entityCode && siteArea && codeType === CodeType.SiteArea
              ? [
                  {
                    value: siteArea.id,
                    label: siteArea.name,
                  },
                ]
              : undefined,
          [InterventionsFilterFieldNames.Asset]:
            entityCode && asset && codeType === CodeType.Asset
              ? [
                  {
                    value: asset.id,
                    label: asset.name,
                  },
                ]
              : undefined,
        };
        onSuccessScanAction(values, ScannerActionType.FindInterventions);
      } else {
        setErrorStatus(ErrorPageStatusEnum.QrCodeScanFailed);
      }
    }
  } catch (error) {
    console.error('handleValidCode:', error);
    setErrorStatus(ErrorPageStatusEnum.QrCodeScanFailed);
  }
}
