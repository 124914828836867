import React, { memo, useState } from 'react';
import _get from 'lodash/get';
import { ComponentEnum, LevelEnum } from 'graphql-common';
import Select from '@lib/components/Select/Select';
import CheckBoxGroup from '@lib/components/CheckBoxGroup/CheckBoxGroup';
import CheckBoxAsIconButton from '@lib/components/CheckBoxAsIconButton/CheckBoxAsIconButton';
import CheckedOption from '@lib/components/Select/components/CheckedOption';
import { scannerDropDownsWrap } from '@lib/enums/selectors';
import { USER_SELECTED_SCANNER_ACTION } from '@lib/enums/localStorageKeys';
import getScannerActionOptions from './utils/getScannerActionOptions';
import CameraSelectContainer from './CameraSelectContainer';
import { UseCameraSetupResult } from './utils/useCameraSetup';
import { ScannerActionType } from './enums';
import styles from './QrcodeScanner.module.scss';

type Props = {
  useCameraSetupResult: UseCameraSetupResult;
  getUserAccessByComponent: (component: ComponentEnum) => undefined | LevelEnum;
};

const torchToggleOption = {
  value: 'checked',
  label: '',
  icon: 'flash',
};

function QrcodeScannerToolbar({
  useCameraSetupResult,
  getUserAccessByComponent,
}: Props) {
  const {
    isTorchAvailable,
    cameras,
    handleCameraChange,
    selectedCameraId,
    isReady,
    toggleTorch,
    torchEnabled,
  } = useCameraSetupResult;

  const storedSelectedScannerAction =
    (localStorage.getItem(USER_SELECTED_SCANNER_ACTION) as ScannerActionType) ||
    undefined;
  const [selectedScannerAction, setSelectedScannerAction] = useState<
    ScannerActionType | undefined
  >(storedSelectedScannerAction);

  const actionOptions = getScannerActionOptions({
    isTasksAvailable:
      getUserAccessByComponent(ComponentEnum.TasksPerforming) !==
      LevelEnum.LimitedRead,
    isInterventionsAvailable:
      getUserAccessByComponent(ComponentEnum.InterventionsManagement) !==
      LevelEnum.LimitedRead,
    isCreateInterventionsAvailable:
      getUserAccessByComponent(ComponentEnum.InterventionsManagement) ===
      LevelEnum.Write,
    isCountersAvailable:
      getUserAccessByComponent(ComponentEnum.AssetsCounterReading) !==
      LevelEnum.LimitedRead,
  });
  const selectedActionOption = actionOptions.find(
    (option) => option.value === selectedScannerAction,
  );
  if (!selectedActionOption) {
    setSelectedScannerAction(actionOptions[0]?.value as ScannerActionType);
  }

  const elementForPortal = document.getElementById(scannerDropDownsWrap);

  const onChangeScannerActionType = (v: unknown) => {
    const newScannerActionType = _get(v, 'value');
    if (newScannerActionType) {
      localStorage.setItem(USER_SELECTED_SCANNER_ACTION, newScannerActionType);
      setSelectedScannerAction(newScannerActionType);
    }
  };

  const onChangeCameraId = (v: unknown) => {
    const newCameraId = _get(v, 'value');
    if (!!newCameraId && selectedCameraId !== newCameraId) {
      handleCameraChange(newCameraId);
    }
  };

  return (
    <div className={styles.scannerDescBottom}>
      <div
        className={
          isTorchAvailable
            ? styles.scannerDescBottomRowWithTorch
            : styles.scannerDescBottomRow
        }
      >
        <div>
          <Select
            values={selectedActionOption ? [selectedActionOption] : undefined}
            options={actionOptions}
            onChange={onChangeScannerActionType}
            value={selectedActionOption}
            isClearable={false}
            isSearchable={false}
            disabled={!isReady || actionOptions.length <= 1}
            menuPlacement="top"
            menuPortalTarget={elementForPortal}
            menuListHasSpaces
            controlHasLeftPadding={false}
            components={{
              Option: CheckedOption,
            }}
          />
        </div>
        {cameras?.length > 0 && (
          <div>
            <Select
              options={cameras.map((camera) => ({
                label: camera.label,
                value: camera.id,
              }))}
              onChange={onChangeCameraId}
              value={selectedCameraId ? { value: selectedCameraId } : null}
              isClearable={false}
              isSearchable={false}
              disabled={!isReady}
              menuPlacement="top"
              menuPortalTarget={elementForPortal}
              menuListHasSpaces
              components={{
                SelectContainer: CameraSelectContainer,
              }}
            />
          </div>
        )}
        {isTorchAvailable && (
          <div>
            <CheckBoxGroup
              name="torch"
              options={[torchToggleOption]}
              CheckBoxComponent={CheckBoxAsIconButton}
              onChange={toggleTorch}
              value={torchEnabled ? [torchToggleOption] : []}
              checkBoxComponentProps={{
                getIcon: ({ isChecked }) =>
                  isChecked ? 'flashlight_on' : 'flashlight_off',
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default memo(QrcodeScannerToolbar);
