import React from 'react';
import { useTranslation } from 'react-i18next';
import { Counter } from 'graphql-common';
import { ApolloError, GraphQLErrors } from '@apollo/client/errors';
import { FieldItem, Values } from '@lib/interfaces/form';
import Form, {
  ActionsProps,
  FormRef,
} from '@lib/components/ReactHookForm/FormContainer';
import Modal from '@lib/components/Modal/Modal';
import AssetCounterIndexFieldNames from '@lib/enums/fieldNames/assetCounterIndexFieldNames';
import AssetCounterIndexModalFormActions from 'components/AssetCounterIndexModalForm/AssetCounterIndexModalFormActions';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import EmptyState from '@lib/components/EmptyState/EmptyState';
import { IconName } from '@lib/components/Modal/enums';
import getFormFields from './utils/getFormFields';

export interface FormProps {
  assetCounter?: Counter;
  formRef: React.MutableRefObject<FormRef | undefined>;
  graphQLErrors?: GraphQLErrors;
  assetCounterError?: ApolloError;
  isOpen: boolean;
  loading: boolean;
  onCancel?: () => void;
  onClose?: () => void;
  onSubmit?: (values: Values, isEditForm?: boolean) => void;
}

const defaultValues = {
  [AssetCounterIndexFieldNames.Index]: null,
  [AssetCounterIndexFieldNames.Action]: { value: 'index' },
};

export default function AssetCounterIndexModalForm(props: FormProps) {
  const {
    assetCounter,
    formRef,
    graphQLErrors,
    isOpen,
    loading,
    onCancel,
    onClose,
    onSubmit,
    assetCounterError,
  } = props;
  const { t } = useTranslation();

  const fields: FieldItem[] = getFormFields({
    assetCounter,
    t,
  });

  const actionsComponent: (props: ActionsProps) => React.JSX.Element = (
    actionsComponentProps,
  ) => (
    <AssetCounterIndexModalFormActions
      {...actionsComponentProps}
      onCancel={onCancel}
    />
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      caption={t('add-index')}
      titleAlign="left"
    >
      {assetCounterError ? (
        <EmptyState
          title={t('access-denied')}
          text={t('access-denied-text')}
          icon={IconName.Lock}
          actions={[
            <Button
              key="back"
              buttonText={t('close')}
              buttonType={ButtonTypes.primaryFilled}
              leftIcon="close"
              onClick={onClose ? () => onClose() : undefined}
            />,
          ]}
        />
      ) : (
        <Form
          actions={actionsComponent}
          actionsComponentProps={{ onCancel }}
          defaultValues={defaultValues}
          fields={fields}
          formId="counter-index"
          formRef={formRef}
          graphQLErrors={graphQLErrors}
          loading={loading}
          onSubmit={onSubmit}
        />
      )}
    </Modal>
  );
}
